import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Tooltip } from '@material-ui/core'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import { I18n } from 'src/helpers/I18n'
import moment from 'moment'

const GridTable = React.lazy(() => import('src/components/Table/GridTable'))
const styles = theme => ({
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  gridTable: {
    '& .MuiToolbar-root': {
      display: 'none !important'
    },

    '& .Grid-Root-Table': {
      minHeight: 'calc(100vh - 347px) !important',
      maxHeight: 'calc(100vh - 347px) !important',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 360px) !important',
      },
      ['@media (min-width:601px) and (max-width:959px)']: {
        minHeight: 'calc(100vh - 391px) !important',
      },
      ['@media (min-width: 1150px)']: {
        minHeight: 'calc(100vh - 347px) !important',
      }
    },

    '& table.MuiTable-root': {
      ['@media (max-width: 767px)']: {
        width: '750px'
      },
      ['@media (min-width: 768px)']: {
        width: '900px'
      },
      ['@media (min-width: 992px)']: {
        width: '100%'
      },
    }
  },
  gridTableFooter: {
    display: 'flex',
    flex: 'none',
    position: 'relative',
    alignItems: 'center',
    minHeight: '55px',
  },
  gridTableFooterButtonRight: {
    right: '0px',
    position: 'absolute'
  },
  paperFade: {
    marginTop: '0.35rem !important'
  }
});

const Unit = 'L'

class IndexSKE extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'customer.name',
          title: I18n.t('Table.customer.name'),
          formatterComponent: (data) => this.renderDataField(data, 'customer.name'),
          sortable: false,
          width: '27%'
        },
        {
          name: 'diesel',
          title: I18n.t('Label.products.diesel'),
          // formatterComponent: (data) => this.renderDataField(data, 'diesel') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.diesel', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'dieselFreeTax',
          title: I18n.t('Label.products.dieselFreeTax'),
          // formatterComponent: (data) => this.renderDataField(data, 'dieselFreeTax') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.dieselFreeTax', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'kerosene',
          title: I18n.t('Label.products.kerosene'),
          // formatterComponent: (data) => this.renderDataField(data, 'kerosene') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.kerosene', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'gasoline',
          title: I18n.t('Label.products.gasoline'),
          // formatterComponent: (data) => this.renderDataField(data, 'gasoline') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.gasoline', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'crudeOil',
          title: I18n.t('Label.products.crudeOil'),
          // formatterComponent: (data) => this.renderDataField(data, 'gasoline') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.crudeOil', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'adBlue',
          title: I18n.t('Label.products.adBlue'),
          // formatterComponent: (data) => this.renderDataField(data, 'adBlue') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.adBlue', '0')
            return this.getFuelString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'amount',
          title: I18n.t('Table.amount'),
          // formatterComponent: (data) => this.renderDataField(data, 'amount') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.amount', '0')
            return String(amount || 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'customer._id',
          title: I18n.t('Table.preview'),
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            return this.renderActionsColumn(data)
          },
          width: "16%"
        },
      ]
    }
    this.ConfirmDeleteDialog = null;
    this.toolbarActions = [];

    this.renderToolbarActions = this.renderToolbarActions.bind(this)
    this.renderSelectedActions = this.renderSelectedActions.bind(this)
  }

  viewReport = (data) => {
    let { invoiceDate } = this.props
    let startDate = moment(invoiceDate.startDate).format("YYYYMMDD")
    let endDate = moment(invoiceDate.endDate).format("YYYYMMDD")
    let customerId = data.row.customer._id
    let constructionOpts = data.row.constructionOpts;
    let url = window.config.API_HOST +
      `/api/authWithSession?token=${localStorage.getItem("token")}&redirect=/pdf/invoice-${customerId}-${startDate}-${endDate}-${constructionOpts}.pdf`
    window.open(url);
  }

  getFuelString(value) {
    return <>
      {String(value || 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}&nbsp;{Unit}
    </>
  }

  viewReportAll = () => {
    let { type, paymentTerm } = this.props.filters || {},
      { invoiceDate } = this.props;

    if (!invoiceDate.startDate || !invoiceDate.endDate) return;

    let startDate = moment(invoiceDate.startDate).format("YYYYMMDD")
    let endDate = moment(invoiceDate.endDate).format("YYYYMMDD")
    let constructionOpts = localStorage.getItem('constructionOpts');

    let url = window.config.API_HOST +
      `/api/authWithSession?token=${localStorage.getItem("token")}&redirect=/pdf/invoiceall-${type}-${startDate}-${endDate}-${paymentTerm}-${constructionOpts}.pdf`
    window.open(url);
  }

  renderActionsColumn(data) {
    return (
      <Button color='primary' variant='outlined' onClick={() => this.viewReport(data)}>
        {I18n.t('Button.confirm')}
      </Button>
    )
  }

  renderToolbarActions(data) {
    let noData = true
    if (data.length) noData = false
    let { month, paymentTerm } = this.props.filters || {},
      { classes } = this.props;

    return (
      <Tooltip title={I18n.t("Tooltip.print")} key="print">
        <span className={classes.gridTableFooterButtonRight}>
          <Button
            disabled={!month && !paymentTerm || noData}
            onClick={this.viewReportAll}
            color='primary'
            variant='contained' >
            {I18n.t("Button.print")}
          </Button>
        </span>
      </Tooltip>
    )
  }

  addIdToData() {
    const { data = [] } = this.props;
    return data.map((item, index) => ({
      ...item,
      id: this.getData(item, 'customer._id', index)
    })
    )
  }

  render() {
    const { classes, onRefTable } = this.props,
      data = this.addIdToData();
      localStorage.setItem('constructionOpts', '');
      if (data && data[0]) {
        localStorage.setItem('constructionOpts', data[0]['constructionOpts']);
      }

    return (
      <PaperFade className={classes.paperFade} showLoading={true}>
        <GridTable
          id="InvoiceIndexSKE"
          className={classes.gridTable}
          onFetchData={() => { }}
          onRefTable={onRefTable}
          columns={this.table.columns}
          rows={data || []}
          totalCount={(data || []).length}
          pageSize={(data || []).length}
          showCheckboxColumn={false}
          height="auto"
          filterHiding={true}
          pagingHiding={true}
        />
        <div className={classes.gridTableFooter}>
          {this.renderToolbarActions(data)}
        </div>
      </PaperFade>
    )
  }
}

IndexSKE.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(IndexSKE));
