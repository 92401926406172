import React, { lazy } from 'react';
import { Redirect } from 'react-router';
import { I18n } from 'src/helpers/I18n';
import Utils from 'src/helpers/utility';

let defaultIndex = Utils.defineDefaultIndex();

const AreaIndex = lazy(() => import('src/containers/Area/Index'))
const AreaCreate = lazy(() => import('src/containers/Area/Create'))
const AreaEdit = lazy(() => import('src/containers/Area/Edit'))

const CustomerIndex = lazy(() => import('src/containers/Customer/Index'))
const CustomerCreate = lazy(() => import('src/containers/Customer/Create'))
const CustomerEdit = lazy(() => import('src/containers/Customer/Edit'))
const CustomerDetail = lazy(() => import('src/containers/Customer/Detail'))

const DriverIndex = lazy(() => import('src/containers/Driver/Index'))
const DriverEdit = lazy(() => import('src/containers/Driver/Edit'))
const DriverCreate = lazy(() => import('src/containers/Driver/Create'))
const DriverDetail = lazy(() => import('src/containers/Driver/Detail'))
const DriverGPS = lazy(() => import('src/containers/Driver/GPS'))

const OrderIndex = lazy(() => import('src/containers/Order/Index'))
const OrderCreate = lazy(() => import('src/containers/Order/Create'))
const OrderEdit = lazy(() => import('src/containers/Order/Edit'))
const OrderDetail = lazy(() => import('src/containers/Order/Detail'))

const AdminDelivery = lazy(() => import('src/containers/AdminDelivery/Index'))
const AdminSeparateDelivery = lazy(() => import('src/containers/AdminDelivery/SeparateDelivery'))
const AdminSeparateDeliveryEdit = lazy(() => import('src/containers/AdminDelivery/Create'))
const AdminGeneralDelivery = lazy(() => import('src/containers/AdminDelivery/GeneralDelivery'))
const AdminHistoryDelivery = lazy(() => import('src/containers/AdminDelivery/History'))

const VehicleIndex = lazy(() => import('src/containers/Vehicle/Index'))
const VehicleEdit = lazy(() => import('src/containers/Vehicle/Edit'))
const VehicleCreate = lazy(() => import('src/containers/Vehicle/Create'))
const VehicleDetail = lazy(() => import('src/containers/Vehicle/Detail'))

const DivideOrder = lazy(() => import('src/containers/DivideOrder/Index'))

const SettingPrice = lazy(() => import('src/containers/SettingPrice/Index'))

const ReportIndex = lazy(() => import('src/containers/Report/Index'))
const ReportDailyPDF = lazy(() => import('src/containers/Report/ShowPdf'))
const ReportDeliveryIndex = lazy(() => import('src/containers/ReportDelivery/Index'))

const Delivery = lazy(() => import('src/containers/Delivery/Index'))
const HistoryDelivery = lazy(() => import('src/containers/Delivery/History'))
const GeneralDelivery = lazy(() => import('src/containers/Delivery/GeneralDelivery'))
const SeparateDelivery = lazy(() => import('src/containers/Delivery/SeparateDelivery'))
const SeparateDeliveryEdit = lazy(() => import('src/containers/Delivery/SeparateDeliveryEdit'))

const DeliveryOthers = lazy(() => import('src/containers/DeliveryOthers/Index'))
const PouredAmount = lazy(() => import('src/containers/PouredAmount/Index'))
const RemainingAmount = lazy(() => import('src/containers/RemainingAmount/Index'))

const InvoiceIndex = lazy(() => import('src/containers/Invoice/Index'))
const EstimationIndex = lazy(() => import('src/containers/Estimation/Index'))
const CustomerOrderIndex = lazy(() => import('src/containers/CustomersOrder/Index'))

const ProductIndex = lazy(() => import('src/containers/Product/Index'))
const ProductCreate = lazy(() => import('src/containers/Product/Create'))
const ProductEdit = lazy(() => import('src/containers/Product/Edit'))

const routesDriver = [
  {
    path: "/",
    component: () => <Redirect to={defaultIndex} />,
    exact: true,
    name: 'dashboard'
  },
  //Delivery
  {
    path: "/delivery",
    name: 'delivery.index',
    title: () => I18n.t("Breadcrumb.delivery"),
    component: () => <Delivery />,
    exact: true,
    sidebarName: 'delivery.index'
  },
  {
    path: "/delivery/:id",
    name: 'delivery.history',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <HistoryDelivery />,
    exact: true,
    sidebarName: 'delivery.index'
  },
  {
    path: "/delivery/:id/general",
    name: 'delivery.general',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <GeneralDelivery />,
    exact: true,
    sidebarName: 'delivery.index'
  },
  {
    path: "/delivery/:id/separate",
    name: 'delivery.separate',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <SeparateDelivery />,
    exact: true,
    sidebarName: 'delivery.index'
  },
  {
    path: "/delivery/:id/separate/:history_id",
    name: 'delivery.separate',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <SeparateDeliveryEdit />,
    exact: true,
    sidebarName: 'delivery.index'
  },
  //Delivery Others
  {
    path: "/delivery-others",
    name: 'deliveryOthers.index',
    title: () => I18n.t("Breadcrumb.deliveryOthers"),
    component: () => <DeliveryOthers />,
    exact: true,
    sidebarName: 'deliveryOthers.index'
  },
  // Poured Fuels in Truck
  {
    path: "/poured-amount",
    name: 'pouredAmount.index',
    title: () => I18n.t("Breadcrumb.pouredAmount"),
    component: () => <PouredAmount />,
    exact: true,
    sidebarName: 'pouredAmount.index'
  },
  // Remaining Fuels in Truck
  {
    path: "/remaining-amount",
    name: 'remainingAmount.index',
    title: () => I18n.t("Breadcrumb.remainingAmount"),
    component: () => <RemainingAmount />,
    exact: true,
    sidebarName: 'remainingAmount.index'
  },
]

const routes = [
  {
    path: "/",
    component: () => <Redirect to={defaultIndex} />,
    exact: true,
    name: 'dashboard'
  },
  // ======================================== Admin Delivery Route ================================================
  {
    path: "/admin-delivery",
    name: 'adminDelivery.index',
    title: () => I18n.t("Breadcrumb.delivery"),
    component: () => <AdminDelivery />,
    exact: true,
    sidebarName: 'adminDelivery.index'
  },
  {
    path: "/admin-delivery/:id",
    name: 'adminDelivery.history',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <AdminHistoryDelivery />,
    exact: true,
    sidebarName: 'adminDelivery.index'
  },
  {
    path: "/admin-delivery/:id/general",
    name: 'adminDelivery.general',
    title: () => I18n.t("Breadcrumb.generalDelivery"),
    component: () => <AdminGeneralDelivery />,
    exact: true,
    sidebarName: 'adminDelivery.index'
  },
   {
    path: "/admin-delivery/:id/separate",
    name: 'adminDelivery.edit',
    title: () => I18n.t("Breadcrumb.order.edit"),
    component: () => <AdminSeparateDelivery/>,
    exact: true,
    sidebarName: 'adminDelivery.index'
  },
  {
    path: "/admin-delivery/:id/separate/:history_id",
    name: 'adminDelivery.edit',
    title: () => I18n.t("Breadcrumb.order.edit"),
    component: () => <AdminSeparateDeliveryEdit />,
    exact: true,
    sidebarName: 'adminDelivery.index'
  },
  // ======================================== Area Route ================================================
  {
    path: '/areas',
    name: 'area.index',
    title: () => I18n.t('Breadcrumb.area.index'),
    component: () => <AreaIndex />,
    exact: true,
    sidebarName: 'area.index'
  },
  {
    path: '/areas/create',
    name: 'area.create',
    title: () => I18n.t('Breadcrumb.area.create'),
    component: () => <AreaCreate />,
    exact: true,
    sidebarName: 'area.index'
  },
  {
    path: '/areas/edit/:id',
    name: 'area.edit',
    title: () => I18n.t('Breadcrumb.area.edit'),
    component: () => <AreaEdit />,
    exact: true,
    sidebarName: 'area.index'
  },
  //-----------------------------------------------------------------------------------------------------
  //Customer
  {
    path: "/customers",
    name: 'customer.index',
    title: () => I18n.t("Breadcrumb.customer.index"),
    component: () => <CustomerIndex />,
    exact: true,
    sidebarName: 'customer.index'
  },
  {
    path: "/customers/create",
    name: 'customer.create',
    title: () => I18n.t("Breadcrumb.customer.create"),
    component: () => <CustomerCreate />,
    exact: true,
    sidebarName: 'customer.index'
  },
  {
    path: "/customers/edit/:id",
    name: 'customer.edit',
    title: () => I18n.t("Breadcrumb.customer.edit"),
    component: () => <CustomerEdit />,
    exact: true,
    sidebarName: 'customer.index'
  },
  {
    path: "/customers/detail/:id",
    name: 'customer.detail',
    title: () => I18n.t("Breadcrumb.customer.detail"),
    component: () => <CustomerDetail />,
    exact: true,
    sidebarName: 'customer.index'
  },
  //-----------------------------------------------------------------------------------------------------
  //Truck
  {
    path: "/vehicles",
    name: 'vehicle.index',
    title: () => I18n.t("Breadcrumb.vehicle.index"),
    component: () => <VehicleIndex />,
    exact: true,
    sidebarName: 'vehicle.index'
  },
  {
    path: "/vehicles/create",
    name: 'vehicle.create',
    title: () => I18n.t("Breadcrumb.vehicle.create"),
    component: () => <VehicleCreate />,
    exact: true,
    sidebarName: 'vehicle.index'
  },
  {
    path: "/vehicles/edit/:id",
    name: 'vehicle.edit',
    title: () => I18n.t("Breadcrumb.vehicle.edit"),
    component: () => <VehicleEdit />,
    exact: true,
    sidebarName: 'vehicle.index'
  },
  {
    path: "/vehicles/detail/:id",
    name: 'vehicle.detail',
    title: () => I18n.t("Breadcrumb.vehicle.detail"),
    component: () => <VehicleDetail />,
    exact: true,
    sidebarName: 'vehicle.index'
  },
  // Driver
  {
    path: "/drivers",
    name: 'driver.index',
    title: () => I18n.t("Breadcrumb.driver.index"),
    component: () => <DriverIndex />,
    exact: true,
    sidebarName: 'driver.index'
  },
  {
    path: "/drivers/create",
    name: 'driver.create',
    title: () => I18n.t("Breadcrumb.driver.create"),
    component: () => <DriverCreate />,
    exact: true,
    sidebarName: 'driver.index'
  },
  {
    path: "/drivers/edit/:id",
    name: 'driver.edit',
    title: () => I18n.t("Breadcrumb.driver.edit"),
    component: () => <DriverEdit />,
    exact: true,
    sidebarName: 'driver.index'
  },
  {
    path: "/drivers/detail/:id",
    name: 'driver.detail',
    title: () => I18n.t("Breadcrumb.driver.detail"),
    component: () => <DriverDetail />,
    exact: true,
    sidebarName: 'driver.index'
  },
  {
    path: "/drivers/gps/:id",
    name: 'driver.gps',
    title: () => I18n.t("Breadcrumb.driver.gps"),
    component: () => <DriverGPS />,
    exact: true,
    sidebarName: 'driver.index'
  },
  //Order
  {
    path: "/orders",
    name: 'order.index',
    title: () => I18n.t("Breadcrumb.order.index"),
    component: () => <OrderIndex />,
    exact: true,
    sidebarName: 'order.index'
  },
  {
    path: "/orders/create",
    name: 'order.create',
    title: () => I18n.t("Breadcrumb.order.create"),
    component: () => <OrderCreate />,
    exact: true,
    sidebarName: 'order.index'
  },
  {
    path: "/orders/edit/:id",
    name: 'order.edit',
    title: () => I18n.t("Breadcrumb.order.edit"),
    component: () => <OrderEdit />,
    exact: true,
    sidebarName: 'order.index'
  },
  {
    path: "/orders/detail/:id",
    name: 'order.detail',
    title: () => I18n.t("Breadcrumb.order.detail"),
    component: () => <OrderDetail />,
    exact: true,
    sidebarName: 'order.index'
  },
  //Divide Order
  {
    path: "/divide-order",
    name: 'divideOrder.index',
    title: () => I18n.t("Breadcrumb.order.divide"),
    component: () => <DivideOrder />,
    exact: true,
    sidebarName: 'divideOrder.index'
  },
  //Setting Price
  {
    path: "/setting-price",
    name: 'settingPrice.index',
    title: () => I18n.t("Breadcrumb.settingPriceIndex"),
    component: () => <SettingPrice />,
    exact: true,
    sidebarName: 'settingPrice.index'
  },
  //Report
  {
    path: "/report",
    name: 'report.index',
    title: () => I18n.t("Breadcrumb.reportIndex"),
    component: () => <ReportIndex />,
    exact: true,
    sidebarName: 'report.index'
  },
  {
    path: "/report/daily/:date",
    name: 'report.showPdf',
    title: () => I18n.t("Button.exportPDF"),
    component: () => <ReportDailyPDF />,
    exact: true,
    sidebarName: 'report.index'
  },
  // Invoice
  {
    path: "/invoices",
    name: 'invoice.index',
    title: () => I18n.t("Breadcrumb.invoice"),
    component: () => <InvoiceIndex />,
    exact: true,
    sidebarName: 'invoice.index'
  },
  // Estimation
  {
    path: "/estimations",
    name: 'estimation.index',
    title: () => I18n.t("Breadcrumb.estimation"),
    component: () => <EstimationIndex />,
    exact: true,
    sidebarName: 'estimation.index'
  },
  {
    path: "/report-delivery",
    name: 'reportDelivery.index',
    title: () => I18n.t("Breadcrumb.reportDelivery"),
    component: () => <ReportDeliveryIndex />,
    exact: true,
    sidebarName: 'reportDelivery.index'
  },
  // Thống kê đơn hàng theo khách hàng
  {
    path: "/customer-order",
    name: 'customerOrder.index',
    title: () => I18n.t("Breadcrumb.customerOrders"),
    component: () => <CustomerOrderIndex />,
    exact: true,
    sidebarName: 'customerOrder.index'
  },
  // ======================================== Product Route ================================================
  {
    path: '/products',
    name: 'product.index',
    title: () => I18n.t('Breadcrumb.product.index'),
    component: () => <ProductIndex />,
    exact: true,
    sidebarName: 'product.index'
  },
  {
    path: '/products/create',
    name: 'product.create',
    title: () => I18n.t('Breadcrumb.product.create'),
    component: () => <ProductCreate />,
    exact: true,
    sidebarName: 'product.index'
  },
  {
    path: '/products/edit/:id',
    name: 'product.edit',
    title: () => I18n.t('Breadcrumb.product.edit'),
    component: () => <ProductEdit />,
    exact: true,
    sidebarName: 'product.index'
  }
]

export { routes, routesDriver } 