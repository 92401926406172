import React from 'react';
import Icon from '@material-ui/core/Icon';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EditAttributes from '@material-ui/icons/EditAttributes';
import ExploreIcon from '@material-ui/icons/Explore';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faCity, faTruck, faUserFriends, faUserTag, faYenSign, faCalculator, faCubes} from '@fortawesome/free-solid-svg-icons'
import {I18n} from 'src/helpers/I18n';
import AreaIndex from 'src/containers/Area/Index'
import CustomerIndex from 'src/containers/Customer/Index'
import DivideOrder from 'src/containers/DivideOrder/Index'
import DriverIndex from 'src/containers/Driver/Index'
import ReportIndex from 'src/containers/Report/Index'
import ReportDeliveryIndex from 'src/containers/ReportDelivery/Index'
import AdminDeliveryIndex from 'src/containers/AdminDelivery/Index'
import SettingPrice from 'src/containers/SettingPrice/Index'
import OrderIndex from 'src/containers/Order/Index'
import VehicleIndex from 'src/containers/Vehicle/Index'
import ProductIndex from 'src/containers/Product/Index'

import Delivery from 'src/containers/Delivery/Index'
import DeliveryOthers from 'src/containers/DeliveryOthers/Index'
import RemainingAmount from 'src/containers/RemainingAmount/Index'

import InvoiceIndex from 'src/containers/Invoice/Index'
import EstimationIndex from 'src/containers/Estimation/Index'
import CustomerOrderIndex from 'src/containers/CustomersOrder/Index'
import PouredAmount from 'src/containers/PouredAmount/Index'

const sidebar = {
  admin: [
    {
      path: "/orders",
      name: 'order.index',
      title: I18n.t('Sidebar.order'),
      icon: <ShoppingCartIcon/>,
      component: () => <OrderIndex/>,
    },
    {
      path: "/divide-order",
      name: 'divideOrder.index',
      title: I18n.t('Sidebar.divideOrder'),
      icon: <WidgetsIcon/>,
      component: () => <DivideOrder/>,
    },
    {
      divider: true
    },
    {
      path: "/drivers",
      name: 'driver.index',
      title: I18n.t('Sidebar.driver'),
      icon: <FontAwesomeIcon icon={faUserTag} size="lg"/>,
      component: () => <DriverIndex/>,
    },
    {
      path: "/vehicles",
      name: 'vehicle.index',
      title: I18n.t('Sidebar.vehicle'),
      icon: <FontAwesomeIcon icon={faTruck} size="lg"/>,
      component: () => <VehicleIndex/>,
    },
    {
      divider: true
    },
    {
      path: "/report",
      name: 'report.index',
      title: I18n.t('Sidebar.reportMonthAndDay'),
      icon: <EqualizerIcon/>,
      component: () => <ReportIndex/>,
      //hidden: true
    },
    {
      path: "/invoices",
      name: 'invoice.index',
      title: I18n.t('Sidebar.invoice'),
      icon: <Icon>attach_money</Icon>,
      component: () => <InvoiceIndex/>,
      // hidden: false
    },
    {
      path: "/estimations",
      name: 'estimation.index',
      title: I18n.t('Sidebar.estimation'),
      icon: <FontAwesomeIcon icon={faCalculator} size="lg"/>,
      component: () => <EstimationIndex/>,
    },
    {
      divider: true
    },
    {
      path: "/customers",
      name: 'customer.index',
      title: I18n.t('Sidebar.customer'),
      icon: <FontAwesomeIcon icon={faBuilding} size="lg"/>,
      component: () => <CustomerIndex/>,
    },
    {
      path: "/products",
      name: 'product.index',
      title: I18n.t('Sidebar.product'),
      icon: <FontAwesomeIcon icon={faCubes} size="lg"/>,
      component: () => <ProductIndex/>,
    },
    {
      path: "/areas",
      name: 'area.index',
      title: I18n.t('Sidebar.area'),
      icon: <FontAwesomeIcon icon={faCity} size="lg"/>,
      component: () => <AreaIndex/>,
    },
    {
      divider: true
    },
    {
      path: "/setting-price",
      name: 'settingPrice.index',
      title: I18n.t('Sidebar.settingPrice'),
      icon: <FontAwesomeIcon icon={faYenSign} size="lg"/>,
      component: () => <SettingPrice/>,
    },
    {
      divider: true
    },
    {
      path: "/customer-order",
      name: 'customerOrder.index',
      title: I18n.t('Sidebar.customerOrders'),
      icon: <Icon>list</Icon>,
      component: () => < CustomerOrderIndex/>,
      // hidden: false
    },
    {
      path: "/report-delivery",
      name: 'reportDelivery.index',
      title: I18n.t('Sidebar.reportDelivery'),
      icon: <EqualizerIcon/>,
      component: () => <ReportDeliveryIndex/>,
      //hidden: true
    },
    {
      path: "/admin-delivery",
      name: 'adminDelivery.index',
      title: I18n.t('Sidebar.delivery'),
      icon: <EditAttributes/>,
      component: () => <AdminDeliveryIndex/>,
      //hidden: true
    },
  ],
  driver: [
    {
      path: "/delivery",
      name: 'delivery.index',
      title: I18n.t('Sidebar.delivery'),
      icon: <ExploreIcon/>,
      component: () => <Delivery/>,
      // hidden: true
    },
    {
      path: "/delivery-others",
      name: 'deliveryOthers.index',
      title: I18n.t('Sidebar.deliveryOthers'),
      icon: <FontAwesomeIcon icon={faUserFriends} size="lg"/>,
      component: () => <DeliveryOthers/>,
      // hidden: true
    },
    {
      path: "/poured-amount",
      name: 'pouredAmount.index',
      title: I18n.t('Sidebar.pouringFuels'),
      icon: <Icon>local_gas_station</Icon>,
      component: () => <PouredAmount/>,
      // hidden: true
    },
    {
      path: "/remaining-amount",
      name: 'remainingAmount.index',
      title: I18n.t('Sidebar.remainingFuels'),
      icon: <Icon>opacity</Icon>,
      component: () => <RemainingAmount/>,
      // hidden: true
    }
  ]
}
export default sidebar
