import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { TextField } from 'src/components/Forms';
import DateTimeField from "src/components/Forms/DateTimeField";
import BaseView from "src/views/BaseView";
import ReactGooleMap from "./components/GoogleMap/ReactGoogleMap";
import EquipmentProductTable from "./components/EquipmentProductTable";
import { formatDateField, statusOrder } from "src/config/constant";
import Utils from "src/helpers/utility";
import { I18n } from "src/helpers/I18n";

const styles = theme => ({
  paper: {
    padding: "0px",
    margin: 0,
  },
  marginFilterDate: {
    marginTop: "-10px"
  },
  fontSizeMargin: {
    fontSize: "14px",
    marginTop: "3px"
  },
  tablePadding: {
    padding: '0px'
  },
  positionMap: {
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
      height: "100%",
      position: "fixed",
      // top: '8px',
      bottom: "8px",
      left: "8px",
      right: "8px",
      clear: 'both'
    }
  },
  buttonMarginLeft: {
    marginLeft: '5px'
  },
  buttonBack: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "16px",
      left: '16px'
    }
  },
  focus: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "16px",
      right: '16px'
    }
  },
  scollExpanY: {
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
      position: "fixed",
      bottom: "255px",
      left: "8px",
      right: "8px",
      top: "55px"
    }
  },
  heightExpan: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "35px",
      height: "20px"
    }
  },
  tableHead: {
    padding: "1px 1px"
  },
  paddingTable: {
    padding: "0px 0px"
  },
  paddingTableInfo: {
    padding: "0px 1px 0px"
    // paddingBottom: 0,
    // paddingTop: 0,
    // paddingLeft: 0,
  },
  hiddenScrollPosition: {
    overflow: "hidden",
  },
  gridTable: {
    overFlow: "auto"
  },
  positionRightBottom: {
    [theme.breakpoints.down("sm")]: {
      // textAlign: "right"
    }
  },
  buttonMap: {
    textTransform: "capitalize",
  },
  buttonRight: {
    textAlign: 'right'
  },
  textTypo: {
    // heighlighted-paragraph
    display: "table",
    // borderCollapse: 'separate',
    padding: "2px 10px",
    backgroundColor: "black",
    borderRadius: "5px",
    color: "white",
    wordBreak: "break-all",
    maxWidth: "85%"
  },
  buttonDelivered: {
    backgroundColor: "red",
    color: "white"
  },
  borderExpander: {
    boxShadow: "0px 0px 5px 0px black",
    margin: '15px 3px',
    borderRadius: "8px 8px",
  },
  textAlignRight: {
    textAlign: 'right'
  },
  allExpan: {
    maxHeight: "calc(800vh - 1000px)",
    overflow: "auto",
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      dataRow: null,
      reload: false,
      totalDelivered: 0,
      index: null,
      _id: null,
      statusMap: false,
      focus: false
    };
    this.setFocus = this.setFocus.bind(this)
    this.renderLinkGoto = this.renderLinkGoto.bind(this)
    this.renderGoogleMap = this.renderGoogleMap.bind(this)
    this.setDetailDelivary = this.setDetailDelivary.bind(this)
    this.hiddenMap = this.hiddenMap.bind(this)
  }

  delivery = id => {
    this.goto(`/delivery/${id}`);
  };
  renderGoogleMap() {
    this.setState({ statusMap: true })
  }
  hiddenMap() {
    this.setState({ statusMap: false })
  }
  submitComplete = () => {
    let _id = this.getData(this.state, "dataRow._id", null);
    if (_id) {
      this.props.onChangeStatus({ _id, status: statusOrder.delivered });
    }
  };

  setFocus() {
    this.setState({ focus: !this.state.focus })
  }

  renderLinkGoto(orderStatus, gotoReparate, _id) {
    let link = ''
    if (orderStatus != 3 && !gotoReparate) {
      link = `/delivery/${_id}`
    } else if (gotoReparate) {
      link = `/delivery/${_id}/separate`
    } else {
      link = `/delivery/${_id}/general`
    }
    return link
  }

  renderFilterDate() {
    return (
      <DateTimeField
        label={I18n.t(`Input.order.deliveryDate`)}
        name="deliveryDate"
        format={formatDateField}
        showTime={false}
        autoOk={true}
        value={this.props.date}
        onChange={this.props.onChangeDate}
        InputProps={{
          readOnly: true
        }}
        style={{
          marginTop: "8px"
        }}
      />
    );
  }

  renderDetailFuels(data, index) {
    let { classes } = this.props;
    return ["name", "quantity", "numberDelivered"].map((field, index) => {
      let value = this.getData(data, field, 0);
      if (field === "name") value = I18n.t(`Label.products.${value}`);
      return (
        <TableCell
          key={index}
          align={index == 0 ? "left" : "center"}
          className={classes.paddingTable}
        >
          {value}
        </TableCell>
      );
    });
  }

  renderDetailHead = classes => {
    const fields = {
      fuel: "",
      expectNum: "(L)",
      deliveryNum: "(L)"
    };
    let arr = [];
    for (let key in fields) {
      if (key == 'fuel') {
        arr.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
          </TableCell>
        )
      } else {
        arr.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
            {I18n.t(`Table.order.${key}`)}
          </TableCell>
        )
      }
    }
    return arr;
  };

  renderDetailBody(orderDetails) {
    return orderDetails.map((item, index) => {
      return (
        <TableRow key={index}>{this.renderDetailFuels(item, index)}</TableRow>
      );
    });
  }

  renderProductsTitle = (classes) => {
    const fields = {
      productName: "",
      categoryName: "Input.product.categoryName",
      quantity: "Input.quantity"
    };

    let cells = [];
    Object.entries(fields).forEach(entry => {
      const [key, value] = entry;
      if (key == 'productName') {
        cells.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
          </TableCell>
        )
      } else {
        cells.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
            {I18n.t(value)}
          </TableCell>
        )
      }
    });
    return cells;
  }

  renderProductsDetail = (productsByCategory, classes) => {
    let products = productsByCategory.map(product => product.products).flat();
    let cells = [];
    products.forEach((product, index) => {
      cells.push(
        <TableRow key={index}>
          <TableCell key={product._id} align='left' className={classes.tablePadding}>
            {product.name}
          </TableCell>
          <TableCell key={product._id} align='center' className={classes.tablePadding}>
            {product.categoryName}
          </TableCell>
          <TableCell key={product._id} align='center' className={classes.tablePadding}>
            {product.quantity}
          </TableCell>
        </TableRow>
      );
    });
    return cells;
  }

  renderOrderInfo(order, classes) {
    const arr = [
      { label: "customer.code", field: "customer.code", format: null },
      { label: "customer.name", field: "customer.name", format: null },
      { label: "area.code", field: "area.code", format: null },
      {
        label: "order.deliveryTime",
        field: "deliveryTime",
        format: value => Utils._formatDeliveryTime(value)
      },
      { label: "order.address", field: "deliveryAddress", format: null }
    ];
    return (
      <Table>
        <TableBody>
          {arr.map((item, index) => {
            let value = this.getData(order, item.field, "");
            if (item.format) value = item.format(value);
            return (
              <TableRow key={index}>
                <TableCell
                  className={classes.paddingTableInfo}
                  style={{ width: "30%" }}
                >
                  {I18n.t(`Table.${item.label}`)}
                </TableCell>
                <TableCell
                  className={classes.paddingTableInfo}
                  style={{ width: "70%" }}
                >
                  {value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  setDetailDelivary(index, _id) {
    this.setState({ index: index, _id: _id })
  }

  senRouter(status, _id, gotoReparate) {
    switch (status) {
      case 3:
        this.goto(`/delivery/${_id}`)
        break;
      default:
        if (gotoReparate) {
          this.goto(`/delivery/${_id}/separate`)
        } else {
          this.goto(`/delivery/${_id}/general`)
        }
    }
  }

  render() {
    const { classes, orders = [], histories = [] } = this.props;

    return (
      <div className={classes.paper}>
        <div className={classes.hiddenScrollPosition}>
          <Grid container spacing={4} direction="row" alignItems="center">
            <Grid item xs={7} className={classes.marginFilterDate}>
              {this.renderFilterDate()}
            </Grid>
            <Grid item xs={5} className={classes.buttonRight} >
              {/* <Button variant="contained" color='primary' className={classes.buttonMap} onClick={() => this.renderGoogleMap()} >
                {I18n.t("Button.googleMap")}
              </Button> */}
            </Grid>
          </Grid>
        </div>
        {
          !this.state.statusMap ?
            <div className={classes.allExpan} >
              {
                orders.map((item, index) => {
                  let orderStatus = this.getData(item, "status", "");
                  let _id = this.getData(item, "_id", "");
                  let productsByCategory = this.getData(item, "productsByCategory", []);
                  let orderDetails = this.getData(item, "orderDetails", []);
                  orderDetails = this.props.addNumberDelivered({
                    orderDetails,
                    orderId: _id,
                    histories: this.props.histories
                  });
                  let gotoReparate = false
                  let arrHistories = []
                  this.props.histories.map(element => {
                    if (_id == this.getData(element, 'order', '') && this.getData(element, 'receivedVehicle', '')) {
                      gotoReparate = true
                    }
                    if(element.order === _id) {
                      arrHistories.push(element)
                    }
                  })
                  let totalDelivered = 0;
                  orderDetails.map(item => (totalDelivered = totalDelivered + Number(item.numberDelivered)))
                  let statusExpanded = (this.state.index != null && this.state.index == index) ? true : false
                  let link = this.renderLinkGoto(orderStatus, gotoReparate, _id)
                  return <ExpansionPanel key={_id} expanded={statusExpanded} className={classes.borderExpander}>
                    <ExpansionPanelSummary>
                      <Grid container spacing={0} direction="row" alignItems="center" justify="center">
                        <Grid item xs={9}>
                          <Typography className={classes.textTypo}>
                            {index + 1}. {this.getData(item, "customer.name", "")}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.textAlignRight}>
                          {
                            orderStatus !== 1
                              ? <Button color="inherit" size="small" variant="outlined" style={{ backgroundColor: "red", color: "white" }} >
                                {I18n.t("Button.delivered")}
                              </Button>
                              : ""
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {this.getData(item, "deliveryAddress", "")}
                          </Typography>
                          <Typography>
                            {Utils._formatDeliveryTime(this.getData(item, "deliveryTime", ""))}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.textAlignRight}>
                          {
                            !statusExpanded
                              ? <span>
                                <Button color="primary" size='small' variant='contained' onClick={() => this.setDetailDelivary(index, _id)} >
                                  {I18n.t("Button.detail")}
                                </Button>
                                {orderStatus !== 1 ?  <Button
                                  onClick={() => this.goto(link)}
                                  color="primary" size='small' variant='contained'
                                  className={classes.buttonMarginLeft}
                                >
                                  { I18n.t("Button.edit") }
                                </Button> : "" }
                              </span>
                              : ""
                          }
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                          <Table className={classes.paddingTable}>
                            <TableHead className={classes.paddingTable}>
                              <TableRow>{this.renderDetailHead(classes)}</TableRow>
                            </TableHead>
                            <TableBody className={classes.paddingTable}>
                              {this.renderDetailBody(orderDetails)}
                            </TableBody>
                          </Table>
                          <br />
                          <EquipmentProductTable productsByCategory={productsByCategory} histories={arrHistories} classes={classes} />
                          <br />
                          <Typography> {I18n.t("Input.order.memo")}</Typography>
                          <TextField
                            fullWidth
                            multiline={true}
                            variant="outlined"
                            margin='none'
                            name='memo'
                            rows={4}
                            value={this.getData(item, 'memo', '')}
                            rowsMax={10}
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                          <Button onClick={() => this.setDetailDelivary(null, _id)} color="primary" variant='contained' size='small' >
                            {I18n.t('Button.close')}
                          </Button>
                          {orderStatus !== 1 ?  <Button
                                  onClick={() => this.goto(link)}
                                  color="primary" size='small' variant='contained'
                                  className={classes.buttonMarginLeft}
                                >
                                  { I18n.t("Button.edit") }
                                </Button> : "" }
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                })
              }
            </div>
            :
            <div className={classes.positionMap} >
              <ReactGooleMap orders={orders} focus={this.state.focus} />
              <Button size='small' color='primary' variant='contained' className={classes.buttonBack} onClick={() => this.hiddenMap()}>
                <Icon style={{ fontSize: '15px' }}>arrow_back_ios</Icon>
                {I18n.t("Button.back")}
              </Button>
              <Button
                size='small' color='primary' variant='contained'
                style={{ backgroundColor: `${this.state.focus ? "red" : ""}` }}
                className={classes.focus}
                onClick={() => this.setFocus()}
              >
                {!this.state.focus ? I18n.t("Button.onFocus") : I18n.t("Button.offFocus")}
              </Button>
            </div>
        }
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Index));
