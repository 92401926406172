import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Button,
  // Tooltip
} from '@material-ui/core'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import { I18n } from 'src/helpers/I18n'
import moment from 'moment'
// import _ from 'lodash'
const GridTable = React.lazy(() => import('src/components/Table/GridTable'))

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
    marginRight: '50px'
  },
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  // borderTable: {
  //   border: "groove"
  // },
  gridTable: {
    '& .MuiToolbar-root': {
      display: 'none !important'
    },

    '& .Grid-Root-Table': {
      minHeight: 'calc(100vh - 496px) !important',
      maxHeight: 'calc(100vh - 311px) !important',
      ['@media (max-width:959px)']: {
        minHeight: '0 !important',
      },
      ['@media (min-width:960px) and (max-width:1053px)']: {
        minHeight: 'calc(100vh - 480px) !important',
      },
      ['@media (min-width:1054px) and (max-width:1299px)']: {
        minHeight: 'calc(100vh - 500px) !important',
      },
      ['@media (min-width:1300px)']: {
        minHeight: 'calc(100vh - 496px) !important',
      },
    },

    '& table.MuiTable-root': {
      ['@media (max-width: 767px)']: {
        width: '750px'
      },
      ['@media (min-width: 768px)']: {
        width: '900px'
      },
      ['@media (min-width: 992px)']: {
        width: '100%'
      },
    },
  },
  scrollTable: {
    width: '100%',
    overflow: 'auto',
    marginBottom: '1.5rem',

    '& table.MuiTable-root': {
      ['@media (max-width: 767px)']: {
        width: '750px'
      },
      ['@media (min-width: 768px)']: {
        width: '900px'
      },
      ['@media (min-width: 992px)']: {
        width: '100%'
      },
    }
  },
  gridTableFooter: {
    display: 'flex',
    flex: 'none',
    position: 'relative',
    alignItems: 'center',
    minHeight: '55px',
  },
  gridTableFooterButtonRight: {
    right: '0px',
    position: 'absolute'
  },
  paperFade: {
    marginTop: '0.35rem !important'
  }
});

const Unit = 'L'

class CustomerList extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'name',
          title: I18n.t('Table.customer.name'),
          formatterComponent: (data) => this.getData(data, 'row.name'),
          sortable: false,
          width: "27%"
        },
        {
          name: 'code',
          title: I18n.t('Table.customer.code'),
          formatterComponent: (data) => this.getData(data, 'row.code'),
          sortable: false,
          width: "27%"
        },
        {
          name: 'phone',
          title: I18n.t('Table.customer.phone'),
          formatterComponent: (data) => this.getData(data, 'row.phone'),
          sortable: false,
          width: "27%"
        },
        {
          name: '_id',
          title: I18n.t('Table.preview'),
          filterable: false,
          sortable: false,
          width: "16%",
          formatterComponent: (data) => {
            return this.renderActionsColumn(data)
          }
        },
      ],
      defaultSort: [],
    }
    this.toolbarActions = [];
  }
  exportExcel = (e,data) => {
    e.preventDefault();
    let {year,month} = this.props.filters || {};

    if(!year || !month) return;
    this.props.onExportFile({
      year, type: '1',customerId:data.value,month
    })
  }

  viewReport = (data) => {
    let { filters } = this.props;
    let startDate = moment(filters.startDate).format("YYYYMMDD");
    let endDate = moment(filters.endDate).format("YYYYMMDD");
    let customerId = data.row._id;
    let constructionOpts = data.row.constructionOpts;

    let url = window.config.API_HOST + `/api/authWithSession?token=${localStorage.getItem("token")}&redirect=/api/reports/estimations-${customerId}-${startDate}-${endDate}-${constructionOpts}`
    
    window.open(url);
  }

  renderActionsColumn(data) {
    return (
      <Button color='primary' variant='outlined' onClick={(e)=>this.exportExcel(e,data)}>
        {I18n.t('Button.confirm')}
      </Button>
    )
  }

  addIdToData() {
    const { data = [] } = this.props;
    return data.map((item, index) => ({
      ...item,
      id: this.getData(item, 'row._id', index)
    })
    )
  }

  render() {
    const { classes, onRefTable } = this.props, data = this.addIdToData();

    return (
      <PaperFade className={classes.paperFade} showLoading={true}>
        <GridTable
          id="CustomerList"
          className={classes.gridTable}
          onFetchData={()=>{}}
          onRefTable={onRefTable}
          columns={this.table.columns}
          rows={data || []}
          totalCount={(data || []).length}
          pageSize={(data || []).length}
          showCheckboxColumn={false}
          height="auto"
          filterHiding={true}
          pagingHiding={true}
        />
        <div className={classes.gridTableFooter}>
          {this.renderToolbarActions(data)}
        </div>
      </PaperFade>
    )
  }
}

CustomerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(CustomerList));
