export default {
  translation: {
    app_name: "SKE 受注配車システム",
    company_name: "株式会社SKE",

    Form: {
      noOption: "見つかりません",
    },

    NotFound: {
      title: 'お探しのページは見つかりませんでした。'
    },
    
    Button: {
      login: "ログイン",
      logout: "ログアウト",
      remember: "アカウントを記憶します",
      create: "追加",
      edit: "編集",
      delete: "削除",
      detail: "詳細",
      view: "閲覧",
      back: "戻る",
      close: "閉じる",
      ok: "OK",
      onFocus: "位置更新: オン",
      offFocus: "位置更新: オフ",
      clear: "クリア",
      cancel: "キャンセル",
      save: "保存",
      update: "更新",
      print: "一括印刷", 
      printItem: "印刷",
      export: "エクスポート",
      exportPDF: 'PDF出力',
      exportExcel: 'エクスポート',
      divide: "配車", 
      editSetting: "編集",
      confirm: "確定",
      export2: "出力",
      remaining: "残量表示",
      refueling: "積込数量を入力する",
      generalDelivery: "合計",
      separateDelivery: "個別",
      changePassword: 'パスワード変更',
      googleMap: "Google Map",
      delivered: "納品済",
      delivery: "納品",
      deliveryCompleted: '納品完了',
      listDelivery: '配送一覧',
      add: '追加',
      finish: '済',
      beforeDelivery: '納品前へ',
      agree: '同意する',
    },

    Sidebar: {
      area: "エリアコード",
      customer: "顧客マスター",
      delivery: "配送一覧",
      deliveryOthers: "その他の配達一覧",
      divideOrder: "受注配車",
      driver: "ドライバー",
      order: "注文",
      settingPrice: "金額設定",
      remainingFuels: "残量",
      report: "日報",
      reportMonthAndDay: "月報／日報",
      pouringFuels: "積込数量",
      user: "ユーザー",
      vehicle: "配送車",
      invoice: "請求書",
      customerOrders: "顧客別注文量",
      product: "商品登録",
      estimation: "見積り",
      reportDelivery:"配達量推移",
    },

    Breadcrumb: {
      area: {
        index: "エリアコード",
        create: "エリアコード追加",
        edit: "エリアの情報編集"
      },

      product: {
        index: "商品",
        create: "商品追加",
        edit: "商品の情報編集"
      },

      customer: {
        index: "顧客一覧",
        create: "顧客追加",
        edit: "顧客編集",
        detail: "顧客詳細",
        history: "顧客履歴",
      },

      driver: {
        index: "ドライバー一覧",
        create: "ドライバー追加",
        edit: "ドライバー情報の編集",
        detail: "ドライバー詳細",
        gps: 'GPS'
      },

      order: {
        index: "注文一覧",
        create: "注文追加",
        edit: "注文編集",
        detail: "注文詳細",
        divide: "受注配車",
      },

      user: {
        index: "ユーザー一覧",
        create: "ユーザー追加",
        edit: "ユーザー情報の編集"
      },

      vehicle: {
        index: "配送車一覧",
        create: "配送車追加",
        edit: "配送車編集",
        detail: "配送車の詳細"
      },

      settingPriceIndex: "金額設定",
      invoice: "請求書",
      estimation: "見積り",
      customerOrders: "顧客別注文量",
      reportIndex: "月報／日報",
      delivery: "配達一覧",
      indexDelivery: "納品",
      deliveryType: '納品種別',
      generalDelivery: "納品",
      separateDelivery: "個別追加",
      deliveryOthers: "その他の配達一覧",
      pouredAmount: "積込数量",
      remainingAmount: "残量",
      reportDelivery:"配達量推移"
    },

    Label: {
      all: "すべて",
      amount: "金額",
      chooseDate: "配達日報の日付選択",
      chooseMonth: "配達月報の月選択",
      chooseSizePaper: "用紙サイズ選択",
      chooseProduct: "商品選択",
      createCustomerByPhone: "新規顧客: {{phone}}",
      date: "日付",
      month: "月",
      year: '年',
      discount: "割引",
      actualRemainingFuel: "実際の残量燃料",
      ordersNotDivided: "未配車注文一覧",
      report: "配達日報一覧",
      receivedVehicle: "車番",
      status: "状態",
      tax: "税率",
      totalAmount: "合計",
      endMonth: "月末",
      orderSelected: "選択された受注",
      noAddress: '住所が見つかりません',
      coordinates: "座標",
      additionalDelivery: "納品追加",
      construction: "工事",
      customer: {
        mapTypeValue: {
          '1': 'SKE',
          '2': 'エムシーセンター'
        },
        types: {
          direct: "SKE",
          mediate: "エムシーセンター"
        },
        MCFlags: {
          A: "A",
          B: "B",
          C: "C",
        },
      },

      products: {
        adBlue: "アドブルー",
        diesel: "軽油",
        kerosene: "灯油",
        gasoline: "レギュラー",
        dieselFreeTax: "免税軽油",
        crudeOil: "重油"
      },
      toll:{
        tolltravel:'通行料金',
        tollhighspeed:'高速料金'
      },
      taxes: {
        consumptionTax: "消費税",
        dieselTax: "軽油税"
      },

      insurance: "保証",
      insuranceFee: "保証金額",

      dateRange: {
        startMonth: "1日 - 15日",
        endMonth: "16日 - 31日",
        fromDate: "から",
        toDate: "まで"
      },

      driver: {
        deliverNumber: '配送燃料カード',
        fuelNumber: 'レギュラーカード'
      },

      order: {
        title: "注文",
        info: "注文情報",
        detail: "注文詳細",
        quantity: "注文数量",
        expectNum: "予定数量",
        deliveryInfo: "配送情報",
        total: "注文合計",
        equipmentProducts: "備品商品",
        toll:"通行料金・高速料金"
      },

      statusOrder: {
        waiting: "配車待ち",
        divided: "配車済",
        delivered: "配達済",
        done: "済"
      }
    },

    Input: {
      address: "住所",
      amount: "金額",
      birthday: "生年月日",
      code: "コード",
      cost: "購入価格",
      email: "メールアドレス",
      name: "名前",
      note: "備考",
      phone: "電話番号",
      price: "販売価格",
      quantity: "数量",
      sex: "性別",
      status: "状態",
      date: '日付',
      month: '月',
      construction: '工事',
      curator: "担当",
      auth: {
        username: "アカウント",
        password: "パスワード",
        rePassword: "パスワード確認"
      },

      area: {
        code: "エリアコード番号",
        name: "エリア"
      },

      product: {
        categoryName: "商品カテゴリ",
        name: "商品名",
        unit: "単位"
      },

      customer: {
        code: "顧客コード",
        name: "顧客名",
        type: "対象",
        extendPrice: {
          diesel: "軽油単価",
          kerosene: "灯油単価"
        },
        paymentTerm: "締日",
        flag: "エムシーセンター"
      },

      order: {
        deliveryAddress: "配達場所",
        orderDate: "注文日",
        deliveryDate: "配達日付",
        deliveryTime: "配達時間",
        memo: "メモ",
        constructionNumber: "工事番号",
        constructionName: "工事場所",
        note:"備考",
        noWarranty: "保証無"
      },

      user: {
        username: "アカウント",
        code: "ユーザーコード",
        name: "ユーザー名",
        position: "役職",
        firstName: "名",
        lastName: "姓"
      },

      vehicle: {
        name: "配送車",
        licensePlate: "車番",
        driver: "ドライバー名",
        capacity: "容積",
        remaining: "残量",
        noDriverAvailable: "配車出来るドライバーはいません"
      },
      changePassword: {
        title: "パスワードを変更します",
      },
    },

    Placeholder: {
      quantity: "数量",
      filter: {
        general: "検索",
        date: "選択する",
        dateRange: "時間範囲を選択する"
      }
    },

    Table: {
      action: "動作",
      sorting: "並べ替え",
      address: "住所",
      amount: "金額",
      code: "コード",
      createdAt: "作成日",
      email: "アドレス",
      name: "名称",
      no: "NO.",
      phone: "電話番号",
      price: "販売価格",
      quantity: "数量",
      status: "状態",
      total: "総合計",
      totalAmount: "合計",
      preview: 'プレビュー',

      area: {
        label: "エリア",
        code: "エリアコード",
        name: "エリア名"
      },

      product: {
        categoryName: "商品カテゴリ",
        name: "商品名",
        unit: "単位"
      },

      customer: {
        label: "顧客",
        code: "顧客コード",
        name: "顧客名",
        phone: "電話番号",
        type: "タイプ",
        extendPrice: {
          diesel: "軽油単価",
          kerosene: "灯油単価"
        },
        paymentTerm: "締日"
      },

      driver: {
        code: "コード",
        name: "氏名",
        cardNumber: "カードナンバー"
      },

      order: {
        orderDate: "注文日付",
        deliveryDate: "配達日付",
        deliveryTime: "配達時間",
        address: "住所",
        expectNum: "予定数量",
        deliveryNum: "納品数量",
        fuel: "燃料",
        memo: "メモ",
        constructionName: "工事場所",
        constructionNumber: "工事番号"
      },

      user: {
        username: "アカウント",
        code: "ユーザーコード",
        name: "ユーザー名",
        position: "役職",
        firstName: "姓",
        lastName: "名"
      },

      vehicle: {
        name: "配送車名",
        licensePlate: "ナンバー",
        driver: "ドライバー名",
        capacity: "容積",
        remaining: "残量"
      }
    },

    Validate: {
      required: {
        base: 'この項目は必須項目です。',
        endTime: '開始時間と等しい時間を選択してください',
        auth: {
          username: "この項目は必須項目です。",
          password: "この項目は必須項目です。",
        },
      },
      noJapanese: "この項目には半角英数字とアルファベットのみ入力してください。",
      passwordNotMatch: "パスワードが一致しません。再入力してください !",
      editFuelsTruck: "残量は容積より大きい値を入力できません。",
      invalidFuelsOrder: "予定数量は注文数量より大きくすることはできません",
      emptyFuelsOrder: "予定数量がまだ入力されていません",
      maxLengthMoney: "最大入力可能数字列の長さは${_maxLength}桁です。",
      emptyFuelsExport: "少なくとも１つの燃料種類に数量入力する必要があります。",
      emptyReceivedVehicle: "「車番」項目は必須項目です。",
      min: "入力可能の最小値は${arguments[0]}です。",
      max: "入力可能の最大値は${arguments[0]}です。",
      minLength: "${arguments[0]}桁以上に入力してください。",
      maxLength: "${arguments[0]}桁以下に入力してください。",
      lessThan: "${arguments[0]}より小さいな値を入力してください。",
      greatThan: "${arguments[0]}より大きいな値を入力してください。",
      minDate: "入力可能の最小値は${minDate}です",
      dateInvalid: "有効な日付を入力してください。",
      soonerDeliveryDay:"未来の納品を早めて登録できません。",
      phone: {
        minLength: " 電話番号の項目に${_minLength}桁以上に入力してください",
        maxLength: " 電話番号の項目に${arguments[0]}桁以下に入力してください"
      },
      email: {
        format : "メールアドレスは不正です。"
      },
      number: {
        onlyNumber: "数字のみを入力してください。"
      },
      truck: {
        capacity: "容積の項目に残量より大きな値を入力してください",
        remaining: "残量の項目に容積より小さいな値を入力してください"
      },
    },

    Common: {
      deliveryTime: {
        morning: "AM",
        afternoon: "PM",
        anytime: "いつでも",
        unknown: "不明"
      }
    },

    Confirm: {
      delete: "選択したデータを削除してよろしいですか。",
      update: "更新しますか？",
      updatePrice: "単価を更新してもよろしいですか。",
      undivide: "この受注をキャンセルしてもよろしいですか。",
      completeOrder: "この受注の状態が完了に変更されますがよろしいでしょう？",
      finishInputDelivered: "」項目を入力してください",
      logout: "ログアウトしますが、よろしいでしょうか？",
      reset: "本件のデータをリセットします。よろしいでしょうか？",
    },

    Notification: {
      delete: "削除したデータは復元できませんのでご注意ください。",
      update: "",
    },

    Message: {
      success: {
        create: "データが追加されました。",
        update: "データが更新されました。",
        delete: "データが削除されました。",
        noOrdersSelected: "受注がまだ選択されていません",
        // custom for another subject
        customer: {
          create: "新顧客が追加されました",
          update: "顧客が更新されました"
        },
        order: {
          create: "新しい注文が追加されました",
          update: "注文が更新されました",
        },
        group: {
          create: "新しいグループが追加されました。",
        },
       
      },

      error: {
        base: "エラーが発生しましたので、もう一度お試しください。",
      },

      divideOrder: {
        haveParams: "注文$ {arguments [0]}は保存されました。 今すぐ分区するには「確認」をクリックしてください。",
        invalidFuelsDialogTitle: "燃料が足りません",
        invalidFuelsDialogContent: "${name}の配送車の容積が足りないので、この注文を加えて配達できませんが、引き続けますか？"
      },
      noAddress: "ドライバーがまだログインしないので、位置情報を取得できません。"
    },

    Tooltip: {
      create: "作成",
      edit: "情報編集",
      editHistory:"顧客履歴編集",
      editPrice: "単価編集",
      updatePrice: "単価更新",
      detail: "詳細",
      delete: "削除",
      exportCSV: "CSVへ出力",
      exportExcel: "Excelへ出力",
      print: "印刷",
      shareOrder: "配車",
      deliver: "配達",
      completeOrder: "配達を完了する",
      gps: "GPS"
    },

    Pagination: {
      backIconButtonText: '前のページ',
      labelRowsPerPage: '表示:',
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count} 件`,
      nextIconButtonText: '次のページ',
      noData: "見つかりません"
    }
  }
}
