import React from 'react';
import {
    TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper
} from '@material-ui/core';
import PaperFade from 'src/components/Main/PaperFade'
import { I18n } from 'src/helpers/I18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYenSign } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types'
import _ from 'lodash'

const ProductList = ({ classes, orders }) => {
    if (orders.length > 0) {
        return (
            <PaperFade className={classes.paperFade}>
                <TableContainer component={Paper}>
                    <Table minWidth={650}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.productTitle}>{I18n.t('Table.customer.name')}</TableCell>
                                {orders[0].products.map(product => (
                                    <TableCell className={classes.productTitle} align="center">{product.name}</TableCell>
                                ))}
                                <TableCell className={classes.productTitle} align="center">金額</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map(order => (
                                <TableRow>
                                    <TableCell>
                                        {order.customerName}
                                    </TableCell>
                                    {order.products.map(product => (
                                        <TableCell align="center">
                                            {product.quantity ? (`${product.quantity}${product.unit}`) : 0}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center">
                                        <FontAwesomeIcon icon={faYenSign} className={classes.icon} />
                                        {Math.round((_.sumBy(order.products, (p) => parseInt(p.quantity || 0) * parseFloat(p.unitPrice || 0) * (parseFloat(p.tax || 0)+ 1)) + Number.EPSILON) * 100) / 100}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PaperFade>);
    } else {
        return '';
    }
}

ProductList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default ProductList;