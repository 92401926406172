import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField, MoneyField } from 'src/components/Forms';
import { BaseView } from 'src/views/BaseView';
import { Grid, Typography, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYenSign } from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    vertCenter: {
        display: "flex",
        alignItems: "center",
    },
});

class EquipmentFieldPrice extends BaseView {
    constructor(props) {
        super(props);
        this.state = {
            value: null
        }
    }

    render() {
        const { classes, readOnly, idName, idValue, categoryName, categoryValue, productName, productValue, unitPriceName, unitPriceValue = "0", unitName, unitValue } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} className={classes.vertCenter}>
                    <Typography variant="subtitle1">{productValue}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <MoneyField
                        fullWidth
                        name={unitPriceName}
                        value={unitPriceValue}
                        InputProps={{
                            startAdornment:
                                <InputAdornment className={classes.inputAdornment}>
                                    <FontAwesomeIcon icon={faYenSign} className={classes.icon} />
                                </InputAdornment>,
                            readOnly: readOnly
                        }}
                    />
                </Grid>

                <TextField
                    name={idName}
                    value={idValue}
                    type="hidden"
                    InputProps={{
                        disableUnderline: true
                    }}
                />

                <TextField
                    name={categoryName}
                    value={categoryValue}
                    type="hidden"
                    InputProps={{
                        disableUnderline: true
                    }}
                />

                <TextField
                    name={productName}
                    value={productValue}
                    type="hidden"
                    InputProps={{
                        disableUnderline: true
                    }}
                />

                <TextField
                    name={unitName}
                    value={unitValue}
                    type="hidden"
                    InputProps={{
                        disableUnderline: true
                    }}
                />
            </Grid>
        )
    }
}

EquipmentFieldPrice.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EquipmentFieldPrice);
