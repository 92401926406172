import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DeliveryAction from 'src/actions/DeliveryAction';
// import HistoryAction from 'src/actions/HistoryAction'
import BaseContainer, {selector} from 'src/containers/BaseContainer';
import View from 'src/views/DeliveryOthers/Index';
class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.onFetchData = this.onFetchData.bind(this)
    this.onRefTable = this.onRefTable.bind(this)
  }

  componentWillUnmount(){
    localStorage.removeItem("dateDeliveryOthers")
  }

  componentWillMount() {
    this.onFetchData()
    localStorage.removeItem('datePouredAmount')
  }

  onFetchData(state = {}) {
    this.props.dispatch(DeliveryAction.fetchForOthers(state))
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  render() {
    return <View
      onFetchData={this.onFetchData}
      onRefTable={this.onRefTable}
      trucks={this.props.orders}
    />
  }
}

const mapStateToProps = state => {
  return {
    orders: selector(state, "delivery.listOther", []),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
