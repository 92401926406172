import React from 'react'
import { I18n } from 'src/helpers/I18n'
import { CardContent, Typography } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

const SettingTax = ({ classes, renderChip, renderInput, renderAdornments, settingPrice, isEditing, getData }) => (

    <CardContent className={classes.cardBackground}>
        <Typography variant="h6" className={classes.cardTitle}>
            {I18n.t("Label.tax")}
        </Typography>
        {renderChip(classes, I18n.t("Label.tax"), <AttachMoneyIcon fontSize="small" />)}
        {renderInput(classes, settingPrice, isEditing, I18n.t(`Label.taxes.consumptionTax`), "taxes.consumptionTax", 'end', getData, renderAdornments)}
        {renderInput(classes, settingPrice, isEditing, I18n.t(`Label.taxes.dieselTax`), "taxes.dieselTax", 'end', getData, renderAdornments)}
    </CardContent>
)

export default SettingTax;