import React from 'react';
import View from 'src/views/ReportDelivery/Index'
import ReportAction from '../../actions/ReportAction';
import CustomerAction from '../../actions/CustomerAction'
import BaseContainer, {selector} from 'src/containers/BaseContainer';
import SettingAction from '../../actions/SettingPriceAction'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import moment from 'moment'
import {saveFile} from 'src/helpers/File';
import {dateFormatBackend,formatYearField} from 'src/config/constant';
moment.defaultFormat = dateFormatBackend;

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.filters = {}
    this.state = {
      agencies: undefined
    }
  }

  componentDidMount(){
    this.onFetchAgencies({})
  }
  getDeliveryDate({startDate, endDate}){
    return {
      startDate: startDate && moment(startDate).format(formatYearField),
      endDate: endDate && moment(endDate).format(formatYearField)
    }
  }

  onFetchData = (state = {}) => {
    this.DeliveryDate = this.getDeliveryDate(state);
    this.filters = state;
  }

  onFetchAgencies = () => {
    this.props.dispatch(SettingAction.fetchAgencies({})).then(response => {
      if(!response.error) {
        this.setState({agencies: response.data})
      }
    })
  }
  
  onExportFile = (state) => {
    this.props
      .dispatch(ReportAction.exportDelivery({...state, exportExcel: true}))
      .then(response => {
        let {path, fileName} = this.getData(response, 'data', {});
        if(path) {
          saveFile(this.getData(response, 'data', {}) || 'The invoices.xls')
        } else {
          console.warn('Not found path!!!')
        }
      })
      .catch(error => console.warn('cant export file!!!'))
  }

  onRefTable = (ref) => this.refTable = ref;

  render() {
    return (
      <View
        onFetchData={this.onFetchData}
        onRefTable={this.onRefTable}
        filters={this.filters}
        onExportFile={this.onExportFile}
        agencies={this.state.agencies}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    customers: selector(state, "customer.list.list_data", []),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
