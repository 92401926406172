import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {SnackbarProvider, enqueueSnackbar} from 'notistack';
import {connect} from 'react-redux';

const styles = theme => ({
  info: {backgroundColor: `${theme.palette.info.main}`},
});

class NotistackConsumer extends React.Component {
  handleClick = () => {
    enqueueSnackbar('I love snacks.');
  };

handleClickVariant = variant => () => {
    // variant could be success, error, warning or info
    this.props.enqueueSnackbar('This is a warning message!', {variant});
  };

  componentDidUpdate(prevProps) {
    if (this.props.notify) {
      enqueueSnackbar(this.props.notify.message, {
        anchorOrigin: {
          vertical: window.innerWidth <= 599 ? 'top' : 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 5000,
        variant: this.props.notify.type
      });
    }
  }

  render() {
    return ""
  }
}

NotistackConsumer.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    notify: state.utility.notify
  }
}
NotistackConsumer = connect(mapStateToProps)(NotistackConsumer);

class Notistack extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <SnackbarProvider
        maxSnack={3}
        classes={{ variantInfo: classes.info }}
      >
        <NotistackConsumer/>
      </SnackbarProvider>
    )
  }
}

export default withStyles(styles)(Notistack);
