import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SettingPriceAction from 'src/actions/SettingPriceAction'
import ProductAction from 'src/actions/ProductAction';
import BaseContainer, { selector } from 'src/containers/BaseContainer'
import View from 'src/views/SettingPrice/Index'
import { I18n } from 'src/helpers/I18n'
import moment from 'moment'

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(SettingPriceAction.fetch());
    this.props.dispatch(ProductAction.fetchAllByCategory());
    localStorage.removeItem('divideOrderLocal')
    localStorage.removeItem('idTableOld')
  }

  componentDidUpdate() {
    let { error } = this.props
    if (error && error.status) {
      this.notify(`${error.message}`, 'error')
    }
  }

  onFetchData(month) {
    this.props.dispatch(SettingPriceAction.fetch({ month: month }));
  }

  onSubmit(values) {
    let _values = { ...values, applyAt: moment(values.month).format("YYYY-MM") }
    this.props.dispatch(SettingPriceAction.edit(_values))
      .then(data => {
        if (!data.error) {
          this.notify(I18n.t('Message.success.update'))
          this.goto("/setting-price")
        } else {
          this.notify(`${data.error.message}`, 'error')
        }
      })
  }

  render() {
    return (
      <View
        onSubmit={this.onSubmit}
        onFetchData={this.onFetchData}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => {
  console.log('Setting.mapStateToProps', state);
  return {
    settingPrice: selector(state, "settingPrice.data", {}),
    productsByCategory: selector(state, 'product.list.list_data.0', {}),
    error: selector(state, "settingPrice.error", {})
  }
}

export default withRouter(connect(mapStateToProps)(Index))
