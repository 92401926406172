import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom'
import { 
  TableContainer, Table, 
  TableBody, TableCell, TableRow, Paper
} from '@material-ui/core';
import PaperFade from 'src/components/Main/PaperFade';
import BaseView from 'src/views/BaseView'
import {I18n} from 'src/helpers/I18n';
import Utils from 'src/helpers/utility';

const GridTable = React.lazy(() => import('src/components/Table/GridTable'))
const styles = theme => ({
  tableContainer: {
    borderRadius: '0',
    borderBottom: '0',
    '& th, & td': {
      wordBreak: 'break-all'
    }
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'no',
          title: I18n.t('Table.no'),
          filterable: false,
          sortable: false,
          width: 80
        },
        {
          name: 'name',
          title: I18n.t('Table.vehicle.name'),
          width: 200
        },
        {
          name: 'licensePlate',
          title: I18n.t('Table.vehicle.licensePlate'),
          width: 150
        },
        {
          name: 'driver.fullName',
          title: I18n.t('Table.vehicle.driver'),
          width: 200,
          formatterComponent: (data) => {
            return this.customDriverColumn(data)
          }
        },
        {
          name: 'capacity',
          filterable: false,
          sortable: false,
          width: 250,
          title: I18n.t('Table.vehicle.capacity'),
          formatterComponent: (data) => {
            return this.customFuelsColumn(data)
          }
        },
        {
          name: 'remain',
          filterable: false,
          sortable: false,
          width: 250,
          title: I18n.t('Table.vehicle.remaining'),
          formatterComponent: (data) => {
            return this.customFuelsColumn(data)
          }
        },
        {
          name: '_id',
          title: I18n.t('Table.action'),
          filterable: false,
          sortable: false,
          width: 120,
          formatterComponent: (data) => {
            return this.renderActionsColumn(data)
          }
        },
      ],
      defaultSort: [],
    }
    this.ConfirmDeleteDialog = null;
    this.toolbarActions = [
      {key: "create", path: "/vehicles/create", type: "button", 'btnText': I18n.t("Button.add")}
    ];
    this.columnActions = [
      {key: "edit", path: "/vehicles/edit", icon: "edit"},
      {key: "detail", path: "/vehicles/detail", icon: "assignment"}
    ];
    this.renderToolbarActions = this.renderToolbarActions.bind(this)
    this.renderSelectedActions = this.renderSelectedActions.bind(this)
  }

  customFuelsColumn = (data) => {
    const {classes} = this.props;
    const capacity = Utils.sortFulesByPriority(this.getData(data.row, "capacity", {})) // fix bug #22362
    const remain = Utils.sortFulesByPriority(this.getData(data.row, "remain", {}))
    let results = []

    switch (data.column.name) {
      case "capacity":
        if (Object.keys(capacity).length) {
          for (let fuel in capacity) {
            results.push(
              <TableRow key={fuel}>
                <TableCell component="th" scope="row">
                  {I18n.t(`Label.products.${fuel}`)}
                </TableCell>
                <TableCell align="right">
                  {capacity[fuel] || 0}&nbsp;L
                </TableCell>
              </TableRow>
            )
          }
        }
        break;
      case "remain":
        if (Object.keys(remain).length) {
          for (let fuel in remain) {
            if(fuel in remain) {
              results.push(
                <TableRow key={fuel}>
                  <TableCell component="th" scope="row">
                    {I18n.t(`Label.products.${fuel}`)}
                  </TableCell>
                  <TableCell align="right">
                    {remain[fuel] || 0}&nbsp;L
                  </TableCell>
                </TableRow>
              )
            }
          }
        }
        break;
      default:
        break;
    }
    // return <ul style={{paddingLeft: '15px'}}>{results}</ul>

    return (
      <TableContainer className={classes.tableContainer} component={Paper} variant="outlined">
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {results}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  customDriverColumn = (data) => {
    let driver = data.value || this.getData(data.row, 'driver.fullName', 'N/A')
    return <p>{driver}</p>
  }

  render() {
    const {classes} = this.props;
    let vehicles = this.getData(this.props, 'vehicles', {})
    return (
      <PaperFade showLoading={true}>
        <GridTable
          id="TruckIndex"
          className={classes.gridTable}
          onFetchData={this.props.onFetchData}
          onRefTable={this.props.onRefTable}
          columns={this.table.columns}
          rows={vehicles.list_data || []}
          totalCount={vehicles.total || 0}
          pageSize={vehicles.limit || 20}
          defaultSort={this.table.defaultSort}
          showCheckboxColumn={true}
          height="auto"
          selectedActions={this.renderSelectedActions}
          tableActions={this.renderToolbarActions}
        />
        {this.renderDialogConfirmDelete()}
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
