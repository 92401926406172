import React from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateTimeField from 'src/components/Forms/DateTimeField';
import PaperFade from 'src/components/Main/PaperFade';
import {BaseView} from 'src/views/BaseView';
import ElementForm from './components/InputForm';
import {dateFormatBackend, dateFormatDefault, dateTimeFormatDefault, fuelProducts} from "src/config/constant";
import {I18n} from 'src/helpers/I18n';
import moment from 'moment';

moment.defaultFormat = dateFormatBackend;

const styles = theme => ({
  paper: {
    minHeight: "calc(100vh - 85px)",
    [theme.breakpoints.down('xs')]: {
      minHeight: 0,
      padding: '5px 5px',
      margin: 0
    },
  },
  histories: {
    maxHeight: 'calc(100vh - 180px)',
    padding: '10px 0px',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '70vh',
      overflowY: 'auto'
    }
  },
  heightExpan: {
    [theme.breakpoints.down('xs')]: {
      minHeight: "35px",
      height: "20px",
    },
  },
  fontSizeExpan: {
    fontSize: '14px',
  },
  textCenter: {
    textAlign: 'center'
  },
  borderExpander: {
    boxShadow: "0px 0px 5px 0px black",
    margin: '7px 5px',
    borderRadius: "8px 8px !important",
  },
  borderRadiusExpan: {
    borderRadius: "8px 8px",
  },
  btnEdit: {
    float: 'right',
    marginRight: '20px'
  },
})

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      isEditing: true,
      reload: false,
    }
    this.onChangeDate = this.onChangeDate.bind(this)
  }

  onChangeDate(date) {
    this.setState({date: date})
    this.props.onFetchData(moment(date).format('YYYY-MM-DD'));
    localStorage.setItem("datePouredAmount", moment().format('YYYY-MM-DD'))
  }

  renderFilterDate() {
    let datePouredAmount = localStorage.getItem("datePouredAmount")
    let date = this.state.date 
    if(datePouredAmount){
      date = datePouredAmount
    }
    return (
      <DateTimeField
        label={I18n.t(`Placeholder.filter.date`)}
        name="date"
        format={'YYYY/MM/DD'}
        showTime={false}
        autoOk={true}
        value={date}
        onChange={(value) => this.onChangeDate(value)}
        InputProps={{
          readOnly: true
        }}
        style={{
          marginTop: '7px'
        }}
      />
    )
  }

  renderButtonRefuel() {
    return (
      <div className={this.props.classes.textCenter}>
        <Button 
          size='medium' 
          variant="contained" 
          color="primary" 
          onClick={() => this.refElementForm.onClickBtn()}>
          {I18n.t('Button.refueling')}
        </Button>
      </div>
    )
  }

  renderButtonEditRefuel(data) {
    console.log('data', data)
    return (
      <div className={this.props.classes.textCenter}>
        <Button 
          className={this.props.classes.btnEdit}
          size='medium' 
          variant="contained" 
          color="primary" 
          onClick={() => this.refElementForm.onEditBtn(data)}>
          {I18n.t('Button.edit')}
        </Button>
      </div>
    )
  }

  renderHistory() {
    const {classes, histories} = this.props;
    if (!histories.length) return '';
    return histories.map((item, index) => {
      let orderDetails = this.getData(item, 'details', [])
      let time = this.getData(item, 'insert.when', null);
      if (time) time = moment(time).format(dateTimeFormatDefault);

      return (
        <ExpansionPanel key={index}>
          <ExpansionPanelSummary conso expandIcon={<ExpandMoreIcon/>} className={classes.heightExpan} >
            <Grid container spacing={1} alignItems='flex-end' direction='row' >
              <Grid item xs={6}>
                <Typography className={classes.fontSizeExpan} >{index + 1}, {time}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {this.renderButtonEditRefuel(item)}
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    fuelProducts.map(fuel =>
                      <TableCell align='center' className={classes.paper} key={fuel}>
                        {I18n.t(`Label.products.${fuel}`)}
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {
                    orderDetails.map((detail, count) =>
                      <TableCell key={count} align='center' className={classes.paper}>
                        {this.getData(detail, 'quantity', 0)}
                      </TableCell>
                    )
                  }
                </TableRow>
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    })
  }

  getIsEditing = (isEditing) => {
    this.setState({ isEditing: isEditing, reload: !this.state.reload })
  }

  render() {
    const {classes, onSubmit} = this.props
    return (
      <PaperFade className={classes.paper}>
        {this.state.isEditing &&
          <span>
            {this.renderFilterDate()}
            <br/>
            {this.renderButtonRefuel()}
            <br/>
            <div className={classes.histories}>
              {this.renderHistory()}
            </div>
          </span>
        }
        <br/>
        <ElementForm
          classes={classes}
          onSubmit={onSubmit}
          getIsEditing={this.getIsEditing}
          ref={ref => this.refElementForm = ref}
        />
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
