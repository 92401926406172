import BaseAction from './BaseAction'

class SortedOrder extends BaseAction {
  get actions() {
    return {
    getSortedOrders: {
        method: 'get',
        url: '/api/orders/getSortedOrders',
        type: 'SortedOrder.getSortedOrders'
      }
    }
  }
}

export default SortedOrder.export()
