import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import BaseField from './BaseField';
import { connectField } from './Connect'
import InputAdornment from '@material-ui/core/InputAdornment'
import MaskedInput from 'react-text-mask';
import { NumericFormat } from 'react-number-format';

const debug = require("debug")("mq:form:MoneyField")
const styles = theme => ({
    textField: {
    },
    'input': {
        '&::placeholder': {
            fontSize: "14px !important",
        }
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
})

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={ values => { onChange({ target: { value: values.value } })}}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

class MoneyField extends BaseField {
    constructor(props) {
      super(props)
      this.state = {
        ...this.state,
        defaultValue: props.defaultValue || props.value
      }
    }

    render() {
        debug("render TextField: ", this.props.name)
        debug("props: ", this.props)
        const { 
          margin, isEditing, renderAdornments, defaultValue, classes, 
          className, name, InputProps, positionAdornments = 'end', customInputProps = {}, ...otherProps 
        } = this.propsRemovedIgrone()

        let nameAdornment = `${positionAdornments}Adornment`;

        return (
            <TextField
              {...otherProps}
              name={name}
              error={this.state.error ? true : false}
              helperText={this.state.error}
              className={`${classes.TextField} ${className}`}
              margin={margin || "normal"}
              inputRef={ref => this.ref = ref}
              value={this.state.value}
              onChange={(e) => this.onChange(e.target.value)}
              onBlur={e => this.onBlur(e)}
              InputProps={{ 
                inputComponent: NumberFormatCustom,
                [nameAdornment]: <InputAdornment position={positionAdornments}> {renderAdornments} </InputAdornment>,
                readOnly: !isEditing,
                ...customInputProps
              }}
            />
        )
    }
}

MoneyField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    validate: PropTypes.arrayOf(PropTypes.func),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    margin: PropTypes.string
}

export default withStyles(styles)(connectField(MoneyField))