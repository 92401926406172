import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductAction from '../../actions/ProductAction';
import BaseContainer, { selector } from 'src/containers/BaseContainer';
import View from 'src/views/Product/Index';
import { I18n } from 'src/helpers/I18n';

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.onFetchData = this.onFetchData.bind(this)
    this.onRefTable = this.onRefTable.bind(this)
    this.onDeleteData = this.onDeleteData.bind(this)
    this.onSubmitData = this.onSubmitData.bind(this)
  }

  componentDidUpdate(prevProps) {
    //example for check props change
    /* if(prevProps.x !== this.props.x){
      //props x changed
    } */
  }

  componentDidMount() {
    // localStorage.removeItem('divideOrderLocal')
  }

  onFetchData(state) {
    this.props.dispatch(ProductAction.fetchAll(state))
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  onDeleteData(selectedIds) {
    this.props.dispatch(ProductAction.delete({
      ids: selectedIds
    }))
      .then(result => {
        if (!result.error) {
          //success
          this.notify(I18n.t('Message.success.delete'))
          if (this.refTable) {
            this.refTable.onSelectionChange([])
            this.refTable.onFetchData()
          }
        } else {
          //error
          this.notify(`[${result.error.status}] ${result.error.message}`, 'error')
        }
      })
  }

  async onSubmitData(listEditing) {
    try {
      if (Array.isArray(listEditing)) {
        let promises = listEditing.map(element => {
          this.props.dispatch(ProductAction.edit({
            id: element.id,
            ...element
          }))
        })
        await Promise.all(promises)
        this.notify(I18n.t('Message.success.update'))
      }
    } catch (error) {
      console.log('error', error)
      this.notify(`Đã có lỗi xảy ra!`, 'error')
    }
  }

  render() {
    return (<View
      onFetchData={this.onFetchData}
      onRefTable={this.onRefTable}
      onDeleteData={this.onDeleteData}
      onSubmitData={this.onSubmitData}
      {...this.props}
    />);
  }
}


const mapStateToProps = state => {
  return {
    products: selector(state, "product.list", []),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
