import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {BaseView} from 'src/views/BaseView'
import MapLayers from './MapLayers'
// import Map from './Map'

const styles = theme => ({ })

class GetMap extends BaseView {
	constructor(props) {
		super(props)
	}
	
  render() {
		const { orders, defaultCenter } = this.props
		return (
			<MapLayers 
				focus={this.props.focus}
				options={{ ...this.props }}
				orders={orders}
				defaultCenter={defaultCenter}
			/>
			// </MapLayers>
		)
  }
}

GetMap.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(GetMap))
