import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import OrderAction from '../../actions/OrderAction';
import VehicleAction from '../../actions/VehicleAction';
import BaseContainer, {selector} from 'src/containers/BaseContainer';
import SortedOrderAction from '../../actions/SortedOrderAction';
import View from 'src/views/DivideOrder/Index';
import {I18n} from 'src/helpers/I18n';
import moment from 'moment';
import _ from 'lodash'

class Index extends BaseContainer {
  constructor(props) {
    super(props);
    const queryString = decodeURIComponent(props.location.search).replace('?','')
    const query = new URLSearchParams(queryString);
    let date = query.get('date');

    const defaultDate = moment(date).isValid() ? moment(date) : moment();
    this.state = {
      date: defaultDate
    }
    this.loadData = this.loadData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onRefTable = this.onRefTable.bind(this);
    this.onNotifyHaveParams = this.onNotifyHaveParams.bind(this);
    this.onCancelSelected = this.onCancelSelected.bind(this);
    this.validateBeforeSubmit = this.validateBeforeSubmit.bind(this);
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  loadData(date) {
    date = moment(date || this.state.date).format('YYYY-MM-DD')
    this.setState({ date: date })
    this.props.dispatch(OrderAction.fetchDaily({pageSize: -1, date: date || undefined}));
    this.props.dispatch(SortedOrderAction.getSortedOrders({ pageSize: -1, date: date }));
    this.props.dispatch(VehicleAction.fetchAll({pageSize: -1}));
  }

  componentDidMount() {
    this.loadData()
    localStorage.removeItem('idTableOld')
  }

  submitOrderSort = (values) => {
    this.props.dispatch(OrderAction.sortDivided(values))
  }

  // Xóa đơn hàng
  unDivideOrder = (values) => {
    let date = moment(this.state.date).format('YYYY-MM-DD')
    this.props.dispatch(OrderAction.unDivide({id: values}))
    .then(data => {
      if (!data.error) {
        this.notify(I18n.t('Message.success.update'));
        this.goto(`/divide-order?date=${date}`)
      } else {
        this.notify(`${data.error.message}`, 'error')
      }
    })
  }

  async onSubmit(values) {
    let dateSubmit = _.get(values, 'date', '')
    let date = dateSubmit || moment(this.state.date).format('YYYY-MM-DD')
    this.props.dispatch(OrderAction.divide(values))
      .then(data => {
        if (!data.error) {
          this.notify(I18n.t('Message.success.update'));
          this.goto(`/divide-order?date=${date}`)
        } else {
          this.notify(`${data.error.message}`, 'error')
        }
      })
  }

  onNotifyHaveParams(orderIdParam) {
    let message = I18n.t('Message.divideOrder.haveParams');
    try {
      eval(`message=\`${message}\``)
    } catch (e) {
      console.error("can not build message for onNotifyHaveParams function.")
    }
  }

  validateBeforeSubmit(message, type = "info") {
    this.notify(message, type);
  }

  onCancelSelected() {
    if (this.refTable) {
      this.refTable.onSelectionChange([]) //gọi method API của con thông qua ref
    }
  }

  render() {
    return (<View
      loadData={this.loadData}
      defaultDate={this.state.date}
      onFetchData={this.onFetchData}
      onSubmit={this.onSubmit}
      onRefTable={this.onRefTable}
      orders={this.props.orders}
      onNotifyHaveParams={this.onNotifyHaveParams}
      onCancelSelected={this.onCancelSelected}
      validateBeforeSubmit={this.validateBeforeSubmit}
      submitOrderSort={this.submitOrderSort}
      unDivideOrder={this.unDivideOrder}
      {...this.props}
    />);
  }
}

const mapStateToProps = state => {
  return {
    orders: selector(state, "order.listDaily", []),
    sortedOrder: selector(state, "sortedOrder.data", []),
    trucks: selector(state, "vehicle.list.list_data", [])
  }
}

export default withRouter(connect(mapStateToProps)(Index))
