import React from 'react'
import { I18n } from 'src/helpers/I18n'
import { CardContent, Grid, Typography } from '@material-ui/core'
import { TextField } from 'src/components/Forms';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

const renderSettingMC = (fuelType, renderInput, classes, settingPrice, isEditing, getData, renderAdornments, newAgency = false, index) => {
    return ['A', 'B', 'C'].map(key => {
        let fuelKeyPath = newAgency ? `agencies[${index}][${fuelType}].flag${key}` : `prices.mcCenter.${fuelType}.flag${key}`;
        return renderInput(classes, settingPrice, isEditing, I18n.t(`Label.customer.MCFlags.${key}`), fuelKeyPath, 'start', getData, renderAdornments);
    });
}

const SettingMC = ({ index, agencyName, classes, renderChip, renderInput, renderInsurance, renderAdornments, settingPrice, isEditing, getData, newAgency = false, validation }) => (

    <CardContent className={classes.cardBackground}>
        <Typography variant="h6" className={classes.cardTitle}>
            {(isEditing && newAgency) ?
                <TextField
                    fullWidth
                    name={`agencies[${index}][label]`}
                    value={agencyName}
                    validate={validation}
                />
                : agencyName}
            {(isEditing && newAgency) ?
                <TextField
                    name={`agencies[${index}][value]`}
                    value={index + 3}
                    type="hidden"
                /> : ''}
            {(isEditing && newAgency) ?
                <TextField
                    name={`agencies[${index}][key]`}
                    value={"mediate"}
                    type="hidden"
                /> : ''}
        </Typography>

        <Grid container spacing={4} direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={2} md={2} lg={2}>
                {/* Dầu Diesel */}
                {renderChip(classes, I18n.t(`Label.products.diesel`), <LocalGasStationIcon fontSize="small" />)}
                {renderSettingMC("diesel", renderInput, classes, settingPrice, isEditing, getData, renderAdornments, newAgency, index)}
            </Grid>

            <Grid item xs={12} sm={2} md={2} lg={2}>
                {/* Dầu Hỏa */}
                {renderChip(classes, I18n.t(`Label.products.kerosene`), <LocalGasStationIcon fontSize="small" />)}
                {renderSettingMC("kerosene", renderInput, classes, settingPrice, isEditing, getData, renderAdornments, newAgency, index)}
            </Grid>

            <Grid item xs={12} sm={2} md={2} lg={2}>
                {/* Dầu Thô */}
                {renderChip(classes, I18n.t(`Label.products.crudeOil`), <LocalGasStationIcon fontSize="small" />)}
                {renderSettingMC("crudeOil", renderInput, classes, settingPrice, isEditing, getData, renderAdornments, newAgency, index)}
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}>
                {renderChip(classes, `${I18n.t(`Label.products.gasoline`)}`, <LocalGasStationIcon fontSize="small" />)}
                {renderInput(classes, settingPrice, isEditing, I18n.t(`Label.products.gasoline`), "prices.mcCenter.gasoline", 'start', getData, renderAdornments)} {/* Xăng */}
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3}> {/* Bảo hiểm */}
                {renderChip(classes, I18n.t(`Label.insurance`), <AttachMoneyIcon fontSize="small" />)}
                {renderInsurance(classes, settingPrice, true, I18n.t(`Label.insurance`), "insurance.limit", getData, renderAdornments)}
                {renderInput(classes, settingPrice, true, I18n.t(`Label.insuranceFee`), "insurance.amount", 'start', getData, renderAdornments)}
            </Grid>
        </Grid>
    </CardContent>
)

export default SettingMC;