import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { BaseView } from 'src/views/BaseView';
import PaperFade from "src/components/Main/PaperFade";
import { Grid, Typography, Chip, Divider } from '@material-ui/core';
import EquipmentFieldPrice from './EquipmentFieldPrice';
import _ from 'lodash';

const EquipmentCategory = ({ classes, readOnly, category, productsInCategory }) => {
    let products = [];
    if (Array.isArray(productsInCategory)) {
        productsInCategory.forEach((product, prodIndex) => {
            products.push(
                <Grid container key={prodIndex} item xs={12} lg={2}>
                    <EquipmentFieldPrice
                        readOnly={readOnly}
                        label={product.name}
                        idName={`productsByCategory['${category}'][${prodIndex}]._id`}
                        idValue={product._id}
                        categoryName={`productsByCategory['${category}'].categoryName`}
                        categoryValue={product.categoryName}
                        productName={`productsByCategory['${category}'][${prodIndex}].name`}
                        productValue={product.name}
                        unitPriceName={`productsByCategory['${category}'][${prodIndex}].unitPrice`}
                        unitPriceValue={product.unitPrice}
                        unitName={`productsByCategory['${category}'][${prodIndex}].unit`}
                        unitValue={product.unit}
                    />
                </Grid>
            )
        });
    }

    return (
        <Grid container spacing={4} key={category}>
            <Grid container item xs={12} lg={12} className={classes.category}>
                <Chip variant="outlined" color="primary" tabIndex={-1} label={category} />
            </Grid>
            {products}
        </Grid>);
}

class EquipmentFormPrice extends BaseView {
    constructor(props) {
        super(props)
        this.state = {
            productsByCategory: [],
            reload: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        let { productsByCategory } = nextProps;
        this.setState({ productsByCategory: productsByCategory })
    }

    renderEquipmentProducts = (readOnly, classes) => {
        const { productsByCategory } = this.props;
        let categories = [];

        if (productsByCategory) {
            Object.entries(productsByCategory).forEach(entry => {
                const [category, products] = entry;
                categories.push(<EquipmentCategory key={category} classes={classes} readOnly={readOnly} category={category} productsInCategory={products} />);
            });
        }
        return categories;
    }

    render() {
        const { classes, title, readOnly } = this.props;
        return (
            <PaperFade className={classes.paper}>
                <Typography variant="h6" className={classes.title}>{title}</Typography>
                <Grid container spacing={4} className={classes.wrapper} >
                    {this.renderEquipmentProducts(readOnly, classes)}
                </Grid>
            </PaperFade>
        )
    }
}

EquipmentFormPrice.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export default EquipmentFormPrice;
