import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import { I18n } from "src/helpers/I18n";

const renderProductsTitle = (classes) => {
    const fields = {
        name: '',
        expectNum: '',
        deliveryNum: ''
      };

    let cells = [];
    for (let key in fields) {
        cells.push(
        <TableCell key={key} align='left' className={classes ? classes.paddingTable : ''}>
            {
                key === 'name'
                ?
                <>
                    {I18n.t(`Table.product.${key}`)}{fields[key] }
                </>
                :
                <>{I18n.t(`Table.order.${key}`)}{fields[key]}</>
            }
        </TableCell>
      )
    }
    return cells;
}

const renderProductsDetail = (productsByCategory, histories, classes) => {
    let listProducts = []
    Object.entries(productsByCategory).forEach(function ([category, productsInCategory]) {          
        if (productsInCategory) {
        productsInCategory.forEach(function (product, index) {
            if(product.quantity > 0) {
                listProducts.push(product);
            }
        });
        }
    });
    
    let cells = [];
    if (listProducts && listProducts.length > 0) {
        Object.entries(listProducts).forEach(([category, product]) => {
            let quantifyHistory = 0
            
            if(histories !== undefined && histories.length > 0 ) {
                histories.map(history => {
                    if(history.productsByCategory !== undefined && history.productsByCategory.length > 0) {
                        history.productsByCategory.map(item => {
                            if(item !== undefined && product._id === item._id) {
                                quantifyHistory += parseInt(item.quantity)
                            } 
                        })
                    }
                })
            }

            cells.push(
                <TableRow key={product._id}>
                    <TableCell align='left' className={classes.tablePadding}>
                        {product.name}
                    </TableCell>
                    <TableCell align='left' className={classes.tablePadding}>
                        {product.quantity + " "}{product.unit}
                    </TableCell>                        
                    <TableCell align='left' className={classes.tablePadding}>
                        {quantifyHistory + " "}{product.unit}
                    </TableCell>
                </TableRow>
            );
        });
    }
    return cells;
}

const EquipmentProductTable = ({ productsByCategory, histories, classes }) => {
    console.log('EquipmentProductTable.render', productsByCategory[0]);
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>{renderProductsTitle(classes)}</TableRow>
            </TableHead>
            <TableBody>
                {renderProductsDetail(productsByCategory[0], histories, classes)}
            </TableBody>
        </Table>
    );
}

export default EquipmentProductTable;