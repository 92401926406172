import BaseReducer from './BaseReducer';

class SortedOrderReducer extends BaseReducer {
    get actionsAllow() {
        return {
            ...super.actionsAllow,
            "SortedOrder.getSortedOrders": {
                path: "data"
            }
        }
    }

    get initialState() {
        return {
            ...super.initialState,
            error: {
                message: null
            }
        }
    }
}

export default SortedOrderReducer.export()
