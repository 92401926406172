import React, { Component } from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps"
import { compose, withProps } from "recompose"
import _ from 'lodash'
import IconLocation from './myLocation.png'
import {MAP, MARKER} from 'react-google-maps/lib/constants'

const google = window.google = window.google ? window.google : {}
const GOOGLE_MAP_URL = "https://maps.google.com/maps/api/js?key=" + window.config.GOOGLE_API_KEY + "&libraries&v=3.exp&libraries=geometry,drawing,places"

class MapLayers extends Component {
	constructor(props) {
		super(props)
		this.ref = { map: null, markerCenter: null, searchBox: null }
		this.state = {
			options: {},
			zoom: null,
			bounds: null,
			center: { lat: 35.678206, lng: 139.769090 },
			open: true,
			reload: true,
			defaultCenter: { lat: 35.678206, lng: 139.769090 },
		}
	
		this.getPosition = this.getPosition.bind(this)
		this.bounds = null
	}

	async getPosition() {
		return new Promise(r => {
			navigator.geolocation.getCurrentPosition((data) => {
				let position = {
					lat: Number(data.coords.latitude),
					lng: Number(data.coords.longitude)
					// lat: 21.001250700000003, lng: 105.7938183
				}
				//this.setState({ defaultCenter: position, reload: !this.state.reload })
				r(position)
			})
		})
		
	}
	
	async initCenter(){
		const position = await this.getPosition()
		if(this.ref.map) {
			this.ref.map.setCenter(position)
			this.ref.currentPosition.setPosition(position)
		}
	}
	async updateCurrentPosition(){
		const position = await this.getPosition()
		this.ref.currentPosition.setPosition(position)

		if(this.props.focus && !this.ref.map.getBounds().contains(this.ref.currentPosition.getPosition())){
			this.ref.map.panTo(this.ref.currentPosition.getPosition())
		}
	}
	componentWillMount() {
		this.initCenter()
	}

	componentDidMount() {
		this.generateOptions(this.props.options)
		this.intervalTime = setInterval(() => this.updateCurrentPosition(), 60000);
	}

	componentWillUnmount(){
		clearInterval(this.intervalTime)
	}

	componentWillReceiveProps(nextProps) {
		this.generateOptions(nextProps.options)
	}

	renderMarkers(latLng, index) {
		return (
			<Marker position={latLng} key={index} >
				<InfoWindow style={{ display: 'none' }} disableAutoPan={false} >
					<div style={{ padding: '0px', color: 'red' }}>{index + 1}</div>
				</InfoWindow>
			</Marker>
		)
	}

	setRefMap(ref) { //gán function vào ref để có thẻ gọi được từ bên ngoài
		// if (ref) {
		// 	ref.setCenter = (center, ifNotExist) => this.setCenter(center, ifNotExist, true)
		// }
		if(ref) this.ref.map = ref.context[MAP]
		if (typeof this.props.options.onloadMap == "function") {
			this.props.options.onloadMap(ref)
		}
	}
	setZoom(zoom, ifNotExist = false) {
		if (ifNotExist) {
			if (this.state.zoom == null) {
				this.setState({ zoom: zoom })
			}
		} else {
			this.setState({ zoom: zoom })
		}
	}
	setCenter(center, ifNotExist = false, forceRender = false) {
		let lat = _.get(center, 'lat', '')
		let lng = _.get(center, 'lng', '')
		let changedCenter = false
		if (typeof lat == "function") {
			center = center.toJSON();
		}
		if (!isNaN(lat) && !isNaN(lng)) {
			lat = parseFloat(lat)
			lng = parseFloat(lng)
			if (ifNotExist) {
				if (this.state.center == null) {
					this.setState({ center: center })
					changedCenter = true
				}
			} else {
				this.setState({ center: center })
				changedCenter = true
			}

			//kiểm tra nếu đang hiện marker ở tâm thì bắt buộc phải render lại view
			if (changedCenter && (this.props.options.showCenter || forceRender)) {
				this.setState({ reload: !this.state.reload })
			}
		}
	}
	getCenterPosition() {
		return this.state.center
	}
	getZoom() {
		return parseInt(this.state.zoom) > 1 ? this.state.zoom || 15 : 1;
	}
	getOptions() {
		let options = this.state.options
		options['center'] = this.state.defaultCenter
		options['zoom'] = options.zoom || this.getZoom()
		return options
	}

	generateOptions(options) {
		options = Object.assign({}, options)
		options.onCenterChanged = () => this.handleMapCenterChanged()
		options.onZoomChanged = () => this.handleMapZoomChanged()
		options.onBoundsChanged = () => this.handleBoundsChanged()
		options = {
			...options,
			options: {
				mapTypeControl: true, // Chọn loại bản dồ
				streetViewControl: false, // Đường phố
				navigationControl: false, // Dẫn đường
				mapTypeId: google.maps.MapTypeId.TERRAIN,
				zoomControlOptions: { position: google.maps.ControlPosition.LEFT_CENTER },
				fullscreenControlOptions: { position: google.maps.ControlPosition.LEFT_CENTER },
			}
		}
		this.setState({ options: options })
		this.setCenter(this.state.center, true)
		this.setZoom(12)
	}

	handleMapCenterChanged(position) {
		let lat = _.get(position, 'lat', '')
		let lng = _.get(position, 'lng', '')
		if (lat && lng) {
			this.setState({ center: { lat: lat, lng: lng } })
		}
		if (!_.isEmpty(position)) {
			this.setCenter(position)
		} else {
			this.setCenter(this.state.center)
		}
		let { onCenterChanged } = this.props.options
		if (typeof onCenterChanged == "function") {
			onCenterChanged()
		}
	}
	handleMapZoomChanged() {
		this.setState({ zoom: this.ref.map.getZoom() })
		let { onZoomChanged } = this.props.options
		if (typeof onZoomChanged == "function")
			onZoomChanged()
	}
	handleBoundsChanged() {
		this.bounds = this.ref.map.getBounds()
	}

	onPlacesChanged() {
		const places = this.ref.searchBox.getPlaces()
		const bounds = new google.maps.LatLngBounds()
		places.forEach(place => {
			if (place.geometry.viewport) {
				bounds.union(place.geometry.viewport)
			} else {
				bounds.extend(place.geometry.location)
			}
		})

		const nextMarkers = places.map(place => ({ position: place.geometry.location }))
		let nextCenter = _.get(nextMarkers, '0.position', this.getCenterPosition())
		if (typeof nextCenter.lat == "function") {
			nextCenter = nextCenter.toJSON()
		}
		this.handleMapCenterChanged(nextCenter)
		let options = this.state.options
		options.center = nextCenter
		this.setState({ options: Object.assign({}, options), zoom: 12, }, () => {
			options.onZoomChanged()
			if (this.props.onSearchPlace) this.props.onSearchPlace()
		})
	}

	renderOrder() {
		let { orders } = this.props
		if (Array.isArray(orders))
			return orders.map((item, index) => {
				let position = {
					lat: Number(_.get(item, 'mapAddress.latitude', '')),
					lng: Number(_.get(item, 'mapAddress.longitude', ''))
				}
				if (Number(_.get(item, 'mapAddress.latitude', '')))
					return (
						<Marker position={position} key={index} >
							<InfoWindow style={{ display: 'none' }} disableAutoPan={false} >
								<div style={{ padding: '0px', color: 'red' }}>{index + 1}</div>
							</InfoWindow>
						</Marker>
					)
			})
	}

	markerCurrenLocation() {
		let lat = _.get(this.state.defaultCenter, 'lat', '')
		return lat && <Marker
			defaultPosition={this.state.defaultCenter}
			icon={{
				url: IconLocation,
				scaledSize: { width: 35, height: 35 }
			}}
			ref={ref => { if(ref) this.ref.currentPosition = ref.state[MARKER]}}
		>
		</Marker>
	}

	getPixelPositionOffset = (width, height) => ({ x: -(width / 2), y: -(height / 2)})

	render() {
		let options = this.getOptions()
		const markerOrders = this.renderOrder()
		const currenLocation = this.markerCurrenLocation()
		return (
			<GoogleMap
				{...options}
				ref={(ref) => { this.setRefMap(ref) }}
			>
				{markerOrders}
				{currenLocation}
			</GoogleMap>
		)
	}
}

export default compose(withProps({
	googleMapURL: GOOGLE_MAP_URL,
	loadingElement: <div style={{ height: '100%' }} />,
	containerElement: <div style={{ height: '100%', width: '100%' }} />,
	mapElement: <div style={{ height: '100%' }} />
}), withScriptjs, withGoogleMap)(MapLayers)
