import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import CustomerList from './CustomerList'
import FilterForm from './components/FilterForm'
import { customerTypes } from "src/config/constant";
import _ from "lodash";

const styles = theme => ({
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  marginBottomNone: {
    marginBottom: '0px !important'
  }
})

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      type: '1',
      reload: false
    }
    this.getTypeViews = this.getTypeViews.bind(this)
  }

  onSubmit = (values) => {
    this.month = values.month
    this.props.onSubmit(values)
  }

  getTypeViews(type) {
    this.setState({ type: type, reload: !this.state.reload })
  }

  render() {
    const { classes, invoices = {}, loadUser,loadCustomer , filters } = this.props;
    let { type } = this.state;
    let customers = this.getData(this.props, 'customers', {});

    let agencies = this.getData(this.props, 'agencies', []);
    if (Array.isArray(agencies)) {
      agencies = agencies.map(agency => _.pick(agency, _.keys(customerTypes[0])));
    }
    agencies = _.unionBy(customerTypes, agencies, "label");
    agencies = _.remove(agencies, function(n) {
      return n.key==="direct";
    });
    let orders = [];
    let arrInvoices = this.getData(invoices, "lists", []);
    if (arrInvoices && arrInvoices.length) {
      orders = arrInvoices.map(invoice => {
        let order = {};
        order['customerName'] = invoice.customer.name;
        order['products'] = invoice.products;

        return order;
      });
    }

    return (<React.Fragment>
      <PaperFade className={classes.marginBottomNone} showLoading={true}>
        <FilterForm
          customers={customers}
          onSubmit={this.onSubmit}
          loadUser={loadUser}
          loadCustomer={loadCustomer}
          onFetchData={this.props.onFetchData}
          getTypeViews={this.getTypeViews}
          customeropt={this.props.customeropt}
          agencies={agencies}
        />
      </PaperFade>

      <CustomerList
        onFetchData={this.props.onFetchData}
        onRefTable={this.props.onRefTable}
        onExportFile={this.props.onExportFile}
        onSubmit={this.onSubmit}
        data={customers}
        filters={filters}
      />
    </React.Fragment>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
