import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TextField } from 'src/components/Forms';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography
} from '@material-ui/core';
import DateTimeField from 'src/components/Forms/DateTimeField';
import PaperFade from 'src/components/Main/PaperFade';
import BaseView from 'src/views/BaseView';
import IndexMobile from './IndexMobile';
import { formatDateField, statusOrder } from 'src/config/constant';
import Utils from 'src/helpers/utility';
import { I18n } from 'src/helpers/I18n';
import ReactGooleMap from './components/GoogleMap/ReactGoogleMap'
import EquipmentProductTable from './components/EquipmentProductTable';

const GridTable = React.lazy(() => import('src/components/Table/GridTable'))
const styles = theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      maxHeight: "3000px",
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px',
      margin: 0,
    },
  },
  gridTable: {
    overFlow: "auto",
  },
  button: {
    marginLeft: '5px'
  },
  heigthMap: {
    [theme.breakpoints.up('md')]: {
      height: "80vh",
      // maxHeight: "1366px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "80vh",
      // maxHeight: "1024px",
    }
  },
  
  marginTopButton: {
    marginTop: '-25px',
    marginLeft: "5px"
  },

  orderMemo: {
    backgroundColor: '#fe0000',
    color: '#ffffff',
    padding: '7px 12px',
    borderRadius: 4,
  }

})

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      reload: false,
      dataRow: '',
      completeOpen: false,
      totalDelivered: 0,
      showMap: false,
      focus: false
    }
    this.renderLinkGoto = this.renderLinkGoto.bind(this)
    this.setFocus = this.setFocus.bind(this)
    this.table = {
      columns: [
        {
          name: 'no',
          title: I18n.t('Table.no'),
          sortable: false,
          width: 100
        },
        {
          name: 'customer',
          title: I18n.t('Table.customer.name'),
          sortable: false,
          width: 200,
          formatterComponent: (data) => this.renderDataField(data, 'customer.name')
        },
        {
          name: 'deliveryAddress',
          title: I18n.t('Table.order.address'),
          sortable: false,
          width: 300,
        },
        {
          name: 'deliveryTime',
          title: I18n.t('Table.order.deliveryTime'),
          sortable: false,
          width: 150,
          formatterComponent: (data) => Utils._formatDeliveryTime(data)
        },
        {
          name: 'memo',
          title: I18n.t('Table.order.memo'),
          sortable: false,
          width: 150,
          formatterComponent: ({ value: data }) => {
            return data ? <span className={props.classes.orderMemo}>メモ有</span> : ""
          },
        },
        {
          name: 'status',
          title: I18n.t('Table.status'),
          sortable: false,
          width: 120,
          formatterComponent: (data) => {
            let orderStatus = this.getData(data, 'row.status', '')
            return (
              orderStatus === 3
                ? <Button color="inherit" variant="outlined" style={{ backgroundColor: "red", color: "white" }} >
                  {I18n.t("Button.delivered")}
                </Button>
                : ""
            )
          }
        },
        {
          name: '_id',
          title: I18n.t('Table.action'),
          sortable: false,
          formatterComponent: (data) => {
            return this.renderActionsColumn(data)
          },
          width: 250
        },
        {
          name: 'constructionName',
          title: I18n.t('Table.order.constructionName'),
          sortable: false,
          width: 250
        },
        {
          name: 'constructionNumber',
          title: I18n.t('Table.order.constructionNumber'),
          sortable: false,
          width: 250
        },
      ],
      defaultSort: [],
    }
  }

  onShow = () => {
    this.setState({ open: true, reload: !this.state.reload })
  }

  onClose = () => {
    this.setState({ open: false, completeOpen: false, reload: !this.state.reload })
  }

  onSetDataDetail = (data) => {
    this.setState({ dataRow: data })
    this.onShow()
  }

  submitComplete = () => {
    let _id = this.getData(this.state, 'dataRow._id', null);
    if (_id) {
      this.props.onChangeStatus({ _id, status: statusOrder.delivered })
    }
    this.onClose()
  }

  dialogComplete() {
    let { totalDelivered } = this.state
    return (
      <Dialog
        open={this.state.completeOpen}
        onClose={this.onCancel}
        aria-labelledby="alert-dialog-title"
        fullWidth
        maxWidth='xs'
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {I18n.t("Tooltip.completeOrder")}
        </DialogTitle>
        <DialogContent>
          {
            totalDelivered > 0
              ? I18n.t("Confirm.completeOrder")
              : I18n.t("Confirm.finishInputDelivered")
          }
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.onClose}>
            {I18n.t("Button.cancel")}
          </Button>
          <Button disabled={totalDelivered > 0 ? false : true} color="primary" onClick={this.submitComplete}>
            {I18n.t("Button.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderLinkGoto(orderStatus, gotoReparate, _id) {
    let link = ''
    if (orderStatus != 3 && !gotoReparate) {
      link = `/delivery/${_id}`
    } else if (gotoReparate) {
      link = `/delivery/${_id}/separate`
    } else {
      link = `/delivery/${_id}/general`
    }
    return link
  }

  renderActionsColumn(data) {
    let { classes } = this.props
    let dataRow = this.getData(data, 'row', {});

    let orderStatus = this.getData(data, 'row.status', '')
    let _id = this.getData(data, 'row._id', '');
    let orderDetails = this.getData(data, 'row.orderDetails', [])
    orderDetails = this.props.addNumberDelivered({
      orderDetails,
      orderId: _id,
      histories: this.props.histories
    })
    let gotoReparate = false
    this.props.histories.map(element => {
      if (_id == this.getData(element, 'order', '') && this.getData(element, 'receivedVehicle', '')) {
        gotoReparate = true
      }
    })

    let totalDelivered = 0
    orderDetails.map(item => totalDelivered = totalDelivered + Number(item.numberDelivered))
    let link = this.renderLinkGoto(orderStatus, gotoReparate, _id)
    return (
      <React.Fragment>
        <Button color="primary" size="small" variant='contained' onClick={() => this.onSetDataDetail(dataRow)}>
          {I18n.t("Button.detail")}
        </Button>
        <Button
          color="primary" size='small' variant='contained'
          className={classes.button}
          onClick={() => this.goto(link)}
        >
          {orderStatus == 3 ? I18n.t("Button.edit") : I18n.t("Button.delivery")}
        </Button>
      </React.Fragment>
    )
  }

  renderDetailHead = (classes) => {
    const fields = {
      fuel: '',
      expectNum: '(L)',
      deliveryNum: '(L)'
    };
    let arr = [];
    for (let key in fields) {
      arr.push(
        <TableCell key={key} align='left' className={classes ? classes.paddingTable : ''}>
          {I18n.t(`Table.order.${key}`)} {fields[key]}
        </TableCell>
      )
    }
    return ''
  }

  renderDetailBody() {
    let { dataRow } = this.state;
    let orderDetails = this.getData(dataRow, 'orderDetails', []);

    orderDetails = this.props.addNumberDelivered({
      orderDetails,
      orderId: dataRow._id,
      histories: this.props.histories
    });

    return (
      orderDetails.map((item, index) => (
        <TableRow key={index}>
          {this.renderDetailFuels(item)}
        </TableRow>
      ))
    )
  }

  renderDetail(classes) {
    let _id = this.getData(this.state, 'dataRow._id', '')
    let orderStatus = this.getData(this.state, 'dataRow.status', '')
    let productsByCategory = this.getData(this.state, 'dataRow.productsByCategory', []);
    let memo = this.getData(this.state, 'dataRow.memo', '')
    let gotoReparate = false
    let histories = []
    this.props.histories.map(element => {
      if (_id == this.getData(element, 'order', '') && this.getData(element, 'receivedVehicle', '')) {
        gotoReparate = true
      }

      if(element.order === _id) {
        histories.push(element)
      }
    })
    let link = this.renderLinkGoto(orderStatus, gotoReparate, _id)
    
    return (
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={this.state.open}
        onClose={this.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>{this.renderDetailHead()}</TableRow>
            </TableHead>
            <TableBody>
              {this.renderDetailBody()}
            </TableBody>
          </Table>
          <EquipmentProductTable productsByCategory={productsByCategory} histories={histories} classes={classes} />

          <TextField
            fullWidth
            multiline={true}
            variant="outlined"
            margin='none'
            name='memo'
            rows={4}
            value={memo}
            rowsMax={10}
            InputProps={{
              readOnly: true
            }}
          />
        </DialogContent>
        <DialogActions style={{ marginRight: "15px", marginBottom: "15px" }}>
          <Button onClick={this.onClose} size='small' color="primary" variant='contained'>
            {I18n.t("Button.close")}
          </Button>
          <Button
            onClick={() => this.goto(link)}
            style={{ marginLeft: '5px' }} size='small' color="primary" variant='contained'
          >
            {orderStatus == 3 ? I18n.t("Button.edit") : I18n.t("Button.delivery")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderDetailFuels(data, classes) {
    return ['name', 'quantity', 'numberDelivered'].map(field => {
      let value = this.getData(data, field, 0);
      if (field === 'name') value = I18n.t(`Label.products.${value}`)
      return (<TableCell align='left' key={field} className={classes ? classes.paddingTable : ''}>{value}</TableCell>)
    })
  }

  showMap() {
    this.setState({ showMap: !this.state.showMap })
  }

  setFocus() {
    this.setState({ focus: !this.state.focus })
  }

  renderDateFilter() {
    let { date, onChangeDate, classes } = this.props;
    return (
      <Grid container spacing={4} alignItems='flex-end' justify="flex-start">
        <Grid item xs={6} sm={6} md={3}>
          <DateTimeField
            label={I18n.t(`Input.order.deliveryDate`)}
            name="deliveryDate"
            format={formatDateField}
            showTime={false}
            autoOk={true}
            value={date}
            onChange={onChangeDate}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={9}>
          <Button
            color='primary' variant='contained'
            className={classes.marginTopButton}
            onClick={this.showMap.bind(this)}
          >
            {!this.state.showMap ? I18n.t("Button.googleMap") : I18n.t("Button.listDelivery")}
          </Button>
          {this.state.showMap
            ? <Button
              color='primary' variant='contained'
              className={classes.marginTopButton}
              style={{ backgroundColor: `${this.state.focus ? "red" : ""}` }}
              onClick={() => this.setFocus()}
            >
              {!this.state.focus ? I18n.t("Button.onFocus") : I18n.t("Button.offFocus")}
            </Button>
            : ""
          }
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, onChangeDate, onChangeStatus, date, orders, addNumberDelivered, histories, sortedOrder } = this.props
    let sortedData = Utils.filterSortedOrderUser(orders, sortedOrder)
    return (
      <PaperFade showLoading={true} className={classes.paper}>
        <Hidden mdUp>
          <div style={{ overflowX: 'hidden', overflowY: "auto" }}>
            <IndexMobile
              classes={classes}
              orders={sortedData}
              onChangeDate={onChangeDate}
              onChangeStatus={onChangeStatus}
              date={date}
              addNumberDelivered={addNumberDelivered}
              histories={histories}
            />
          </div>
        </Hidden>
        <Hidden smDown>
          {this.renderDetail(classes)}
          {this.renderDateFilter()}
          {
            !this.state.showMap
              ? <GridTable
                id="DeliveryIndex"
                className={classes.gridTable}
                onFetchData={this.props.onFetchData}
                onRefTable={this.props.onRefTable}
                columns={this.table.columns}
                rows={orders}
                filterHiding={true}
                pagingHiding={true}
                defaultSort={this.table.defaultSort}
                height="auto"
              />
              : <div className={classes.heigthMap}>
                <ReactGooleMap
                  focus={this.state.focus}
                  orders={orders}
                />
              </div>
          }
        </Hidden>
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
