import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {withRouter} from 'react-router-dom'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade';
import { 
  TableContainer, Table, TableBody,
  TableCell, TableRow, Paper
} from '@material-ui/core';
import {I18n} from 'src/helpers/I18n';

const GridTable = React.lazy(() => import('src/components/Table/GridTable'))
const styles = theme => ({
  tableContainer: {
    borderRadius: '0',
    borderBottom: '0',
    '& th, & td': {
      wordBreak: 'break-all'
    }
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'no',
          title: I18n.t('Table.no'),
          filterable: false,
          sortable: false,
          width: 90
        },
        {
          name: 'code',
          title: I18n.t('Table.driver.code'),
          width: 150
        },
        {
          name: 'fullName',
          title: I18n.t('Table.driver.name'),
          width: 200
        },
        {
          name: 'email',
          title: I18n.t('Table.email'),
          width: 200
        },
        {
          name: 'card',
          title: I18n.t('Table.driver.cardNumber'),
          filterable: false,
          sortable: false,
          width: 275,
          formatterComponent: this.customCardNumColumn
        },
        {
          name: '_id',
          title: I18n.t('Table.action'),
          filterable: false,
          sortable: false,
          width: 250,
          formatterComponent: (data) => {
            return this.renderActionsColumn(data)
          }
        },
      ],
      defaultSort: [],
    }
    this.ConfirmDeleteDialog = null;
    this.toolbarActions = [
      {key: "create", path: "/drivers/create", btnText: I18n.t('Button.add'), type: 'button'}
    ];
    this.columnActions = [
      {key: "gps", path: "/drivers/gps", icon: "place"},
      {key: "edit", path: "/drivers/edit", icon: "edit"},
      {key: "detail", path: "/drivers/detail", icon: "assignment"},
     
    ];
    this.renderToolbarActions = this.renderToolbarActions.bind(this)
    this.renderSelectedActions = this.renderSelectedActions.bind(this)
  }

  customCardNumColumn = (data) => {
    let driverCards = this.getData(data.row, 'driverCards', {});
    let deliverNumber = this.getData(driverCards, 'deliverNumber', 'N/A');
    let fuelNumber = this.getData(driverCards, 'fuelNumber', 'N/A');

    return (
      <TableContainer className={this.props.classes.tableContainer} component={Paper} variant="outlined">
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {I18n.t('Label.driver.fuelNumber')}
              </TableCell>
              <TableCell align="right">{fuelNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {I18n.t('Label.driver.deliverNumber')}
              </TableCell>
              <TableCell align="right">{deliverNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  render() {
    let {classes} = this.props;
    let drivers = this.getData(this.props, 'drivers', {})
    return (
      <PaperFade showLoading={true}>
        <GridTable
          id="DriverIndex"
          className={classes.gridTable}
          onFetchData={this.props.onFetchData}
          onRefTable={this.props.onRefTable}
          columns={this.table.columns}
          rows={[...drivers.list_data || []]}
          totalCount={drivers.total || 0}
          pageSize={drivers.limit || 20}
          defaultSort={this.table.defaultSort}
          showCheckboxColumn={true}
          height="auto"
          selectedActions={this.renderSelectedActions}
          tableActions={this.renderToolbarActions}
        />
        {this.renderDialogConfirmDelete()}
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
