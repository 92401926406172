import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Form, DateTimeField,Validation } from "src/components/Forms";
import CustomerAction from '../../../actions/CustomerAction';
import AutoCompleteField, { Option as OptionAuto } from "src/components/Forms/AutoCompleteField";
import { BaseView } from "src/views/BaseView";
import { I18n } from "src/helpers/I18n";
// import DateUtil from 'src/helpers/date';
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import moment from 'moment';
import {  months } from "src/config/constant";
const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  buttonConfirm: {
    marginTop: "15px",
    textAlign: 'right',
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
    },
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
    }
  }
});

class Create extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
    this.filter = {
      type: 1,
      year: moment().year()
    };
  }
  componentDidMount() {
  }

  
  onHandleChange = (data = {}) => {
    Object.entries(data).forEach(([name, value]) => {
      this.filter[name] = value
    });

    if (this.filter["type"] && data.type) {
      this.filter["type"] = Number(this.filter["type"]); //fix bugs
      // this.props.loadUser(this.filter);
      let conditions = [
        {columnName: "type", value: this.filter["type"], operation: 'equal', "dataType": "number"},
      ]
      this.props.loadCustomer(conditions);
      this.state.data.code="";
    }
    this.setState({ data: { ...this.state.data, ...data } }, () => {
    });
  };

  render() {
    const { classes, onSubmit, customeropt=[], agencies = [] } = this.props;
    let { data } = this.state;
    let month = _.get(data, "month", parseInt(moment().month())+1);
    let code = _.get(data, "code", "");
    let type = _.get(data, "type", "1");
    let year = _.get(data, "year", String(moment().year()));
    if (typeof year != 'string') year = moment(year).format("YYYY");

    return (
      <Form className={classes.form} onSubmit={onSubmit}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <AutoCompleteField
              key="0"
              margin="dense"
              select
              label={I18n.t("Input.customer.type")}
              name="type"
              isMulti={false}
              defaultValue="1"
              onChange={({ value }) => this.onHandleChange({ "type": value })}
              isClearable={false}>
                
              {agencies.map(item => (
                <OptionAuto
                  key={item.value}
                  value={item.value}
                  showCheckbox={false}>
                  {item.label}
                </OptionAuto>
              ))}
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              key="1"
              fullWidth
              margin="dense"
              label={I18n.t("Label.year")}
              name="year"
              format={"YYYY"}
              showTime={false}
              autoOk={true}
              views={["year"]}
              value={year}
              onChange={value => this.onHandleChange({ 'year': value })}
              InputProps={{ readOnly: true }}
              maxDate={Date()}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <AutoCompleteField
              key="2"
              fullWidth
              select
              margin="dense"
              label={I18n.t("Input.customer.code")}
              name="code"
              isMulti={false}
              isClearable={false}
              value={code}
              onChange={value => this.onHandleChange({ 'code': value.value })}
              defaultValue={code}>
              {customeropt
                .reduce((newObj, item) => ([
                  ...newObj,
                  <OptionAuto
                    key={item.code}
                    value={item.code}
                    showCheckbox={false}
                  >
                    {item.name} - {item.code}
                  </OptionAuto>
                ]),
                  [
                    <OptionAuto key="-1" value='' showCheckbox={false}>
                      {I18n.t("Label.all")}
                    </OptionAuto>
                  ]
                )
              }
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={1} lg={1}>
            <AutoCompleteField
              key="1"
              fullWidth
              margin="dense"
              label={I18n.t("Label.month")}
              name="month"
              value={month}
              onChange={({ value }) => this.onHandleChange({ "month": value })}
              isMulti={false}
              isClearable={false}
              validate={[Validation.required(I18n.t('Validate.required.base'))]}
              select
            >
              {months.map(item => (
                <OptionAuto
                  key={item.value}
                  value={item.value}
                  showCheckbox={false}
                >
                  {item.text}
                </OptionAuto>
              ))}
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} className={classes.buttonConfirm}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => this.props.getTypeViews(type)}>
              {I18n.t("Button.confirm")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

Create.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Create);