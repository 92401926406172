import React from 'react'
import { I18n } from 'src/helpers/I18n'
import { CardContent, Grid, Typography } from '@material-ui/core'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'

const renderSettingSKE = (classes, settingPrice, isEditing, getData, renderAdornments, renderChip, renderInput) => {
    return ['diesel', 'gasoline', 'kerosene', 'crudeOil'].map(fuel =>
        (
            <Grid item xs={12} sm={3} md={3} lg={3} key={fuel}>
                {renderChip(classes, I18n.t(`Label.products.${fuel}`), <LocalGasStationIcon fontSize="small" />)}
                {renderInput(classes, settingPrice, isEditing, I18n.t("Label.dateRange.startMonth"), `prices.ske.startMonth.${fuel}`, 'start', getData, renderAdornments)}
                {renderInput(classes, settingPrice, isEditing, I18n.t("Label.dateRange.endMonth"), `prices.ske.endMonth.${fuel}`, 'start', getData, renderAdornments)}
            </Grid>
        )
    )
}

const SettingSKE = ({ classes, renderChip, renderInput, renderAdornments, settingPrice, isEditing, getData }) => (

    <CardContent className={classes.heightSKEAdblue}>
        <Typography variant="h6" className={classes.cardTitle}>
            {I18n.t("Label.customer.types.direct")}
        </Typography>
        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="stretch">
            {renderSettingSKE(classes, settingPrice, isEditing, getData, renderAdornments, renderChip, renderInput)}
        </Grid>
    </CardContent>
)

export default SettingSKE;