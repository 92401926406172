import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, Chip, Grid, withWidth } from "@material-ui/core";
import {
	DateTimeField,
	Form,
	MoneyField,
	MoneyFieldDecimal,
	Validation,
} from "src/components/Forms";
import PaperFade from "src/components/Main/PaperFade";
import { BaseView } from "src/views/BaseView";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent, faYenSign } from "@fortawesome/free-solid-svg-icons";
import { monthFormatBackend, monthFormatDefault } from "src/config/constant";
import { I18n } from "src/helpers/I18n";
import moment from "moment";
import SettingMC from "./components/SettingMC";
import SettingSKE from "./components/SettingSKE";
import SettingAdBlue from "./components/SettingAdBlue";
import SettingTax from "./components/SettingTax";
import SettingAgency from "./components/SettingAgency";
import EquipmentFormPrice from "./components/EquipmentFormPrice";
import _ from "lodash";

moment.defaultFormat = monthFormatBackend;

const styles = (theme) => ({
	cardBackground: {
		[theme.breakpoints.up("md")]: {
			borderStyle: "ridge",
			height: "400px",
		},
	},
	heightSKEAdblue: {
		[theme.breakpoints.up("md")]: {
			borderStyle: "ridge",
			height: "325px",
		},
	},
	cardTitle: {
		marginBottom: "10px",
	},
	form: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
	},
	icon: {
		color: theme.palette.grey.dark,
	},
	chipWraper: {
		alignSelf: "center",
	},
	chip: {
		// position: "absolute"
	},
	label: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px ${theme.spacing(
			2
		)}px !important`,
	},
	button: {
		marginLeft: "5px",
	},

	wrapper: {
		padding: `0px 16px !important`,
	},
	title: {
		paddingBottom: theme.spacing(2),
	},
	category: {
		marginBottom: `-${theme.spacing(2)}px !important`,
		paddingBottom: `${theme.spacing(1)}px !important`,
		paddingTop: `${theme.spacing(4)}px !important`,
	},
});

class Index extends BaseView {
	constructor(props) {
		super(props);
		this.state = {
			showAddForm: false,
			deleteForm: [],
			inputs: [],
			isEditing: false,
			reload: false,
			month: moment().format(),
			dateInput: new Date(),
			number: "",
			agencies: [],
		};
		this.validate = {
			required: [Validation.required(I18n.t("Validate.required.base"))],
		};
		this.onChangeDate = this.onChangeDate.bind(this);
		this.onClickDeleteBtn = this.onClickDeleteBtn.bind(this);
		this.onClickDeleteForm = this.onClickDeleteForm.bind(this);
	}

	onClickAddBtn = () => {
		console.log("check ==== 01 === 00001");
		console.log(this.state.inputs);
		let newInput = `input-${this.state.inputs.length}`;
		this.state.inputs.push(newInput);
		// this.setState((prevState) => ({ inputs: this.state.inputs }));

		console.log(this.state.inputs);
		this.setState({
			inputs: this.state.inputs,
			showAddForm: true,
			isEditing: true,
			reload: !this.state.reload,
		});
	};

	onClickDeleteBtn(value) {
		delete this.state.agencies[value];
		let agencies = this.state.agencies.filter(function (el) {
			return el != null;
		});
		this.setState({ agencies: agencies, isEditing: true, reload: true });
	}

	onClickDeleteForm(value) {
		delete this.state.inputs[value];
		let inputs = this.state.inputs.filter(function (el) {
			return el != null;
		});
		this.setState({ inputs: inputs, reload: true, isEditing: true });
	}

	onClickEditBtn = () => {
		this.setState({
			showAddForm: false,
			isEditing: true,
			reload: !this.state.reload,
		});
	};

	cancel = () => {
		this.goto("/setting-price");
	};

	onChangeDate(value) {
		this.setState({ dateInput: value, reload: !this.state.reload });
		this.props.onFetchData(moment(value, monthFormatDefault).format());
	}

	renderChip(classes, label, icon) {
		return (
			<Grid item xs={12} lg={12}>
				<Chip
					variant="outlined"
					className={classes.chip}
					color="primary"
					icon={icon}
					label={label}
				/>
			</Grid>
		);
	}

	renderAdornments(classes, name) {
		switch (true) {
			case name.indexOf("limit") > -1:
				return <b className={classes.icon}>L</b>;
			case name.indexOf("taxes.consumptionTax") > -1:
				return (
					<FontAwesomeIcon
						icon={faPercent}
						size={"sm"}
						className={classes.icon}
					/>
				);
			default:
				return <FontAwesomeIcon icon={faYenSign} className={classes.icon} />;
		}
	}

	renderInsurance(
		classes,
		settingPrice,
		isEditing,
		label,
		name,
		getData,
		renderAdornments
	) {
		return (
			<Grid key={name} item xs={12} md={12} lg={12}>
				<MoneyField
					label={label}
					name={name}
					isEditing={isEditing}
					value={getData(settingPrice, name, 0)}
					// validate={[
					//   Validation.required(I18n.t("Validate.required.base")),
					//   Validation.maxLength(12, I18n.t("Validate.maxLengthMoney")),
					// ]}
					renderAdornments={renderAdornments(classes, name)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{renderAdornments(classes, name)}
							</InputAdornment>
						),
						readOnly: !isEditing,
					}}
				/>
			</Grid>
		);
	}

	renderInput(
		classes,
		settingPrice,
		isEditing,
		label,
		name,
		positionAdornments = "start",
		getData,
		renderAdornments
	) {
		return (
			<Grid key={name} item xs={12} md={12} lg={12}>
				<MoneyFieldDecimal
					label={label}
					name={name}
					isEditing={isEditing}
					value={getData(settingPrice, name, "0")}
					validate={[
						Validation.required(I18n.t("Validate.required.base")),
						Validation.maxLength(12, I18n.t("Validate.maxLengthMoney")),
					]}
					renderAdornments={renderAdornments(classes, name)}
					positionAdornments={positionAdornments}
				/>
			</Grid>
		);
	}

	componentDidUpdate() {
		if (this.state.agencies && this.state.agencies.length == 0) {
			let finalSettingPrice = this.props.settingPrice;
			let agencies = finalSettingPrice.agencies;
			if (!agencies) {
				agencies = this.getData(this.props, "settingPrice.agencies", []);
			}

			if(agencies && agencies.length > 0) {
				this.setState({ agencies: agencies })
			}
		}
	}

	render() {
		const { classes, onSubmit, settingPrice, productsByCategory } = this.props;
		const { showAddForm, isEditing, month, addForm } = this.state;
		let finalSettingPrice = settingPrice;
		let isError = false;
		if (!finalSettingPrice) {
			finalSettingPrice = this.getData(this.props, "error.extra_info", {});
			if (finalSettingPrice) {
				isError = true;
			}
		}

		let agencies = finalSettingPrice.agencies;
		if (!agencies) {
			agencies = this.getData(this.props, "settingPrice.agencies", []);
		}
		let numOfNewAgencies = agencies ? agencies.length : 0;
		let product1 = finalSettingPrice.productsByCategory
			? finalSettingPrice.productsByCategory[0]
			: {};
		let product2 = productsByCategory;
		// let productPricesByCategory = _.merge(product1, product2);
		let resultMerge = _.defaults(product1, product2);
		let productPricesByCategory = _.pick(resultMerge, _.keys(product2));

		return (
			<PaperFade>
				<Form onSubmit={onSubmit}>
					<Grid
						container
						spacing={2}
						direction="row"
						justify="flex-start"
						alignItems="stretch"
					>
						<Grid item xs={12}>
							<DateTimeField
								label={I18n.t(`Label.date`)}
								name="month"
								format={monthFormatDefault}
								showTime={false}
								autoOk={true}
								views={["year", "month"]}
								value={month}
								onChange={this.onChangeDate}
								InputProps={{
									readOnly: true,
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={9} md={9} lg={9}>
							{" "}
							{/* SKE */}
							<SettingSKE
								classes={classes}
								renderChip={this.renderChip}
								renderInput={this.renderInput}
								renderAdornments={this.renderAdornments}
								settingPrice={finalSettingPrice}
								isEditing={isEditing}
								getData={this.getData}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							<SettingAdBlue
								classes={classes}
								renderChip={this.renderChip}
								renderInput={this.renderInput}
								renderAdornments={this.renderAdornments}
								settingPrice={finalSettingPrice}
								isEditing={isEditing}
								getData={this.getData}
							/>
						</Grid>

						<Grid item xs={12} sm={9} md={9} lg={9}>
							{" "}
							{/* MC Center */}
							<SettingMC
								agencyName={I18n.t("Label.customer.types.mediate")}
								classes={classes}
								renderChip={this.renderChip}
								renderInput={this.renderInput}
								renderInsurance={this.renderInsurance}
								renderAdornments={this.renderAdornments}
								settingPrice={finalSettingPrice}
								isEditing={isEditing}
								getData={this.getData}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={3} lg={3}>
							{" "}
							{/* Thuế */}
							<SettingTax
								classes={classes}
								renderChip={this.renderChip}
								renderInput={this.renderInput}
								renderAdornments={this.renderAdornments}
								settingPrice={finalSettingPrice}
								isEditing={isEditing}
								getData={this.getData}
							/>
						</Grid>

						{this.state.agencies
							? this.state.agencies.map((agency, index) => (
									<Grid item xs={12} sm={9} md={9} lg={9}>
										{" "}
										{/* Other agencies */}
										<SettingAgency
											agencyName={agency.label}
											classes={classes}
											renderChip={this.renderChip}
											renderInput={this.renderInput}
											renderInsurance={this.renderInsurance}
											renderAdornments={this.renderAdornments}
											settingPrice={finalSettingPrice}
											isEditing={isEditing}
											getData={this.getData}
											validation={this.validate.required}
											index={index}
											onClickDeleteBtn={this.onClickDeleteBtn}
										/>
									</Grid>
							  ))
							: ""}

						{!isError && showAddForm
							? this.state.inputs?.map((input, indexInput) => (
									<Grid item xs={12} sm={9} md={9} lg={9}>
										{" "}
										{/* Other agencies */}
										<SettingAgency
											agencyName={""}
											classes={classes}
											renderChip={this.renderChip}
											renderInput={this.renderInput}
											renderInsurance={this.renderInsurance}
											renderAdornments={this.renderAdornments}
											settingPrice={finalSettingPrice}
											isEditing={isEditing}
											getData={this.getData}
											validation={this.validate.required}
											index={indexInput}
											onClickDeleteBtn={this.onClickDeleteForm}
										/>
									</Grid>
							  ))
							: ""}

						{/* {!showAddForm ? */}
						<Grid item xs={12} sm={9} md={9} lg={9}>
							<Grid container direction="row" justify="flex-end">
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={this.onClickAddBtn}
								>
									{I18n.t("Button.add")}
								</Button>
							</Grid>
						</Grid>
						{/* : ''} */}

						<Grid item xs={12}>
							<EquipmentFormPrice
								classes={classes}
								title={I18n.t("Label.order.equipmentProducts")}
								productsByCategory={productPricesByCategory}
								readOnly={!isEditing}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} direction="row" justify="flex-end">
						<Grid item>
							{!isEditing ? (
								<Button
									size="small"
									variant="contained"
									color="primary"
									onClick={this.onClickEditBtn}
								>
									{I18n.t("Button.editSetting")}
								</Button>
							) : (
								[
									<Button
										size="small"
										key="confirm"
										variant="contained"
										color="primary"
										type="submit"
									>
										{I18n.t("Button.confirm")}
									</Button>,
									<Button
										size="small"
										key="cancel"
										variant="outlined"
										color="primary"
										onClick={this.cancel}
										className={classes.button}
									>
										{I18n.t("Button.cancel")}
									</Button>,
								]
							)}
						</Grid>
					</Grid>
				</Form>
			</PaperFade>
		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Index));
