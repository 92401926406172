import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Form, Validation, DateTimeField, MultipleSelectField } from "src/components/Forms";
import AutoCompleteField, {
  Option as OptionAuto
} from "src/components/Forms/AutoCompleteField";
import { BaseView } from "src/views/BaseView";
import { I18n } from "src/helpers/I18n";
import {
  Grid,
  Button
} from "@material-ui/core";
import { customerTypes as object} from "src/config/constant";
import _ from "lodash";
import moment from "moment";
import {formatYearField} from "src/config/constant";

const styles = theme => ({
  buttonConfirm: {
    marginTop: "15px",
    textAlign: "right"
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      paddingBottom: '0px'
    },
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      paddingBottom: '0px'
    }
  },
  nonePadding: {
    padding: '0 !important'
  }
});

class Create extends BaseView {
  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial"
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      checkEndTime: false,
      reload: false,
      data: {}
    };
    this.validate = {
      code: [Validation.required(I18n.t("Validate.required.base"))],
      name: [Validation.required(I18n.t("Validate.required.base"))]
    };
  }

  onHandleChange = (name, { value }) => {
    this.setState({ data: { ...this.state.data, [name]: value } });
  };

  onHandleDate = (name, value) => {
    let  _data = {...this.state.data}
    let _date = value.clone();
    _data[name] = value;
    if(name=="startDate") {
      _data["endDate"] = _date.add(2, 'years')
    }
        this.setState({ data: _data }, () => {
          this.endDateField.onValidate();
        });
  };
  viewReportPDF = () => {
    let { endDate, startDate, type } = this.state.data || {};

    if (!startDate || !endDate) return;

    startDate = moment(startDate).format("YYYY");
    endDate = moment(endDate).format("YYYY");

    let url = window.config.API_HOST +
      `/api/authWithSession?token=${localStorage.getItem("token")}&redirect=/api/reports/exportDelivery-${type}-${startDate}-${endDate}.pdf`;
    window.open(url);
  }
  validateEndDate = value => {
    let startDate = _.get(this.state.data, "startDate", new Date());
    if (moment(value).isBefore(startDate)) {
      return I18n.t("Validate.required.endTime");
    }
  };

  renderOptionCustomer ({customers=[], type}) {
    return (
      customers
        .reduce((res, item) => (
          item.type !== parseInt(type)
            ? res
            : [
              ...res, 
              <OptionAuto
                key={item._id}
                value={item._id}
                showCheckbox={false}
              >
                {item.name} - {item.code}
              </OptionAuto>
            ]
        ), [
          <OptionAuto key="0" value='' showCheckbox={false}>
            {I18n.t("Label.all")}
          </OptionAuto>
        ])
    )
  }
  
  render() {
    let { classes, onExportFile, customers = [],agencies } = this.props;
    let { data } = this.state;
    let startDate = _.get(data, "startDate", "");
    let endDate = _.get(data, "endDate", "");
    let type = _.get(data, "type", "1");
    let MaxOfEndDate = startDate?startDate.clone().add(2,"years"):"";
    const {
      customStyles,
    } = this;
    return (
      <Form className={classes.form} onSubmit={this.viewReportPDF}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <MultipleSelectField
              key="0"
              fullWidth
              margin="dense"
              select
              label={I18n.t("Input.customer.type")}
              name="type"
              onChange={value => {
                this.setState({ data: { ...this.state.data, ["type"]: value } });
              }}
            >
              { Object.keys(agencies).map(item =>{
                  let label = item
                  let value = agencies[item]
                  if(item === 'ske') {
                    label = 'SKE'
                  } else if(item === 'mc_center') {
                    label = 'エムシーセンター'
                  }

                  return (
                      <OptionAuto
                        key={value}
                        value={value}
                      >
                        {label}
                      </OptionAuto>
                  )
              })}
            </MultipleSelectField>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={9} lg={9} className={classes.nonePadding}></Grid> */}
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              views={['year']}
              fullWidth
              label={I18n.t("Label.dateRange.fromDate")}
              name="startDate"
              format={formatYearField}
              margin="dense"
              showTime={false}
              autoOk={true}
              className={classes.DateTime}
              onChange={value => this.onHandleDate("startDate", value)}
              InputProps={{
                readOnly: true
              }}
              maxDate={Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              fullWidth
              views={['year']}
              label={I18n.t("Label.dateRange.toDate")}
              name="endDate"
              format={formatYearField}
              margin="dense"
              showTime={false}
              autoOk={true}
              value={endDate}
              className={classes.DateTime}
              onChange={value => this.onHandleDate("endDate", value)}
              validate={[this.validateEndDate]}
              forwardRef={ref => (this.endDateField = ref)}
              InputProps={{
                readOnly: true
              }}
              maxDate={MaxOfEndDate}
              minDate={startDate}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} className={classes.buttonConfirm}>
            <Button
              disabled={startDate && endDate && type.length>1 ? false : true}
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => this.props.setTypeViews(type)}
            >
              {I18n.t("Button.confirm")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

Create.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Create);
