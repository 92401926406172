import React from 'react'
import { I18n } from 'src/helpers/I18n'
import { CardContent, Typography } from '@material-ui/core'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'

const SettingAdBlue = ({ classes, renderChip, renderInput, renderAdornments, settingPrice, isEditing, getData }) => (

    <CardContent className={classes.heightSKEAdblue}>
        <Typography variant="h6" className={classes.cardTitle}>
            {I18n.t("Label.products.adBlue")}
        </Typography>
        {renderChip(classes, I18n.t(`Label.products.adBlue`), <LocalGasStationIcon fontSize="small" />)}
        {renderInput(classes, settingPrice, isEditing, I18n.t(`Label.products.adBlue`), "prices.adBlue", 'start', getData, renderAdornments)}
    </CardContent>
)

export default SettingAdBlue;