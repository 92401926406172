import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { TextField, Form, DateTimeField } from "src/components/Forms";
import AutoCompleteField, { Option as OptionAuto } from "src/components/Forms/AutoCompleteField";
import { BaseView } from "src/views/BaseView";
import { I18n } from "src/helpers/I18n";
import DateUtil from 'src/helpers/date';
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import { customerPaymentTerms, months, constructionOptions } from "src/config/constant";
import moment from 'moment';
import { formatDateField } from "src/config/constant";

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  buttonConfirm: {
    marginTop: "15px",
    textAlign: 'right'
  },
  form: {
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
    },
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
    }
  }
});

class Create extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      constructionArr: []
    };
    this.filter = {
      type: 1,
      year: moment().year()
    };
  }

  validateEndDate = value => {
    let startDate = _.get(this.filter, "startDate", new Date());
    if (moment(value).isBefore(startDate)) {
      return I18n.t("Validate.required.endTime");
    }
  };

  onHandleChange = (data = {}) => {
    Object.entries(data).forEach(([name, value]) => {
      this.filter[name] = value
    });

    if (this.filter["type"] && this.filter["paymentTerm"]) {
      this.filter["type"] = Number(this.filter["type"]); //fix bugs
      this.props.loadUser(this.filter);
    }

    this.setState({ data: { ...this.state.data, ...data } }, () => {
      this.endDateField.onValidate();
    });
  };

  handleChange = (options) => {
    this.setState({ constructionArr: options });
  };

  render() {
    const { classes, onSubmit, customers = [], agencies = [] } = this.props;
    let { data, constructionArr } = this.state;
    let startDate = _.get(data, "startDate", "");
    let endDate = _.get(data, "endDate", "");
    let paymentTerm = _.get(data, "paymentTerm", "");
    let month = _.get(data, "month", "");
    let type = _.get(data, "type", "1");
    let year = _.get(data, "year", String(moment().year()));
    let selectPayment

    if (typeof year != 'string') year = moment(year).format("YYYY")

    if (month && paymentTerm) {
      if (paymentTerm == "end") {
        startDate = DateUtil.getDateByMonth(month, null, year);
        endDate = moment(startDate).endOf("month").format()
      } else {
        endDate = DateUtil.getDateByMonth(month, paymentTerm, year);
        startDate = moment(endDate).subtract(1, 'months').add(1, 'days').format()
      }
    }

    if (month == 2) {
      selectPayment = customerPaymentTerms.slice(0, 29)
    } else {
      selectPayment = customerPaymentTerms.slice(0, 31)
    }

    return (
      <Form className={classes.form} onSubmit={onSubmit}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <AutoCompleteField
              key="0"
              margin="dense"
              select
              label={I18n.t("Input.customer.type")}
              name="type"
              isMulti={false}
              defaultValue="1"
              isClearable={false}
              onChange={({ value }) => {
                this.onHandleChange({ "type": value, paymentTerm: value === '2' && 'end' })
              }}
            >
              {agencies.map(item => (
                <OptionAuto
                  key={item.value}
                  value={item.value}
                  showCheckbox={false}
                >
                  {item.label}
                </OptionAuto>
              ))}
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              key="1"
              fullWidth
              margin="dense"
              label={I18n.t("Label.year")}
              name="year"
              format={"YYYY"}
              showTime={false}
              autoOk={true}
              views={["year"]}
              value={year}
              onChange={value => this.onHandleChange({ 'year': value })}
              InputProps={{ readOnly: true }}
              maxDate={Date()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1} lg={1}>
            <AutoCompleteField
              key="1"
              fullWidth
              margin="dense"
              label={I18n.t("Label.month")}
              name="month"
              onChange={({ value }) => this.onHandleChange({ "month": value })}
              isMulti={false}
              isClearable={false}
              select
            >
              {months.map(item => (
                <OptionAuto
                  key={item.value}
                  value={item.value}
                  showCheckbox={false}
                >
                  {item.text}
                </OptionAuto>
              ))}
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <AutoCompleteField
              key="2"
              fullWidth
              margin="dense"
              select
              label={I18n.t("Input.customer.paymentTerm")}
              name="paymentTerm"
              isMulti={false}
              isDisabled={!month || type === '2'}
              isClearable={false}
              onChange={({ value }) => this.onHandleChange({ "paymentTerm": value })}
              value={paymentTerm}
            >
              {
                selectPayment.map(item => (
                  <OptionAuto
                    key={item.value}
                    value={item.value}
                    showCheckbox={false}
                  >
                    {item.text}
                  </OptionAuto>
                ))
              }

            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <AutoCompleteField
              key="2"
              fullWidth
              select
              margin="dense"
              label={I18n.t("Input.customer.code")}
              name="customer"
              isMulti={false}
              isClearable={false}
              defaultValue=''
              isDisabled={paymentTerm && month ? false : true}
            >
              {customers
                .reduce((newObj, item) => ([
                  ...newObj,
                  <OptionAuto
                    key={item._id}
                    value={item._id}
                    showCheckbox={false}
                  >
                    {item.name} - {item.code}
                  </OptionAuto>
                ]),
                  [
                    <OptionAuto key="-1" value='' showCheckbox={false}>
                      {I18n.t("Label.all")}
                    </OptionAuto>
                  ]
                )
              }
            </AutoCompleteField>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} className={classes.buttonConfirm}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={(paymentTerm && month) ? false : true}
              onClick={() => this.props.getTypeViews(type)}
            >
                {I18n.t("Button.confirm")}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              fullWidth
              label={I18n.t("Label.dateRange.fromDate")}
              name="startDate"
              format={formatDateField}
              margin="dense"
              showTime={false}
              autoOk={true}
              disabled={(paymentTerm ? false : true)}
              maxDate={endDate}
              value={paymentTerm != '' ? startDate : ''}
              className={classes.DateTime}
              onChange={value => this.onHandleChange({ "startDate": value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            <DateTimeField
              fullWidth
              label={I18n.t("Label.dateRange.toDate")}
              name="endDate"
              format={formatDateField}
              margin="dense"
              showTime={false}
              autoOk={true}
              disabled={(paymentTerm ? false : true)}
              value={paymentTerm != '' ? endDate : ''}
              maxDate={endDate}
              className={classes.DateTime}
              onChange={value => this.onHandleChange({ "endDate": value })}
              validate={[this.validateEndDate]}
              forwardRef={ref => (this.endDateField = ref)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="constructionOpts-label">{I18n.t("Label.construction")}</InputLabel>
              <Select
                fullWidth
                margin="dense"
                labelId="constructionOpts-label"
                id="constructionOpts-checkbox"
                multiple
                value={constructionArr}
                onChange={(e) => this.handleChange(e.target.value)}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
              >
                {constructionOptions.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    <Checkbox checked={constructionArr.indexOf(option.value) > -1} />
                    <ListItemText primary={option.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
                type="hidden"
                name="constructionOpts"
                value={constructionArr.join('_')}
              />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

Create.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Create);