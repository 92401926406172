import React from 'react';
import View from 'src/views/Estimation/Index';
import CustomerAction from '../../actions/CustomerAction';
import BaseContainer, { selector } from 'src/containers/BaseContainer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { dateFormatBackend } from 'src/config/constant';
import ReportAction from '../../actions/ReportAction';
import {saveFile} from 'src/helpers/File';
moment.defaultFormat = dateFormatBackend;

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.onRefTable = this.onRefTable.bind(this)
    this.filters = {}
  }

  componentDidMount() {
    let conditions = [
      {columnName: "type", value: 1, operation: 'equal', "dataType": "number"},
    ]
    this.props.dispatch(CustomerAction.SelectAll({pageSize: -1,filters:conditions}))
    this.props.dispatch(CustomerAction.clearData({list:[]}))
  }

  loadCustomer = (filters) => {
    this.props.dispatch(CustomerAction.SelectAll({
      filters: filters
    }))
  }

  loadUser = (filters) => {
    let conditions = [
      {columnName: "type", value: filters['type'], operation: 'equal', "dataType": "number"},
    ]
    if(filters.code) {
      conditions = [
        ...conditions,
        {columnName: "code", value: filters['code'], operation: 'equal', "dataType": "string"},
      ]
    }
    this.props.dispatch(CustomerAction.fetchAll({
      filters: conditions
    }))
  }
  onFetchData = (state) => {
    this.filters = state;
    this.props.dispatch(CustomerAction.fetchAll(state))
    
  }
  onExportFile = (state) => {
    this.props
      .dispatch(ReportAction.exportEstimations({...state, exportExcel: true}))
      .then(response => {
        let {path, fileName} = this.getData(response, 'data', {});
        
         if(path) {
          setTimeout(() => {
            const res = {
              file: path,
            };
            window.open(res.file);
          }, 2000); 
        } else {
          console.warn('Not found path!!!')
        }

      })
      .catch(error => console.warn('cant export file!!!'))
  }

  onSubmit = (state) => {

    this.filters = state;
    this.loadUser(state) // clear data table
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  render() {
    return (
      <View
      loadUser={this.loadUser}
      loadCustomer = {this.loadCustomer}
        onFetchData={this.onFetchData}
        onSubmit={this.onSubmit}
        onExportFile={this.onExportFile}
        onRefTable={this.onRefTable}
        customers={this.props.customers}
        customeropt={this.props.customeropt}
        agencies={this.props.agencies}
        dateRange={this.dateRange}
        filters={this.filters}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    customeropt: selector(state, "customer.opt.list_data", []),
    customers: selector(state, "customer.list.list_data", []),
    agencies: selector(state, "settingPrice.data.agencies", [])
  }
}

export default withRouter(connect(mapStateToProps)(Index))
