import React from 'react';
import View from 'src/views/Order/Index'
import OrderAction from '../../actions/OrderAction';
import BaseContainer, { selector } from 'src/containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'src/helpers/I18n';

class Index extends BaseContainer {
  constructor(props) {
    super(props);
    this.refTable = null;
    this.onFetchData = this.onFetchData.bind(this);
    this.onRefTable = this.onRefTable.bind(this);
    this.onDeleteData = this.onDeleteData.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('divideOrderLocal');
  }

  onFetchData(state) {
    Promise.all([
      this.props.dispatch(OrderAction.getTotalOrderDetails()),
      this.props.dispatch(OrderAction.fetchAll(state))
    ]);
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  onDeleteData(selectedIds) {
    this.props.dispatch(OrderAction.delete({
      ids: selectedIds
    }))
      .then(result => {
        if (!result.error) {
          //success
          this.notify(I18n.t('Message.success.delete'));
          if (this.refTable) {
            this.refTable.onSelectionChange([]);
            this.refTable.onFetchData();
          }
        } else {
          //error
          this.notify(`[${result.error.status}] ${result.error.message}`, 'error');
        }
      })
  }

  render() {
    return (<View
      onFetchData={this.onFetchData}
      onRefTable={this.onRefTable}
      onDeleteData={this.onDeleteData}
      orders={this.props.orders}
      totalOrderDetails={this.props.totalOrderDetails}
    />);
  }
}

const mapStateToProps = state => {
  return {
    orders: selector(state, "order.list", {}),
    totalOrderDetails: selector(state, 'order.total.totalOrderDetails', []),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
