export default {
  translation: {
    app_name: "Gasonline",
    company_name: "SKE",

    Form: {
      noOption: "Không tìm thấy",
    },

    NotFound: {
      title: "Nội dung không tìm thấy hoặc không tồn tại!"
    },

    Button: {
      login: "Sign in",
      logout: "Sign out",
      remember: "Ghi nhớ tài khoản",
      create: "Thêm",
      edit: "Sửa",
      delete: "Xóa",
      detail: 'Chi tiết',
      view: "Xem",
      back: "Quay lại",
      close: 'Đóng',
      ok: "OK",
      onFocus: "Cập nhập vị trí: Bật",
      offFocus: "Cập nhập vị trí: Tắt",
      clear: "Clear",
      cancel: "Hủy",
      save: "Lưu",
      update: "Cập nhật",
      print: "In ấn",
      printItem: "In ấn",
      exportPDF: 'Xuất PDF',
      exportExcel: 'Xuất Excel',
      divide: "Phân chia",
      confirm: "Xác nhận",
      remaining: "Số lượng còn lại",
      refueling: "Tiếp nhiên liệu",
      generalDelivery: "Tổng hợp",
      separateDelivery: "Riêng",
      changePassword: 'Đổi MK', //
      googleMap: "Google Map",
      delivered: "Đã giao",
      delivery: "Giaohàng",
      listDelivery: "Danh sách giao hàng",
      deliveryCompleted: 'Giao hàng xong',
      finish: "Xong",
      add: 'Thêm',
      beforeDelivery: 'Trước khi giao hàng',
      agree: 'Đồng ý',
    },

    Sidebar: {
      area: 'Khu vực',
      customer: "Khách hàng",
      delivery: "Danh sách giao hàng",
      deliveryOthers: "Danh sách giao hàng - Tài xế khác",
      divideOrder: "Phân chia Order",
      driver: "Lái xe",
      order: "Đơn hàng",
      settingPrice: "Thiết lập đơn giá",
      remainingFuels: "Lượng dầu còn lại",
      report: "Báo cáo hàng ngày",
      pouringFuels: "Thống kê lượng dầu đổ vào xe bồn",
      user: "Người dùng",
      vehicle: "Xe bồn",
      invoice: 'Hóa đơn',
      customerOrders: 'Thống kê đơn hàng theo khách hàng',
      product: 'Sản phẩm đăng ký',
      estimation: "Dự toán",
      reportDelivery:"Báo cáo giao hàng theo các đại lý"
    },

    Breadcrumb: {
      area: {
        index: "Danh sách khu vực giao hàng",
        create: "Thêm mới khu vực giao hàng",
        edit: "Sửa thông tin khu vực giao hàng"
      },

      customer: {
        index: "Danh sách khách hàng",
        create: "Thêm mới khách hàng",
        edit: "Sửa thông tin khách hàng",
        detail: "Thông tin khách hàng",
        history: "Lịch sử khách hàng",
      },

      driver: {
        index: "Danh sách lái xe",
        create: "Thêm mới lái xe",
        edit: "Sửa thông tin lái xe",
        detail: "Thông tin lái xe",
        gps: 'GPS',
      },

      order: {
        index: "Danh sách đơn hàng",
        create: "Thêm đơn hàng",
        edit: "Sửa đơn hàng",
        detail: "Chi tiết đơn hàng",
        divide: "Phân chia đơn hàng"
      },

      user: {
        index: "Danh sách người dùng",
        create: "Thêm mới người dùng",
        edit: "Sửa thông tin người dùng"
      },

      vehicle: {
        index: "Danh sách xe bồn",
        create: "Thêm mới xe bồn",
        edit: "Sửa thông tin xe bồn",
        detail: "Thông tin chi tiết xe bồn"
      },

      settingPriceIndex: "Thiết lập đơn giá",
      invoice: 'Hóa đơn',
      estimation: "Dự toán",
      customerOrders: 'Thống kê đơn hàng theo khách hàng',
      reportIndex: "Báo cáo hàng ngày",
      delivery: "Danh sách giao hàng",
      indexDelivery: "Thêm số lượng giao hàng",
      deliveryType: 'Theo loại giao hàng',
      generalDelivery: "Giao hàng tổng hợp",
      separateDelivery: "Giao hàng riêng",
      deliveryOthers: "Danh sách giao hàng - Tài xế khác",
      pouredAmount: "Lượng đã đổ vào xe bồn",
      remainingAmount: "Lượng dầu còn lại",
      reportDelivery:"Báo cáo giao hàng theo các đại lý"
    },

    Label: {
      all: "Tất cả",
      amount: "Thành Tiền",
      chooseDate: "Chọn ngày báo cáo",
      chooseSizePaper: "Chọn khổ giấy",
      chooseProduct: "Chọn sản phẩm",
      createCustomerByPhone: "Khách Hàng mới: {{phone}}",
      date: "Ngày Tháng",
      discount: "Giảm giá",
      actualRemainingFuel: "Nhiên liệu còn lại thực tế",
      ordersNotDivided: "Danh sách đơn hàng chưa phân xe",
      report: "Danh Sách Báo Cáo Giao Hàng",
      receivedVehicle: "Xe tiếp nhiên liệu",
      status: "Trạng Thái",
      month: "Tháng",
      year: 'Năm',
      tax: "Thuế",
      totalAmount: "Tổng Tiền",
      orderSelected: "Đơn hàng được chọn",
      noAddress: 'Không tìm thấy địa chỉ',
      coordinates: "Tọa độ", 
      additionalDelivery: "Giao hàng bổ sung",
      construction: "Công trường",
      endMonth: "Cuối tháng",
      customer: {
        mapTypeValue: {
          "1": "SKE",
          "2": "MC Center"
        },
        types: {
          direct: "SKE",
          mediate: "MC Center"
        },
        MCFlags: {
          A: "MC Center A",
          B: "MC Center B",
          C: "MC Center C",
          diesel: 'Dầu diesel',
          gasoline: 'Xăng',
          kerosene: "Dầu hỏa",
          insurance: 'Bảo hiểm',
        },
      },

      products: {
        adBlue: "Ad Blue",
        diesel: "Dầu Diesel",
        kerosene: "Dầu hỏa",
        gasoline: "Xăng",
        dieselFreeTax: "Dầu diesel miễn thuế",
        crudeOil: "Dầu thô"
      },
      toll:{
        tolltravel:'Phí đi lại',
        tollhighspeed:'Phí cao tốc'
      },
      taxes: {
        consumptionTax: "Thuế tiêu dùng",
        dieselTax: "Thuế dầu diesel"
      },

      insurance: "Bảo hiểm",
      insuranceFee: "Phí bảo hiểm",

      dateRange: {
        startMonth: "Ngày 1 - Ngày 15",
        endMonth: "Ngày 16 - Ngày 31",
        fromDate: "Từ ngày",
        toDate: "Đến ngày"
      },

      driver: {
        deliverNumber: "Thẻ giao xăng",
        fuelNumber: "Thẻ đổ xăng"
      },

      order: {
        title: "Đơn Hàng",
        info: "Thông Tin Đặt Hàng",
        detail: "Chi Tiết Đơn Hàng",
        quantity: "Số Lượng Đặt Hàng",
        expectNum: "Số Lượng Dự Kiến",
        deliveryInfo: "Thông Tin Giao Hàng",
        total: "Tổng Số Đơn Hàng",
        equipmentProducts: "Sản Phẩm Trang Bị",
        toll:"Thu phí"
      },

      statusOrder: {
        waiting: "Chờ phân xe",
        divided: "Đã phân xe",
        delivered: "Đã giao hàng",
        done: "Xong"
      }
    },

    Input: {
      address: "Địa chỉ",
      amount: "Thành tiền",
      birthday: "Ngày sinh",
      code: "Mã",
      cost: "Giá nhập",
      email: "Email",
      name: "Tên",
      note: "Ghi chú",
      phone: "Điện thoại",
      price: "Giá bán",
      quantity: "Số lượng",
      sex: "Giới tính",
      status: "Trạng thái",
      date: 'Ngày',
      construction: 'Công trường',
      curator: "Người phụ trách",
      auth: {
        username: "Tài khoản",
        password: "Mật khẩu",
        rePassword: "Nhập Lại Mật Khẩu"
      },

      area: {
        code: "Mã khu vực",
        name: "Tên khu vực"
      },

      customer: {
        code: "Mã khách hàng",
        name: "Tên khách hàng",
        type: "Phân loại",
        extendPrice: {
          diesel: "Giá diesel tăng thêm",
          kerosene: "Giá dầu hỏa tăng thêm"
        },
        paymentTerm: "Hạn thanh toán",
        flag: "Cờ"
      },

      order: {
        deliveryAddress: "Địa chỉ giao hàng",
        orderDate: "Ngày đặt hàng",
        deliveryDate: "Ngày giao hàng",
        deliveryTime: "Giờ giao hàng",
        memo: "Ghi chú",
        constructionNumber: "Số hiệu công trường",
        constructionName: "Tên công trường",
        note:"Ghi chú"
      },

      user: {
        username: "Tên Đăng Nhập",
        code: "Mã Nhân Viên",
        name: "Tên Nhân Viên",
        position: "Chức Vụ",
        firstName: "Tên",
        lastName: "Họ"
      },

      vehicle: {
        name: "Tên Xe Bồn",
        licensePlate: "Biển Số Xe",
        driver: "Tên Lái Xe",
        noDriverAvailable: "Tất cả tài xế đã được phân xe",
        capacity: "Dung Tích",
        remaining: "Nhiên Liệu Còn Lại",
        haveVehicle: "Đã có xe"

      },

      pay: {
        user: 'Đối tượng',
        month: 'Tháng',
        fromToTime: 'Thời gian',
        end: 'Hạn thanh toán',
        code: 'Mã khách hàng'

      },

      customersOrder: {
        errEndTime: 'Thời gian không nhỏ hơn bắt đầu'
      },
      changePassword: { //
        title: "Thay đổi mật khẩu người dùng",
      },
    },

    Placeholder: {
      quantity: "Số lượng ...",
      filter: {
        general: "Tìm kiếm",
        date: "Chọn ngày",
        dateRange: "Chọn thời gian"
      }
    },

    Table: {
      action: "Hành Động",
      sorting: "Sắp xếp",
      address: "Địa chỉ",
      amount: "Thành Tiền",
      code: "Mã",
      createdAt: "Ngày Tạo",
      email: "Email",
      name: "Tên",
      no: "STT",
      phone: "Điện Thoại",
      price: "Giá Bán",
      quantity: "Số Lượng",
      status: "Trạng Thái",
      total: "Tổng",
      totalAmount: "Tổng Tiền",
      preview: 'Xem trước',

      area: {
        label: "Khu Vực",
        code: "Mã Khu Vực",
        name: "Tên Khu Vực"
      },

      customer: {
        label: "Khách Hàng",
        code: "Mã Khách Hàng",
        name: "Tên Khách Hàng",
        phone: "Số Điện Thoại",
        type: "Phân Loại",
        extendPrice: {
          diesel: "Giá diesel tăng thêm",
          kerosene: "Giá dầu hỏa tăng thêm"
        },
        paymentTerm: "Hạn Thanh Toán"
      },

      driver: {
        code: "Mã Lái Xe",
        name: "Tên Lái Xe",
        cardNumber: "Số Thẻ"
      },

      order: {
        orderDate: "Ngày Đặt Hàng",
        deliveryDate: "Ngày Giao Hàng",
        deliveryTime: "Thời Gian Giao Hàng",
        address: "Địa Chỉ Giao Hàng",
        expectNum: "Số Lượng Dự Kiến",
        deliveryNum: "Số lượng đã giao",
        fuel: "Tên nhiên liệu",
        memo: "Bản ghi nhớ",
        constructionName: "Địa điểm xây dựng",
        constructionNumber: "Số dây dựng",
      },

      user: {
        username: "Tên Đăng Nhập",
        code: "Mã Nhân Viên",
        name: "Tên Nhân Viên",
        position: "Chức Vụ",
        firstName: "Tên",
        lastName: "Họ"
      },

      vehicle: {
        name: "Tên Xe",
        licensePlate: "Biển Số Xe",
        driver: "Tên Lái Xe",
        capacity: "Dung Tích",
        remaining: "Nhiên Liệu Còn Lại"
      },

      product: {
        categoryName: "Loại Sản Phẩm",
        name: "Tên Sản Phẩm",
        unit: "Đơn Vị"
      },

      pays: {
        diesel: 'Dầu diesel',
        dieselExempt: 'Dầu diesel miễn thuế',
        fuel: 'Dầu hỏa',
        gasoline: 'Xăng',
        adBlue: 'AD blue',
        money: 'Thành tiền',
        insurrance: 'Bảo hiểm',
      }
    },

    Validate: {
      required: {
        base: 'Vui lòng nhập đầy đủ thông tin',
        endTime: 'Chọn thời gian Lớn hơn bằng thời gian bắt đầu',
        auth: {
          username: "Vui lòng nhập tài khoản",
          password: "Vui lòng nhập mật khẩu",
        },
      },
      noJapanese: "Vui lòng chỉ nhập số và chữ cái alphabet haflwidth cho trường này.",
      passwordNotMatch: "Mật Khẩu Không Khớp. Hãy Nhập Lại!",
      editFuelsTruck: "Dung tích phải lớn hơn hoặc bằng nhiên liệu còn lại!",
      invalidFuelsOrder: "Số lượng dự kiến không được lớn hơn số lượng order",
      emptyFuelsOrder: "Chưa thêm số lượng nhiên liệu đặt hàng",
      emptyFuelsImport: "Chưa thêm số lượng nhiên liệu đổ vào xe bồn",
      emptyFuelsExport: "Chưa thêm số lượng nhiên liệu lấy ra từ xe bồn",
      emptyReceivedVehicle: "Chưa nhập xe tiếp nhiên liệu",
      min: "Vui lòng nhập giá trị nhỏ nhất là ${arguments[0]}",
      max: "Vui lòng nhập giá trị lớn nhất là ${arguments[0]}",
      minLength: "Vui lòng nhập tối thiểu ${arguments[0]} ký tự",
      maxLength: "Vui lòng nhập tối đa ${arguments[0]} ký tự",
      maxLengthMoney: "Vui lòng nhập tối đa ${_maxLength} số",
      lessThan: "Vui lòng nhập giá trị nhỏ hơn ${arguments[0]}",
      greatThan: "Vui lòng nhập giá trị lớn hơn ${arguments[0]}",
      minDate: "Vui lòng nhập ngày lớn hơn ngày tối thiểu ${arguments[0]}",
      dateInvalid: "Vui lòng nhập ngày hợp lệ",
      soonerDeliveryDay:"Không cho phép driver nhập SL nhiên liệu trước ngày delivery ghi trên order",
      phone: {
        minLength: "Vui lòng nhập số điện thoại lớn hơn ${_minLength} chữ số",
        maxLength: "Vui lòng nhập số điện thoại nhỏ hơn ${arguments[0]} chữ số"
      },
      email: {
        format : "Email nhập không đúng định dạng"
      },
      number: {
        onlyNumber: "Vui lòng chỉ nhập số"
      },
      truck: {
        capacity: "Vui lòng nhập dung tích lớn hơn nhiên liệu còn lại",
        remaining: "Vui lòng nhập nhiên liệu còn lại nhỏ hơn dung tích"
      }
    },

    Common: {
      deliveryTime: {
        morning: "SA",
        afternoon: "CH",
        anytime: "Mọi lúc",
        unknown: "Unknown"
      },
      from: 'Từ',
      to: 'Đến',
    },

    Confirm: {
      delete: "Bạn có muốn xóa dữ liệu đã chọn không ?",
      undivide: "Bạn có muốn hủy đơn hàng này không ?",
      update: "Bạn có muốn cập nhật không ?",
      updatePrice: "Bạn có muốn cập nhật đơn giá không ?",
      completeOrder: "Bạn có muốn hoàn thành đơn hàng ?",
      finishInputDelivered: "Hãy nhập vào mục Lượng hàng giao",
      logout: "Bạn có chắc chắn muốn đăng xuất ?",
      reset: "Đặt lại dữ liệu này. Cái này tốt không?",
    },

    Notification: {
      delete: "Dữ liệu sẽ không thể khôi phục sau khi xóa, vì vậy hãy cẩn trọng trong thao tác này.",
      update: "Dữ liệu sẽ không thể khôi phục sau khi thay đổi, vì vậy hãy cẩn trọng trong thao tác này.",
    },

    Message: {
      
      success: {
        create: "Thêm mới dữ liệu thành công",
        update: "Cập nhật dữ liệu thành công",
        delete: "Đã xóa dữ liệu thành công",
        noOrdersSelected: 'Không có đơn hàng nào được chọn',
        // custom for another subject
        
        customer: {
          create: "Thêm mới khách hàng thành công",
          update: "Cập nhật khách hàng thành công"
        },
        order: {
          create: "Thêm mới đơn hàng thành công",
          update: "Cập nhật đơn hàng thành công",
        },
        group: {
          create: "Thêm mới nhóm thành công",
        }
      },

      error: {
        base: "Có lỗi xảy ra, vui lòng thử lại",
      },

      divideOrder: {
        haveParams: "Đơn hàng ${arguments[0]} đã được lưu. Click XÁC NHẬN để phân chia ngay lúc này!",
        invalidFuelsDialogTitle: "Không đủ nhiên liệu",
        invalidFuelsDialogContent: "Xe bồn không đủ: ${name} để giao đơn hàng này. Bạn có muốn tiếp tục không?",
      },
      noAddress: "Không có dữ liệu người dùng"
    },

    Tooltip: {
      create: "Thêm mới",
      edit: "Chỉnh sửa thông tin",
      editPrice: "Chỉnh sửa đơn giá",
      updatePrice: "Cập nhật đơn giá",
      detail: "Xem chi tiết",
      delete: "Xóa các hàng đã chọn",
      exportCSV: "Xuất file CSV",
      exportExcel: "Xuất file excel",
      print: "In danh sách",
      shareOrder: "Phân chia đơn đặt hàng",
      deliver: "Giao hàng",
      completeOrder: "Hoàn thành đơn hàng",
      gps: "GPS"
    },

    Pagination: {
      backIconButtonText: 'Trang trước',
      labelRowsPerPage: 'Hiển thị:',
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to === -1 ? count : to} / ${count} bản ghi`,
      nextIconButtonText: 'Trang sau',
      noData: "Không có dữ liệu",
    }
  }
}
