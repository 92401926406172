import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import { I18n } from 'src/helpers/I18n'
import {dateFormatBackend} from 'src/config/constant';
import moment from 'moment'
moment.defaultFormat = dateFormatBackend;
const GridTable = React.lazy(() => import('src/components/Table/GridTable'))

const styles = theme => ({
  alignRight: {
    textAlign: 'right',
    marginRight: '50px'
  },
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  gridTable: {
    '& .MuiToolbar-root': {
      display: 'none !important'
    },

    '& .Grid-Root-Table': {
      minHeight: '0 !important',
      maxHeight: 'calc(100vh - 335px) !important',
      
      [theme.breakpoints.up('sm')] : {
        minHeight: 'calc(100vh - 510px) !important'
      },
      [theme.breakpoints.up('md')] : {
        minHeight: 'calc(100vh - 500px) !important'
      },
      ['@media (min-width:960px)'] : {
        minHeight: 'calc(100vh - 500px) !important'
      },
      ['@media (min-width:1150px)'] : {
        minHeight: 'calc(100vh - 474px) !important'
      },
    },

    '& table.MuiTable-root': {
      // xs
      ['@media (max-width: 767px)'] : {
        width: '600px'
      },
      // md 
      ['@media (min-width: 768px)']: {
        width: '900px'
      },
      // lg
      ['@media (min-width: 992px)']: {
        width: '100%'
      },
    }
  },

  customerTable: {
    ['@media (max-width: 767px)'] : {
      width: '600px'
    }, 
    ['@media (min-width: 768px)']: {
      width: '900px'
    },
    ['@media (min-width: 992px)']: {
      width: '100%'
    },
  },
  scrollTable: {
    width: '100%',
    overflow: 'auto',
    marginBottom: '1.5rem',

    '& table.MuiTable-root': {
      ['@media (max-width: 767px)'] : {
        width: '600px'
      }, 
      ['@media (min-width: 768px)']: {
        width: '900px'
      },
      ['@media (min-width: 992px)']: {
        width: '100%'
      },
    }
  },
  gridTableFooter: {
    display: 'flex',
    flex: 'none',
    position: 'relative',
    alignItems: 'center',
    minHeight: '55px',
  },
  gridTableFooterButtonRight: {
    right: '0px',
    position: 'absolute'
  },
  paperFade: {
    marginTop: '0.35rem !important'
  }
});

const Unit = 'L';

class IndexMC extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'customer.name',
          title: I18n.t('Table.customer.name'),
          formatterComponent: (data) => this.renderDataField(data, 'customer.name'),
          sortable: false,
          width: "25%"
        },
        {
          name: 'diesel',
          title: I18n.t('Label.products.diesel'),
          // formatterComponent: (data) => this.renderDataField(data, 'diesel') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.diesel', "0")
            return this.getFluesString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'kerosene',
          title: I18n.t('Label.products.kerosene'),
          // formatterComponent: (data) => this.renderDataField(data, 'kerosene') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.kerosene', "0")
            return this.getFluesString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'gasoline',
          title: I18n.t('Label.products.gasoline'),
          // formatterComponent: (data) => this.renderDataField(data, 'gasoline') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.gasoline', "0")
            return this.getFluesString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'adBlue',
          title: I18n.t('Label.products.adBlue'),
          // formatterComponent: (data) => this.renderDataField(data, 'adBlue') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.adBlue', "0")
            return this.getFluesString(amount)
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'amount',
          title: I18n.t('Table.totalAmount'),
          // formatterComponent: (data) => this.renderDataField(data, 'amount') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.amount', "0")
            return String(amount || 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          },
          sortable: false,
          width: "16%"
        },
        {
          name: 'insurance',
          title: I18n.t('Label.insurance'),
          // formatterComponent: (data) => this.renderDataField(data, 'insurance') || 0,
          formatterComponent: (data) => {
            let amount = this.getData(data, 'row.insurance', "0")
            return String(amount || 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          },
          width: "16%"
        }
      ]
    }
  }
  
  getFluesString (value) {
    return <>
      {String(value || 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}&nbsp;{Unit}
    </>
  }

  renderToolbarActions = (data) => {
    let noData = true
    if(data.length) noData = false
    const {startDate, endDate} = this.props.invoiceDate || {},
      {classes} = this.props;

    return (
      <Tooltip title={I18n.t("Tooltip.exportExcel")} key="exportExcel">
        <span className={classes.gridTableFooterButtonRight}>
          <Button 
            disabled={!startDate || !endDate || noData}
            color='primary'
            variant='contained'
            onClick={this.exportExcel}>
            {I18n.t("Button.exportExcel")}
          </Button>
        </span>
      </Tooltip>
    )
  }
  
  exportExcel = (e) => {
    e.preventDefault();
    let {startDate, endDate} = this.props.invoiceDate || {};

    if(!startDate || !endDate) return;

    startDate = moment(startDate).format()
    endDate = moment(endDate).format()

    this.props.onExportFile({
      startDate, endDate, type: '2'
    })
  }

  renderActionsColumn(data){
    return (
      <Button color='primary' variant='outlined' onClick={() => this.viewReport(data)}>
        {I18n.t('Button.confirm')}
      </Button>
    )
  }

  renderMCTotal(){
    const {overAll = {}, classes} = this.props
    let amount = this.getData(overAll, 'amount', 0)
    let insurance = this.getData(overAll, 'insurance', 0)

    return (
      <div className={classes.scrollTable}>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell style={{width: "15%"}}> {I18n.t('Table.customer.name')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Label.products.diesel')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Label.products.kerosene')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Label.products.gasoline')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Label.products.adBlue')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Table.amount')} </TableCell>
              <TableCell style={{width: "10%"}}> {I18n.t('Label.insurance')} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell> エムシーセンター </TableCell>
              <TableCell> {this.getFluesString(overAll.diesel)} </TableCell>
              <TableCell> {this.getFluesString(overAll.kerosene)} </TableCell>
              <TableCell> {this.getFluesString(overAll.gasoline)} </TableCell>
              <TableCell> {this.getFluesString(overAll.adBlue)} </TableCell>
              <TableCell> {String(amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} </TableCell>
              <TableCell> {String(insurance).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }

  addIdToData() {
    const {data=[]} = this.props;
    return data.map((item, index) => ({
      ...item,
      id: this.getData(item, 'customer._id', index)
    }))
  }

  render() {
    const { classes, onRefTable } = this.props,
      data = this.addIdToData();

    return (
      <PaperFade className={classes.paperFade} showLoading={true}>
        {this.renderMCTotal(classes)}
        <br />
        <GridTable
          id="CustomersOrderIndexMC"
          className={classes.gridTable}
          onFetchData={() => {}}
          onRefTable={onRefTable}
          columns={this.table.columns}
          rows={data}
          totalCount={data.length}
          pageSize={data.length}
          showCheckboxColumn={false}
          height="auto"
          filterHiding={true}
          pagingHiding={true}
        />
        <div className={classes.gridTableFooter}>
          {this.renderToolbarActions(data)}
        </div>
      </PaperFade>
    )
  }
}

IndexMC.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(IndexMC));
