import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import LeftSide from '../components/Main/LeftSide'
import { routes, routesDriver } from 'src/config/routes.jsx'
import { Route, Switch, withRouter } from 'react-router-dom'
import Loading from 'src/containers/Loading/Loading'
import LoadingComponent from 'src/components/Progress/Loading'
import Notistack from 'src/components/Snackbars/Notistack'
import http from 'src/helpers/http'
import permission from 'src/helpers/permission'
import PositionGPS from '../components/PositionGPS/Index'
import Forbidden from '../components/Pages/Forbidden';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  widthSidebar: {
    [theme.breakpoints.up('md')]: {
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: '#eeeeee',
    padding: `0px`,
    width: `calc(100% - ${theme.drawerWidth}px)`,
    [theme.breakpoints.up('md')]: {
      padding: '0px 0px',
      marginTop: '-7px',
    },
  }
})

class Main extends React.Component {
  constructor(props) {
    super(props)
    http.initOnce(props)
  }

  renderRoutes() {
    let result = []
    let index = 0
    let accessRoute = routes
    let userInfo = localStorage.getItem('user', "");
    userInfo = JSON.parse(userInfo);
    if (userInfo.role === 'driver') {
      accessRoute = routesDriver
    }
    for (let route of accessRoute) {
      result.push(
        <Route
          key={index++}
          path={route.path}
          exact={route.exact || route.children ? true : false}
          name={route.name}
          component={() => this.renderComponent(route)}
        />
      )
    }
    return result
  }

  renderLoading = () => {
    return (
      <LoadingComponent show={true} />
    )
  }

  renderComponent(route) {
    const { classes } = this.props;
    const Component = permission.hasPermission(route.role) ? route.component : Forbidden
    return <div className={classes.root}>
      <CssBaseline />
      <LeftSide route={route} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Suspense fallback={this.renderLoading()}>
          <Component route={route} />
        </Suspense>
      </main>
    </div>
  }

  render() {
    return (<React.Fragment>
      <Switch>
        {this.renderRoutes(routes)}
        <Route path="*">
          <Forbidden />
        </Route>
      </Switch>
      <Loading />
      <Notistack />
      <PositionGPS />
    </React.Fragment>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Main));
