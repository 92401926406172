import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import { Form, DateTimeField, Validation } from 'src/components/Forms'
import { BaseView } from 'src/views/BaseView';
import { I18n } from 'src/helpers/I18n';
import { Grid, Button, Typography } from '@material-ui/core';
import PaperFade from "src/components/Main/PaperFade";
import moment from 'moment'
import {monthFormatBackend} from 'src/config/constant'
import {
  DatePicker
} from '@material-ui/pickers';

moment.defaultFormat = monthFormatBackend;

const styles = theme => ({
  labelChooseDate: {
    lineHeight: "56px",    
  },
  calendar: {
    display: "inline-block",
    borderRadius: "5px",
    border: "1px solid #e8e8e8"
  },
  pagerFade: {
    minHeight: 'calc(100vh - 89px)',
    position: 'relative'
  },
  label: {
    maxWidth: '180px',
    ['@media (max-width: 599px)']: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  dateField: {
    maxWidth: 'calc(100% - 180px)',
    flexBasis: 'calc(100% - 180px)',
    ['@media (max-width: 599px)']: {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  },
  buttonBottom: {
    ['@media (max-width: 959px)']: {
      bottom: '40px',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
    }
  },
  buttonBottomExportExcel: {
    ['@media (max-width: 959px)']: {
      bottom: '0',
    }
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format(),
      month: moment().format()
    }
  }

  invalidDate(value){
    if(moment(value).format("YYYY-MM-DD") == "Invalid date")
      return I18n.t("Validate.dateInvalid")
  }

  render() {
    const { classes, viewReport, onExportFile,onExportFileDaily } = this.props;
    
    return (
      <PaperFade className={classes.pagerFade}>
        <Form className={classes.form} >
          <Grid container direction='row' justify='flex-start' alignItems='baseline'>
            <Grid item xs={12} sm={12} md={6} lg={4}> 
              <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                <Grid item xs={6} className={classes.label}>
                  <Typography className={classes.labelChooseDate}>{I18n.t("Label.chooseDate")}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.dateField}>
                  <DateTimeField
                    name="date"
                    variant="inline"
                    format="YYYY/MM/DD"
                    label={I18n.t('Input.date')}
                    autoOk={true}
                    showTime={false}
                    value={this.state.date}
                    validate={[
                      Validation.required(I18n.t("Validate.required.base")),
                      this.invalidDate
                    ]}
                    onChange={value => this.setState({date: value})}
                    // orientation="landscape"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} align="" className={classes.buttonBottom}>
                <Button 
                  xs={6} sm={6} md={6} lg={6}
                  variant="contained" color="primary" size='small'
                  disabled={this.invalidDate(this.state.date)}
                  onClick={() => onExportFileDaily(this.state.date)}>{I18n.t("Button.export2")}
                </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}> 
              <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                <Grid item xs={6} className={classes.label}>
                  <Typography className={classes.labelChooseDate}>{I18n.t("Label.chooseMonth")}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.dateField}>
                  <DateTimeField
                    name="date"
                    variant="inline"
                    format="YYYY/MM"
                    label={I18n.t('Input.month')}
                    autoOk={true}
                    openTo="month"
                    views={["year", "month"]}
                    showTime={false}
                    showDate={true}
                    value={this.state.month}
                    validate={[
                      Validation.required(I18n.t("Validate.required.base")),
                      this.invalidDate
                    ]}
                    onChange={value => this.setState({month: value})}
                    // orientation="landscape"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} align="" className={`${classes.buttonBottom} ${classes.buttonBottomExportExcel}`}>
              <Button 
                size='small'
                color='primary'
                variant='contained'
                onClick = {() => onExportFile(this.state.month)}>
                {I18n.t("Button.export2")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Index));
