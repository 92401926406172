import React from 'react';
import PropTypes from 'prop-types';
import {Button, CardActions, Grid, Typography} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {Form} from 'src/components/Forms';
import {BaseView} from 'src/views/BaseView';
import moment from 'moment'
import Utils from 'src/helpers/utility';
import {I18n} from 'src/helpers/I18n';

const styles = theme => ({
  positionBottom: {
    [theme.breakpoints.down('md')]: {
      margin: "10px auto auto auto",
    },
    [theme.breakpoints.down('xs')]: {
      position: "absolute",
      margin: "auto",
      display: "inline-block",
      left: "50%",
      bottom: "8px",
      transform: "translate(-50%)"
    }
  },
  confirmBtn: {
    marginLeft: '5px'
  },
  cardAction: {
    paddingLeft: 0,
  },
  form: {
    ['@media (max-height: 380px)']: {
      position: 'relative',
      minHeight: '400px'
    }
  },
  formFooter: {
    minWidth: '174px'
  }
})

class Index extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      reload: false,
      isUpdating: false,
      data: {}
    }
  }

  onClickBtn = () => {
    this.setState({isEditing: !this.state.isEditing, reload: !this.state.reload, isUpdating: false, data: {}})
    this.props.getIsEditing(this.state.isEditing)
  }

  onEditBtn = (data) => {
    this.setState({isEditing: !this.state.isEditing, reload: !this.state.reload, isUpdating: true, data: data})
    this.props.getIsEditing(this.state.isEditing)
  }

  renderFooterActions = (isEditing, classes) => {
    return (
      <>
        {isEditing &&
          <div className={classes.positionBottom+ ' ' +classes.formFooter} >
            <Button size='medium' variant="contained" color="primary" onClick={this.onClickBtn}>
              {I18n.t('Button.cancel')}
            </Button>
            <Button className={classes.confirmBtn} size='medium' variant="contained" color="primary" type="submit">
              {I18n.t('Button.confirm')}
            </Button>
          </div>
        }
      </>
    )
  }

  renderFormInput = (isEditing) => {
    if (!isEditing) return '';
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6' >
            {moment().format('YYYY/MM/DD')}
          </Typography>
        </Grid>
        {Utils.renderFormInputFuels({}, !isEditing)}
      </Grid>
    )
  }

  renderFormInputUpdate = (isEditing, data) => {
    if (!isEditing) return '';
    const dataFuel = {}
    data.details.map(doc=> {
      dataFuel[doc.material] = doc.quantity
    })
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h6' >
            {moment(data.insert.when).format('YYYY/MM/DD')}
          </Typography>
        </Grid>
        {Utils.renderFormInputFuels(dataFuel, !isEditing)}
      </Grid>
    )
  }

  onSubmit(values) {
    const {onSubmit} = this.props;
    const {isUpdating, data} = this.state;
    // validate data
    values = Utils.formatDataImportFuels(values);
    const obj = {values}
    if (isUpdating) obj._id = data._id
    onSubmit(obj)
  }

  render() {
    const {classes} = this.props
    const {isEditing, isUpdating, data} = this.state
    return (
      <Form className={classes.form} onSubmit={(value) => this.onSubmit(value)}>
        {!isUpdating && this.renderFormInput(isEditing)}
        {isUpdating && this.renderFormInputUpdate(isEditing, data)}
        <CardActions className={classes.cardAction}>
          {this.renderFooterActions(isEditing, classes)}
        </CardActions>
      </Form>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
