
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'src/helpers/I18n';
import { Button } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: "50px",
    color: "red",
    textAlign: "center",
  },
});

class Forbidden extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.main}>
          <h3>{I18n.t("NotFound.title")}</h3>
          <Button
            color="primary"
            size='medium'
            variant="contained"
            onClick={() => { window.history.back() }}
          >
            {I18n.t("Button.back")}
          </Button>
        </main>
      </div>
    );
  }
}

Forbidden.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Forbidden);