import React from 'react';
import View from 'src/views/Report/Index'
import BaseContainer from 'src/containers/BaseContainer';
import {withRouter} from 'react-router-dom'
import moment from 'moment';
import ReportAction from '../../actions/ReportAction';
import { connect } from 'react-redux'

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.filters = {}
  }

  componentDidMount() {
    localStorage.removeItem('divideOrderLocal')
    localStorage.removeItem('idTableOld')
  }

  onSubmit(values) {
    let month = moment(values.month).format("MM/YYYY");
    values = {...values, month: month};
  }

  viewReport = async (date) => {
    let dateString = moment(date).format("YYYY-MM-DD")
    let url = window.config.API_HOST + `/api/authWithSession?token=${localStorage.getItem("token")}&redirect=/pdf/daily-report-${dateString}.pdf`
    window.open(url);
  }
  onExportFileDaily = (date) => {
    let dateString = moment(date).format("YYYY-MM-DD")
    this.props.dispatch(ReportAction.exportDaily({date: dateString})).then(response => {
      let { path } = this.getData(response, 'data', {});

      // if(path) {
        setTimeout(() => {
          const res = {
            file: path,
          };
          window.open(res.file);
        }, 2000); 
      // }
    }).catch(error => console.warn(error))
  }
  onExportFile = (date) => {
    let dateString = moment(date).format("YYYY-MM-DD")
    this.props.dispatch(ReportAction.exportMonth({date: dateString})).then(response => {
      let { path } = this.getData(response, 'data', {});

      // if(path) {
        setTimeout(() => {
          const res = {
            file: path,
          };
          window.open(res.file);
        }, 2000); 
      // }
    }).catch(error => console.warn(error))
  }

  render() {
    return (
      <View
        onSubmit={this.onSubmit}
        viewReport={this.viewReport}
        onExportFileDaily={this.onExportFileDaily}
        onExportFile={this.onExportFile}
        {...this.props}
      />
    );
  }
}

export default withRouter(connect()(Index))
