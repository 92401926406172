import React from "react";
import { I18n } from "src/helpers/I18n";
import { CardContent, Grid, Typography, Button } from "@material-ui/core";
import { TextField } from "src/components/Forms";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const renderSettingAgency = (params) => {
	const {
		isEditing,
		index,
		classes,
		settingPrice,
		getData,
		renderChip,
		renderInput,
		renderAdornments,
	} = params;

	return ["diesel", "kerosene", "crudeOil", "gasoline"].map((fuel) => {
		let width = fuel === "gasoline" ? 3 : 2;
		return (
			<Grid item xs={12} sm={width} md={width} lg={width} key={fuel}>
				{renderChip(
					classes,
					I18n.t(`Label.products.${fuel}`),
					<LocalGasStationIcon fontSize="small" />
				)}
				{renderInput(
					classes,
					settingPrice,
					isEditing,
					I18n.t(`Label.products.${fuel}`),
					`agencies[${index}][${fuel}]`,
					"start",
					getData,
					renderAdornments
				)}
			</Grid>
		);
	});
};

const SettingAgency = (props) => {
	const {
		index,
		isEditing,
		agencyName,
		classes,
		renderChip,
		renderInput,
		renderInsurance,
		renderAdornments,
		settingPrice,
		getData,
		validation,
		onClickDeleteBtn,
	} = props;

	return (
		<CardContent className={classes.cardBackground}>
			<Grid container direction="row" justify="flex-end">
				{isEditing ? (
					<Button
						size="small"
						variant="contained"
						style={{ background: "#F40B27", color: "white" }}
						onClick={() => onClickDeleteBtn(index)}
					>
						{I18n.t("Button.delete")}
					</Button>
				) : (
					""
				)}
			</Grid>
			<Typography variant="h6" className={classes.cardTitle}>
				{isEditing ? (
					<TextField
						fullWidth
						name={`agencies[${index}][label]`}
						value={agencyName}
						validate={validation}
					/>
				) : (
					agencyName
				)}
				{isEditing ? (
					<TextField
						name={`agencies[${index}][value]`}
						value={index + 3}
						type="hidden"
					/>
				) : (
					""
				)}
				{isEditing ? (
					<TextField
						name={`agencies[${index}][key]`}
						value={"agency"}
						type="hidden"
					/>
				) : (
					""
				)}
			</Typography>

			<Grid
				container
				spacing={4}
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				{renderSettingAgency(props)}

				<Grid item xs={12} sm={3} md={3} lg={3}>
					{" "}
					{/* Bảo hiểm */}
					{renderChip(
						classes,
						I18n.t(`Label.insurance`),
						<AttachMoneyIcon fontSize="small" />
					)}
					{renderInsurance(
						classes,
						settingPrice,
						isEditing,
						I18n.t(`Label.insurance`),
						`agencies[${index}][limit]`,
						getData,
						renderAdornments
					)}
					{renderInput(
						classes,
						settingPrice,
						isEditing,
						I18n.t(`Label.insuranceFee`),
						`agencies[${index}][amount]`,
						"start",
						getData,
						renderAdornments
					)}
				</Grid>
			</Grid>
		</CardContent>
	);
};

export default SettingAgency;
