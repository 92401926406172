import React from "react"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {I18n} from 'src/helpers/I18n'
import {Grid, Button} from '@material-ui/core'
import {BaseView} from 'src/views/BaseView'
import {withRouter} from 'react-router-dom'
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog'
import Utils from 'src/helpers/utility';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  height: 'auto',
  overflowX: 'hidden',
  // padding: '5px 5px',
  padding: '10px',
  margin: `0 0 2px 0`,
  background: isDragging ? "lightgreen" : "white",
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: '5px 5px',
  overflowX: 'auto',
  height: 400,
  width: '100%'
})

class ReactDND extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      reload: false,
      elements: props.dataFormat
    }
    this.onDragEnd = this.onDragEnd.bind(this)
    this.ConfirmDialog = null;

  }

  componentDidUpdate(prevProps, prevState){
    if(JSON.stringify(this.props.dataFormat) != JSON.stringify(prevProps.dataFormat)){
      this.setState({ elements: this.props.dataFormat  })
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    const elements = reorder(this.state.elements, result.source.index, result.destination.index)
    this.props.getDataSort(elements)
    this.setState({ elements })
  }

  gotoDetail = (_id) => {
    this.goto(`/orders/detail/${_id}`)
    localStorage.setItem('backDivideOrder', 'backDivideOrder')
  }

  renderDialogConfirmDelete() {
    return <ConfirmDialog
       ref={(ref) => this.ConfirmDialog = ref}
       title={I18n.t('Confirm.undivide')}
       // content={I18n.t('Message.deleteDialogContent')}
       onSubmit={this.unDivideOrder}
    />
 }

  unDivideOrder = (_id) => {
    this.props.unDivideOrder(_id)
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {
                this.state.elements.map((item, index) => {
                  let _id = this.state.elements[index]['_id']
                  return (
                    <Draggable key={_id} draggableId={_id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Grid container spacing={1} direction="row" justify="flex-start" >
                            <Grid item xs={3}> 
                              {index + 1} {this.getData(item, "customer.name", "")}
                            </Grid>
                            <Grid item xs={5}> 
                              {Utils.addressReg((this.getData(item, "deliveryAddress", "")))} 
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'left' }} >
                              <button 
                                style={{ color: 'white', backgroundColor: 'black', border: 'none' }}
                                onClick={() => this.gotoDetail(item._id)}
                              >
                                {I18n.t("Button.detail")}
                              </button>
                              {
                                this.getData(item, 'status', '') == 3
                                ? <button size="small" style={{ backgroundColor: 'red', marginLeft: "5px" , color: 'white', border: 'none'}}>
                                    {I18n.t('Button.finish')}
                                  </button>
                                : <button style={{ border: 'none', marginLeft: "5px" }} onClick={() => this.ConfirmDialog.show(item._id)}>
                                    {I18n.t("Button.delete")}
                                  </button>
                              }
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {this.renderDialogConfirmDelete()}
      </DragDropContext>
    )
  }
}


export default withRouter(ReactDND)

// export default ReactDND
// Put the thing into the DOM!
