import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import BaseView from 'src/views/BaseView';
import {Chip, List, ListItem, ListItemText} from '@material-ui/core'
import {I18n} from 'src/helpers/I18n';

const styles = theme => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
});

class RemainingFuelList extends BaseView {
  constructor(props) {
    super(props);
  }

  formatRemainingFuels = (data) => {
    let rs = [];
    for (let item in data) {
      rs.push({
        name: item,
        value: String(data[item]).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      })
    }
    return rs
  }

  render() {
    const {classes, truck, styleList} = this.props;
    let fuels = this.getData(truck, "remain", {});
    fuels = {
      diesel: _.get(fuels, 'diesel', 0),
      kerosene: _.get(fuels, 'kerosene', 0),
      crudeOil: _.get(fuels, 'crudeOil', 0),
      gasoline: _.get(fuels, 'gasoline', 0),
      adBlue: _.get(fuels, 'adBlue', 0)
    }
    const lists = this.formatRemainingFuels(fuels)
    
    return (
      <List style={styleList}>
        {lists.map(item =>
          <ListItem key={item.name}>
            <ListItemText 
              primary={
                <React.Fragment>
                  {I18n.t(`Label.products.${item.name}`)}
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    label={item.value}
                  />
                </React.Fragment>
              }
            />
          </ListItem>
        )}
      </List>
    );
  }
}

RemainingFuelList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RemainingFuelList);
