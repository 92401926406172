import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Container, Chip, Typography, Icon, IconButton, Paper, Tooltip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import BaseView from 'src/views/BaseView';
import PaperFade from 'src/components/Main/PaperFade';
import { formatShortDateField } from 'src/config/constant';
import SelectField, { Option } from 'src/components/Forms/SelectField';
import Utils from 'src/helpers/utility';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckPickup } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import { I18n } from 'src/helpers/I18n';
import { fuelProducts } from 'src/config/constant';

const GridTable = React.lazy(() => import('src/components/Table/GridTable'));
const FuelTotal = ({ classes, title, totalOrderDetails }) => {
  let arrayFuel = [];
  fuelProducts.forEach((fuelName) => {
    const matched = totalOrderDetails.find((fuel) => { return fuel.name === fuelName });
    if (matched) {
      arrayFuel.push(
        { name: fuelName, quantity: matched.quantity }
      )
    } else {
      arrayFuel.push(
        { name: fuelName, quantity: 0 }
      )
    }
  });

  return (
    <Container maxWidth="md">
      <Typography variant="h6" className={classes.fuelHead}>{title}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {arrayFuel.map(fuel => (
                <TableCell className={classes.fuelHead} align="center">{I18n.t(`Label.products.${fuel.name}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {arrayFuel.map(fuel => (
                <TableCell align="center">{fuel.quantity} L</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>);
}

FuelTotal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

const styles = theme => ({
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  tableContainer: {
    borderRadius: '0',
    borderBottom: '0',
    '& .MuiTableRow-root': {
      // backgroundColor: '#fff !important'
    },
    '& th, & td': {
      wordBreak: 'break-all',
    }
  },
  gridTable: {
    '& .MuiTable-root': {
      minWidth: '100% !important'
    },
    '& .Grid-Root-Table': {
      maxHeight: 'calc(100vh - 30px) !important',
    },

    '& .InputDateRanger input': {
      fontSize: '14px !important'
    }
  },
  fuelHead: {
    fontWeight: 'bold',
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {}
    this.table = {
      columns: [
        {
          name: 'insert.when',
          title: I18n.t('Table.order.orderDate'),
          type: "date",
          filterFormat: formatShortDateField,
          defaultFilterOperation: "daterange",
          formatterComponent: (data) => {
            let insertWhen = this.getData(data, 'row.insert.when', '')
            return moment(insertWhen).format('YYYY/MM/DD')
          },
          width: 146
        },
        {
          name: 'deliveryDate',
          title: I18n.t('Table.order.deliveryDate'),
          type: "date",
          filterFormat: formatShortDateField,
          defaultFilterOperation: "daterange",
          formatterComponent: (data) => {
            let deliveryDate = this.getData(data, 'row.deliveryDate', '')
            return moment(deliveryDate).format('YYYY/MM/DD')
          },
          width: 146
        },
        {
          name: 'customer.name',
          title: I18n.t('Table.customer.name'),
          width: 140,
          formatterComponent: (data) => this.renderDataField(data, 'customer.name')
        },
        {
          name: 'deliveryTime',
          title: I18n.t('Table.order.deliveryTime'),
          type: "number",
          width: 110,
          formatterComponent: (data) => Utils._formatDeliveryTime(data),
          editorComponent: ({ value, onValueChange }) => (
            <SelectField
              value={value}
              onChange={onValueChange}
              fullWidth
            >
              <Option value="">{I18n.t("Label.all")}</Option>
              {Utils.renderDeliveryTimeOptions()}
            </SelectField>)
        },
        {
          name: 'area.code',
          title: I18n.t('Table.area.label'),
          formatterComponent: data => this.renderDataField(data, 'area.code'),
          width: 92
        },
        {
          name: 'deliveryAddress',
          title: I18n.t('Table.order.address'),
          width: 150,
          formatterComponent: (data) => {
            return Utils.addressReg(data.value);
          },
        },
        {
          name: 'memo',
          title: I18n.t('Input.order.memo'),
          width: 125,
          filterable: false,
          sortable: false
        },
        {
          name: 'orderDetails',
          filterable: false,
          sortable: false,
          title: I18n.t('Table.order.expectNum'),
          formatterComponent: (data) => {
            return this.renderDetailsColumn(data);
          },
          width: 210
        },
        {
          name: 'status',
          title: I18n.t('Table.status'),
          formatterComponent: (data) => {
            return this.mapLabelStatus(data);
          },
          type: "number",
          editorComponent: ({ value = "", onValueChange }) => (
            <SelectField value={value} onChange={onValueChange} fullWidth >
              <Option value="" key="0">{I18n.t("Label.all")}</Option>
              <Option value="1" key="1">{I18n.t('Label.statusOrder.waiting')}</Option>
              <Option value="2" key="2">{I18n.t('Label.statusOrder.divided')}</Option>
              <Option value="3" key="3">{I18n.t('Label.statusOrder.delivered')}</Option>
            </SelectField>
          ),
          width: 100,
        },
        {
          name: '_id',
          title: I18n.t('Table.action'),
          filterable: false,
          sortable: false,
          formatterComponent: this.renderActionsColumn,
          width: 120
        },
        {
          name: '',
          filterable: false,
          sortable: false,
          formatterComponent: (data) => {
            if(parseInt(this.getData(data, 'row.status', '')) === 3) {
              let deliveryDate = this.getData(data, 'row.update.when', '')
              return moment(deliveryDate).format('YYYY/MM/DD')
            }
            return ""
          },
          width: 120
        },
      ],
      defaultSort: [],
    }
    this.ConfirmDeleteDialog = null;
    this.toolbarActions = [
      { key: "create", path: "/orders/create", type: "button", btnText: I18n.t('Breadcrumb.order.create') }
    ];
    this.renderToolbarActions = this.renderToolbarActions.bind(this)
    this.renderSelectedActions = this.renderSelectedActions.bind(this)
  }

  renderActionsColumn = (data) => {
    let _id = this.getData(data, 'row._id', '')
    let status = this.getData(data, 'row.status', '')
    let deliveryDate = moment(this.getData(data, 'row.deliveryDate', '')).format('YYYY-MM-DD')
    return (
      <div>
        <Tooltip title={I18n.t("Tooltip.edit")} key="edit">
          <IconButton key="edit" onClick={() => this.goto(`/orders/edit/${_id}`)}>
            <Icon>edit</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title={I18n.t("Tooltip.detail")} key="detail">
          <IconButton key="detail" onClick={() => this.goto(`/orders/detail/${_id}`)}>
            <Icon>assignment</Icon>
          </IconButton>
        </Tooltip>
        {
          status == 1
            ? <Tooltip title={I18n.t("")} key="xxx">
              <IconButton key="xxx" onClick={() => this.goto(`/divide-order?date=${deliveryDate}`)}>
                <FontAwesomeIcon icon={faTruckPickup} size="xs"></FontAwesomeIcon>
              </IconButton>
            </Tooltip>
            : ""
        }

      </div>
    )
  }

  renderDetailsColumn(data) {
    const { classes } = this.props,
      { value = [] } = data;

    return (
      <TableContainer className={classes.tableContainer} component={Paper} variant="outlined">
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {value.map(fuel =>
              <TableRow key={fuel.name}>
                <TableCell component="th" scope="row">
                  {I18n.t(`Label.products.${fuel.name}`)}
                </TableCell>
                <TableCell align="right">{fuel.quantity}&nbsp;L</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  mapLabelStatus(data) {
    let driver = this.getData(data, "row.driver.fullName", "");
    switch (data.value) {
      case 1:
        return (
          <div align="center">
            <Chip size="small" label={I18n.t('Label.statusOrder.waiting')} />
          </div>
        );
      case 2:
        return (
          <div align="center">
            <Chip size="small" color="secondary" label={I18n.t('Label.statusOrder.divided')} />
            <Typography align="center" variant="caption" style={{ display: "block" }}>
              {driver}
            </Typography>
          </div>
        );

      case 3:
        return (
          <div align="center">
            <Chip size="small" color="primary" label={I18n.t('Label.statusOrder.delivered')} />
            <Typography align="center" variant="caption" style={{ display: "block" }}>
              {driver}
            </Typography>
          </div>
        );

      default:
        break;
    }
    return "";
  }

  // renderToolbarActions() {
  //   return this.toolbarActions.map(item =>(
  //     <Button 
  //       key={item.key}
  //       variant="outlined" 
  //       onClick={() => this.goto(item.path)}
  //     >
  //       {item.btnText}
  //     </Button>
  //   ))
  // }


  render() {
    const { classes } = this.props;
    let orders = this.getData(this.props, 'orders', {});
    let totalOrderDetails = this.getData(this.props, 'totalOrderDetails', {});

    return (
      <PaperFade showLoading={true}>
        <FuelTotal classes={classes} title={I18n.t("Label.order.total")} totalOrderDetails={totalOrderDetails} />

        <GridTable
          id="OrderIndex"
          className={classes.gridTable}
          onFetchData={this.props.onFetchData}
          onRefTable={this.props.onRefTable}
          columns={this.table.columns}
          rows={orders ? orders.list_data : []}
          totalCount={orders ? orders.total : 0}
          pageSize={orders ? orders.pageSize : 20}
          defaultSort={this.table.defaultSort}
          showCheckboxColumn={true}
          height="auto"
          selectedActions={this.renderSelectedActions}
          tableActions={this.renderToolbarActions}
        />
        {this.renderDialogConfirmDelete()}
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
