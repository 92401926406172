import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import FilterForm from './components/FilterForm'

const styles = theme => ({
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  marginBottomNone: {
    marginBottom: '0px !important'
  },
  pagerFade: {
    minHeight: 'calc(100vh - 89px)',
    position: 'relative'
  },
})

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      type: '1',
      reload: false
    }
  }

  onSubmit =(values) => {
    this.props.onSubmit(values)
  }

  setTypeViews = (type) => {
    this.setState({ type: type })
    this.setState({ reload: !this.state.reload })
  }

  render() {
    const { 
      classes, customers, invoices = {}, 
      loadUser, DeliveryDate, filters, onExportFile
    } = this.props;
    
    let { type } = this.state
    
    return (
        <PaperFade showLoading={true} className={classes.pagerFade}>
          {this.props.agencies && <FilterForm
            customers={customers}
            onExportFile={onExportFile}
            agencies={this.props.agencies}
            setTypeViews={this.setTypeViews}
          />}
          
        </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
