import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import BaseView from 'src/views/BaseView'
import PaperFade from 'src/components/Main/PaperFade'
import IndexSKE from './IndexSKE'
import IndexMC from './IndexMC'
import IndexOther from './IndexOther'
import FilterForm from './components/FilterForm'
import ProductList from './ProductList'
import LoadingCircle from '../../components/Progress/LoadingCircle';
import { customerTypes } from "src/config/constant";
import _ from "lodash";

const styles = theme => ({
  driverName: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  fixColumn: {
    whiteSpace: 'normal',
  },
  marginBottomNone: {
    marginBottom: '0px !important'
  }
})

class Index extends BaseView {
  constructor(props) {
    super(props)
    this.state = {
      type: '1',
      reload: false
    }
    this.getTypeViews = this.getTypeViews.bind(this)
  }

  onSubmit = (values) => {
    this.month = values.month
    this.props.onSubmit(values)
  }

  getTypeViews(type) {
    this.setState({ type: type, reload: !this.state.reload })
  }

  render() {
    const { classes, customers, invoices = {}, loadUser, invoiceDate, filters, isLoading } = this.props;
    let { type } = this.state;

    let agencies = this.getData(this.props, 'agencies', []);
    if (Array.isArray(agencies)) {
      agencies = agencies.map(agency => _.pick(agency, _.keys(customerTypes[0])));
    }
    agencies = _.unionBy(customerTypes, agencies, "label");

    let orders = [];
    let arrInvoices = this.getData(invoices, "lists", []);
    if (arrInvoices && arrInvoices.length) {
      orders = arrInvoices.map(invoice => {
        let order = {};
        order['customerName'] = invoice.customer.name;
        order['products'] = invoice.products;

        return order;
      });
    }

    let agencyList;
    if (type === '1') {
      agencyList = <IndexSKE data={this.getData(invoices, "lists", [])} invoiceDate={invoiceDate} filters={filters} />;
    } else if (type === '2') {
      agencyList = <IndexMC data={this.getData(invoices, "lists", [])} overAll={this.getData(invoices, "total", {})} invoiceDate={invoiceDate} filters={filters} />;
    } else {
      agencyList = <IndexOther data={this.getData(invoices, "lists", [])} overAll={this.getData(invoices, "total", {})} invoiceDate={invoiceDate} filters={filters} />;
    }

    return (<React.Fragment>
      <PaperFade className={classes.marginBottomNone} showLoading={true}>
        <FilterForm
          customers={customers}
          onSubmit={this.onSubmit}
          loadUser={loadUser}
          getTypeViews={this.getTypeViews}
          agencies={agencies}
        />
      </PaperFade>
      <LoadingCircle
        show={isLoading}
      />
      {agencyList}
      <ProductList classes={classes} orders={orders} />
    </React.Fragment>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Index));
