import React from 'react';
import View from 'src/views/Invoice/Index'
import ReportAction from '../../actions/ReportAction'
import SettingAction from '../../actions/SettingPriceAction'
import CustomerAction from '../../actions/CustomerAction'
import BaseContainer, { selector } from 'src/containers/BaseContainer';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import moment from 'moment';
import { dateFormatBackend } from 'src/config/constant';
moment.defaultFormat = dateFormatBackend;

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null
    this.onRefTable = this.onRefTable.bind(this)
    this.filters = {}
  }

  componentDidMount() {
    this.onFetchData({}) // clear data table
    this.props.dispatch(SettingAction.fetch());
    localStorage.removeItem('divideOrderLocal');
    localStorage.removeItem('idTableOld');
  }

  getInvoiceDate({ startDate, endDate }) {
    return {
      startDate: startDate && moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate && moment(endDate).format("YYYY-MM-DD")
    }
  }

  onFetchData = (state) => {
    //phải đổi cái này thành Payment Action
    this.props.dispatch(SettingAction.fetch());
    this.invoiceDate = this.getInvoiceDate(state)
    this.filters = state;
    this.props.dispatch(ReportAction.fetchInvoices({ ...state, ...this.invoiceDate }))
  }

  onSubmit = (state) => {
    this.invoiceDate = this.getInvoiceDate(state)
    this.filters = state;
    this.props.dispatch(SettingAction.checkSetting({...this.invoiceDate, disableLoading: true}))
      .then(data => {
        if (data.error) {
          this.notify(`${data.error.message}`, 'warning')
          this.onFetchData({}) // clear data table
        } else {
          this.props.dispatch(ReportAction.fetchInvoices({ ...state, ...this.invoiceDate, disableLoading: true }))
        }
      })
  }

  loadUser = (filters) => {
    //build lại conditions cho phù hợp với search api
    let conditions = [
      { columnName: "type", value: filters['type'], operation: 'equal', "dataType": "number" },
      { columnName: "paymentTerm", value: filters['paymentTerm'], operation: 'equal', "dataType": "text" },
    ]
    this.props.dispatch(CustomerAction.fetchAll({
      filters: conditions,
      pageSize: -1
    }))
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  render() {
    return (
      <View
        onFetchData={this.onFetchData}
        onSubmit={this.onSubmit}
        loadUser={this.loadUser}
        onRefTable={this.onRefTable}
        onDeleteData={this.onDeleteData}
        invoices={this.props.invoices}
        customers={this.props.customers}
        agencies={this.props.agencies}
        invoiceDate={this.invoiceDate}
        productPricesByCategory={this.productPricesByCategory}
        filters={this.filters}
        isLoading={this.props.isLoading}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: selector(state, "loading", false),
    invoices: selector(state, "reports.invoices", {}),
    customers: selector(state, "customer.list.list_data", []),
    agencies: selector(state, "settingPrice.data.agencies", []),
    productPricesByCategory: selector(state, "settingPrice.data.productsByCategory.0", [])
  }
}

export default withRouter(connect(mapStateToProps)(Index))
