import BaseContainer, { selector } from 'src/containers/BaseContainer'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DriverAction from 'src/actions/DriverAction';

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.getPosition = this.getPosition.bind(this)
    this.time = null
  }

  onSubmit(position) {
    this.props.dispatch(DriverAction.updateGPS({ mapAddress: position }))
  }

  componentDidMount() {
    let userInfo = localStorage.getItem('user');
    userInfo = JSON.parse(userInfo);
    if (userInfo.role === 'driver') {
      this.time = setInterval(this.getPosition, 120000)
      // this.time = this.getPosition();
    }
  }

  // componentWillUnMount() {
  //   if (this.time) {
  //     navigator.geolocation.clearWatch(this.time);
  //   }
  // }

  getPosition() {
    // let options = {
    //   enableHighAccuracy: false,
    //   timeout: 5000,
    //   maximumAge: 0
    // }
    navigator.geolocation.getCurrentPosition((data) => {
      let position = {
        lat: String(data.coords.latitude),
        lng: String(data.coords.longitude)
      }
      this.onSubmit(position)
    });
    // return navigator.geolocation.watchPosition( (pos) => {
    //   let position = { 
    //     lat: String(pos.coords.latitude), 
    //     lng: String(pos.coords.longitude)
    //   }
    //   console.log('call update GPS');
    //   this.onSubmit(position)
    // }, (err) => {
    //   console.log('err get location', err.message);
    // }, options);
  }

  render() {
    return ""
  }
}

const mapStateToProps = state => {
  return {
    data: selector(state, "user.list", {}),
  }
}

export default withRouter(connect(mapStateToProps)(Index))

