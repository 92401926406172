export default {
  translation: {
    app_name: "Gasonline",
    company_name: "SKE",

    NotFound: {
      title: 'This page is not available'
    },

    Button: {
      login: "LOGIN",
      logout: "LOGOUT",
      remember: "Remember Me",
      create: "Create",
      edit: "Edit",
      delete: "Delete",
      detail: 'Detail',
      view: "View",
      back: "Back",
      close: 'Close',
      ok: "OK",
      onFocus: "Update GPS: On",
      offFocus: "Update GPS: Off",
      clear: "Clear",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      print: "Print",
      exportPDF: 'PDF',
      exportExcel: 'Export excel',
      divide: "Divide",
      confirm: "Confirm",
      remaining: "Remaining",
      refueling: "Refueling",
      generalDelivery: "General",
      separateDelivery: "Separate",
      changePassword: 'Change password',
      googleMap: "Google Map",
      delivered: "Delivered",
      delivery: "Delivery",
      listDelivery: "List delivery ",
      deliveryCompleted: 'Delivery completed',
      add: 'Add',
      finish: "Finish",
      beforeDelivery: 'Before delivery',
      agree: 'Agree',
    },

    Sidebar: {
      area: 'Manage areas',
      customer: "Manage customers",
      delivery: "Delivery list",
      deliveryOthers: "Delivery others",
      divideOrder: "Divide orders",
      driver: "Manage drivers",
      order: "Manage orders",
      settingPrice: "Setting prices",
      remainingFuels: "Remaining Fuels",
      report: "Daily report",
      pouringFuels: "Poured Fuels Statistics",
      user: "Manage users",
      vehicle: "Manage vehicles",
      product: "Registered Products",
      estimation: "Estimation",
      reportDelivery:"Delivery Report"
    },

    Breadcrumb: {
      area: {
        index: "List areas",
        create: "Add new area",
        edit: "Edit area information"
      },

      product: {
        index: "Product List",
        create: "Add New Product",
        edit: "Edit Product"
      },

      customer: {
        index: "List customers",
        create: "Add new customer",
        edit: "Edit customer infomation",
        detail: "Customer information",
        history: "Customer history",
      },

      driver: {
        index: "List drivers",
        create: "Add new driver",
        edit: "Edit driver information",
        detail: "Driver information",
        gps: 'GPS'
      },

      order: {
        index: "List orders",
        create: "Add new order",
        edit: "Edit order",
        detail: "Order details",
        divide: "Divide orders"
      },

      user: {
        index: "List users",
        create: "Add new user",
        edit: "Edit user information"
      },

      vehicle: {
        index: "List vehicles",
        create: "Add new vehicle",
        edit: "Edit vehicle information",
        detail: "Vehicle information"
      },

      estimation: {
        index: "List estimations",
        create: "Add new estimation",
        edit: "Edit area information"
      },

      settingPriceIndex: "Setting prices",
      invoice: 'Invoice',
      estimation: "Estimation",
      reportIndex: "Report daily",
      reportDelivery:"Delivery Report",
      delivery: "Delivery List",
      indexDelivery: "Add the number of delivery",
      generalDelivery: "General delivery",
      separateDelivery: "Separate delivery",
      deliveryOthers: "Delivery Others",
      pouredAmount: "Poured fuels into the tank",
      remainingAmount: "Remaining fuels"
    },

    Label: {
      all: "--All",
      amount: "Amount",
      chooseDate: "Choose report date",
      chooseSizePaper: "Choose paper size",
      chooseProduct: "Choose fuel products",
      createCustomerByPhone: "New customer: {{phone}}",
      date: "Date",
      month: "Month",
      year: 'Year',
      discount: "Discount",
      actualRemainingFuel: "Actual Remaining Fuel",
      ordersNotDivided: "List orders are not divided",
      report: "Delivery Report",
      receivedVehicle: "Xe tiếp nhiên liệu",
      status: "Status",
      tax: "TAX",
      totalAmount: "Total Amount",
      orderSelected: "Order Selected",
      noAddress: 'No Address',
      coordinates: "Coordinates",
      additionalDelivery: "Additional Delivery",
      construction: "Construction",
      endMonth: "End month",
      customer: {
        mapTypeValue: {
          "1": "SKE",
          "2": "MC Center"
        },
        types: {
          direct: "SKE",
          mediate: "MC Center"
        },
        MCFlags: {
          A: "MC Center A",
          B: "MC Center B",
          C: "MC Center C",
        },
      },

      products: {
        adBlue: "Ad Blue",
        diesel: "Diesel",
        kerosene: "Kerosene",
        gasoline: "Gasoline",
        dieselFreeTax: "Diesel Free Tax",
        crudeOil: "Crude Oil"
      },

      taxes: {
        consumptionTax: "Consumption Tax",
        dieselTax: "Diesel Tax"
      },

      insurance: "Insurance",
      insuranceFee: "Insurance Fee",

      dateRange: {
        startMonth: "Day 01 - Day 15",
        endMonth: "Day 16 - Day 31"
      },

      driver: {
        deliverNumber: "Card Deliver Number",
        fuelNumber: "Card Fuel Number"
      },

      order: {
        title: "Orders",
        info: "Order Information",
        detail: "Order Detail Information",
        quantity: "Amount Fuel Order",
        expectNum: "Amount Fuel Expected",
        deliveryInfo: "Delivery Information",
        total: "Order Total",
        equipmentProducts: "Equipment Products"
      },

      statusOrder: {
        waiting: "Waiting",
        divided: "Divided",
        delivered: "Delivered",
        done: "Done"
      }
    },

    Input: {
      address: "Address",
      amount: "Amount",
      birthday: "Birthday",
      code: "Code",
      cost: "Cost",
      email: "Email",
      name: "Name",
      note: "Note",
      phone: "Phone",
      price: "Price",
      quantity: "Quantity",
      sex: "Sex",
      status: "Status",
      construction: 'Construction',
      curator: "Curator",
      auth: {
        username: "Username",
        password: "Password",
        rePassword: "Re-Password"
      },

      area: {
        code: "Area code",
        name: "Area name"
      },

      product: {
        categoryName: "Category",
        name: "Product Name",
        unit: "Unit"
      },

      customer: {
        code: "Customer Code",
        name: "Customer Name",
        type: "Type",
        extendPrice: "Extend Price",
        paymentTerm: "Payment Term",
        flag: "Flag"
      },

      order: {
        deliveryAddress: "Delivery Address",
        orderDate: "Order Date",
        deliveryDate: "Delivery Date",
        deliveryTime: "Delivery Time",
        memo: "Note",
        constructionNumber: "Construction Number",
        constructionName: "Construction Name",
        note:"Note"
      },

      user: {
        username: "Username",
        code: "User Code",
        name: "Full Name",
        position: "Position",
        firstName: "First Name",
        lastName: "Last Name"
      },

      vehicle: {
        name: "Vehicle Model",
        licensePlate: "License Plate",
        driver: "Driver",
        noDriverAvailable: "No Driver Available",
        capacity: "Capacity",
        remaining: "Fuel Remaining"
      },

      changePassword: {
        title: "Change password",
      },
    },

    Placeholder: {
      quantity: "Quantity",
      filter: {
        general: "Filter ...",
        date: "Choose date",
        dateRange: "Choose date range"
      }
    },

    Table: {
      action: "Actions",
      address: "Address",
      amount: "Amount",
      code: "Code",
      createdAt: "Created At",
      email: "Email",
      name: "Name",
      no: "No.",
      phone: "Phone Number",
      price: "Price",
      quantity: "Quantity",
      status: "Status",
      total: "Total",
      totalAmount: "Total Amount",
      preview: 'Preview',

      area: {
        label: "Area",
        code: "Area Code",
        name: "Area Name"
      },

      product: {
        categoryName: "Product Category",
        name: "Product Name",
        unit: "Unit"
      },

      customer: {
        label: "Customer",
        code: "Customer Code",
        name: "Customer Name",
        phone: "Phone Number",
        type: "Types",
        extendPrice: "Extend Price",
        paymentTerm: "Payment Term"
      },

      driver: {
        code: "Driver Code",
        name: "Driver Name",
        cardNumber: "Card Number"
      },

      order: {
        orderDate: "Order Date",
        deliveryDate: "Delivery Date",
        deliveryTime: "Delivery Time",
        address: "Delivery Address",
        expectNum: "Expected Number",
        deliveryNum: "Delivered Number",
        fuel: "Fuel",
        memo: "Memo",
        constructionName: "Construction Name",
        constructionNumber: "Construction Number"
      },

      user: {
        username: "Username",
        code: "User Code",
        name: "Full Name",
        position: "Position",
        firstName: "First Name",
        lastName: "Last Name"
      },

      vehicle: {
        name: "Vehicle Model",
        licensePlate: "License Plate",
        driver: "Driver",
        capacity: "Capacity",
        remaining: "Fuel Remaining"
      }
    },

    Validate: {
      required: {
        base: 'This field is required',
        endTime: 'Choose Greater time equals start time',
        auth: {
          username: "Username is required",
          password: "Password is required",
        }
      },
      noJapanese: "Only number and alphabet haflwidth allowed. Please enter again!",
      passwordNotMatch: "Password is not matching. Please enter again!",
      editFuelsTruck: "Dung tích phải lớn hơn nhiên liệu còn lại!",
      invalidFuelsOrder: "Số lượng dự kiến không được lớn hơn số lượng order",
      emptyFuelsOrder: "No amount of fuel ordered",
      maxLengthMoney: "Number maximum length is ${_maxLength}",
      emptyFuelsImport: "No amount of fuel is poured into the tank truck",
      emptyFuelsExport: "No amount of fuel is taken from the tank truck",
      soonerDeliveryDay:"Current date sooner than delivery day",
      min: "Vui lòng nhập giá trị nhỏ nhất là ${arguments[0]}",
      max: "Vui lòng nhập giá trị lớn nhất là ${arguments[0]}",
      minLength: "Vui lòng nhập tối thiểu ${arguments[0]} ký tự",
      maxLength: "Vui lòng nhập tối đa ${arguments[0]} ký tự",
      lessThan: "Vui lòng nhập giá trị nhỏ hơn ${arguments[0]}",
      greatThan: "Vui lòng nhập giá trị lớn hơn ${arguments[0]}",
      minDate: "Vui lòng nhập ngày lớn hơn ngày tối thiểu ${arguments[0]}",
      dateInvalid: "Vui lòng nhập ngày hợp lệ",
      number: {
        onlyNumber: "Please input only number"
      },
      phone: {
        minLength: "Vui lòng nhập số điện thoại lớn hơn ${_minLength} chữ số",
        maxLength: "Vui lòng nhập số điện thoại nhỏ hơn ${arguments[0]} chữ số"
      },
      truck: {
        capacity: "Vui lòng nhập dung tích lớn hơn nhiên liệu còn lại",
        remaining: "Vui lòng nhập nhiên liệu còn lại nhỏ hơn dung tích"
      }
    },

    Common: {
      deliveryTime: {
        morning: "AM",
        afternoon: "PM",
        anytime: "Anytime",
        unknown: "Unknown"
      }
    },

    Confirm: {
      delete: "Do you want to delete the selected rows?",
      undivide: "Do you want to undivide this order?",
      update: "Do you want to update?",
      updatePrice: "Do you want to update the prices?",
      completeOrder: "Do you want to complete the order?",
      finishInputDelivered: "Input Delivered",
      logout: "Are you sure?",
      reset: "Reset this data. is this good?",
    },

    Notification: {
      delete: "Data will not be recoverable after deletion, so be careful in this operation.",
      update: "Data will not be recoverable after changes, so please be careful in this operation.",
    },

    Message: {
      success: {
        create: "Create data successfully",
        update: "Update data successfully",
        delete: "Remove data successfully",
        noOrdersSelected: "No orders selected",
        // custom for another subject
        customer: {
          create: "Create customer successfully",
          update: "Update customer successfully"
        },
        order: {
          create: "Create order successfully",
          update: "Update order successfully"
        },
        group: {
          create: "Create group successfully",
        }
      },

      error: {
        base: "An error occurred. Please try again.",
      },

      divideOrder: {
        haveParams: "Order ${arguments[0]} has been saved. Click 'CONFIRM' to divide now!",
        invalidFuelsDialogTitle: "Not enough fuels",
        invalidFuelsDialogContent: "This tanker has insufficient ${name} to deliver orders. Do you want to continue?",
      },
      noAddress: "No address"
    },

    Tooltip: {
      create: "Add new",
      edit: "Edit",
      editPrice: "Edit prices",
      updatePrice: "Update prices",
      detail: "View detail",
      delete: "Remove selected rows",
      exportCSV: "CSV",
      exportExcel: "Excel",
      print: "Print",
      shareOrder: "Divide orders",
      deliver: "Deliver",
      completeOrder: "Complete",
      gps: "GPS"
    },

    Pagination: {
      backIconButtonText: 'Previous page',
      labelRowsPerPage: 'Rows per page:',
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count} ${count !== 1 ? 'items' : 'item'}`,
      nextIconButtonText: 'Next page',
      noData: 'no Data',
    }
  }
}
