import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import withStyles from "@material-ui/core/styles/withStyles"
import BaseView from "src/views/BaseView"
import PaperFade from "src/components/Main/PaperFade"
import DateTimeField from "src/components/Forms/DateTimeField"
import { dateFormatBackend } from "src/config/constant"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Hidden, 
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { statusOrder} from 'src/config/constant'
import Utils from 'src/helpers/utility'
import moment from "moment"
import _ from "lodash"
import {I18n} from 'src/helpers/I18n'
moment.defaultFormat = dateFormatBackend;

const styles = theme => ({
  paper: {
    minHeight: "calc(100vh - 85px)",
    [theme.breakpoints.down("xs")]: {
      padding: "5px 5px",
      margin: 0
    }
  },
  padingExpan: {
    [theme.breakpoints.down('xs')]: {
      padding: "5px 5px",
      margin: 0
    }
  },
  heightExpan: {
    [theme.breakpoints.down('xs')]: {
      minHeight: "35px",
      height: "20px",
    },
  },
  tablePadding: {
    padding: '0px'
  },
  textCenter: {
    textAlign: 'center'
  },
  fontSizeExpan: {
    fontSize: '14px',
  },
  positionDate: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '-5px'
    },
  },
  borderExpander: {
    boxShadow: "0px 0px 5px 0px black",
    margin: '7px 3px',
    borderRadius: "8px 8px !important",
  },
  borderRadiusExpan: {
    borderRadius: "8px 8px",
  },
  buttonDeliveryTime: {
    color: 'black !important',
  }
});

class Index extends BaseView {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      indexState: null,
      expanded: true,
    }
    this.setIndex = this.setIndex.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
  }

  onChangeDate (date){
    this.setState({ date: date, indexState: null })
    this.props.onFetchData({ date: moment(date).format('YYYY-MM-DD') })
    localStorage.setItem("dateDeliveryOthers", moment(date).format('YYYY-MM-DD'))
  }
  
  renderDetailHead = () => {
    let {classes} = this.props
    const fields = {
      fuel: '',
      expectNum: '(L)',
      deliveryNum: '(L)'
    };
    let arr = [];
    for (let key in fields) {
      if(key == 'fuel') {
        arr.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
          </TableCell>
        )
      } else {
        arr.push(
          <TableCell key={key} align='center' className={classes.tablePadding}>
            {I18n.t(`Table.order.${key}`)}
          </TableCell>
        )
      }
    }
    return arr
  }

  renderDetailFuels(data) {
    let {classes} = this.props
    return ['name', 'quantity', 'numberDelivered'].map(field => {
      let value = this.getData(data, field, "0");
      if (field === 'name') value = I18n.t(`Label.products.${value}`)
      return (
        <TableCell className={classes.tablePadding} key={field} align={field === "name" ? 'left' : 'center'}>
          {value}
        </TableCell>
      )
    })
  }

  renderDateField() {
    const { classes } = this.props
    let dateDeliveryOthers = localStorage.getItem("dateDeliveryOthers")
    let date = this.state.date 
    if(dateDeliveryOthers){
      date = dateDeliveryOthers
    }
    return (
      <DateTimeField
        label={I18n.t(`Input.order.deliveryDate`)}
        name="deliveryDate"
        format={'YYYY/MM/DD'}
        showTime={false}
        autoOk={true}
        value={date}
        onChange={(value) => this.onChangeDate(value)}
        InputProps={{
          readOnly: true
        }}
        className={classes.dateField}
        style={{
          marginTop: "12px"
        }}
      />
    );
  }

  setIndex(index){
    this.setState({ indexState: index })
    if(index == this.state.indexState ) {
      this.setState({ indexState: null })
    } else {
      this.setState({ indexState: index })
    }
  }


  render() {
    const { trucks, classes } = this.props;
    if(!_.isEmpty(trucks)) {
      return (
        <PaperFade className={classes.paper}>
          <div className={classes.positionDate} >
            {this.renderDateField()}
          </div>
          {
            trucks.length
            ? trucks.map((item, index) => {
              let driverFullName = this.getData(item, 'driver.fullName', '')
              let orders = this.getData(item, 'orders', [])
              let statusExpan = index == this.state.indexState ? true : false
              return (
                <ExpansionPanel 
                  key={index} 
                  expanded={statusExpan} 
                  className={classes.borderExpander}
                >
                  <ExpansionPanelSummary onClick={() => this.setIndex(index)} className={classes.heightExpan} expandIcon={<ExpandMoreIcon/>} >
                    <Typography style={{fontWeight: "bold"}} >{driverFullName}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.padingExpan}>
                    <Grid container direction="row" spacing={2}> 
                      {
                        orders.map((element, count) => {
                          let orderDetails = this.getData(element, 'orderDetails', []);
                          let orderStatus = this.getData(element, 'status', '')
                          let orderHistory = this.getData(element, 'orderHistory', {})
                          let keyOrderHistory = Object.keys(orderHistory)
                          return (
                            <Grid item xs={12} md={12} lg={6} key={count}>
                              <ExpansionPanel className={classes.borderRadiusExpan}  >
                                <ExpansionPanelSummary>
                                  <Grid container spacing={1} alignItems='flex-end' direction='row' >
                                    <Grid item xs={12}>
                                      <Typography className={classes.fontSizeExpan}>
                                        {count + 1}, {this.getData(element, 'customer.name', '')} 
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography className={classes.fontSizeExpan}>
                                        {Utils.addressReg(this.getData(element, "deliveryAddress", ""))} 
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Hidden smDown>
                                        <Button style={{ fontSize: 14 }} disabled={true} className={classes.buttonDeliveryTime}>
                                          {Utils._formatDeliveryTime(this.getData(element, 'deliveryTime', ''))}
                                        </Button>
                                      </Hidden>
                                      <Hidden mdUp>
                                        <Typography style={{ fontSize: 14 }}>
                                          {Utils._formatDeliveryTime(this.getData(element, 'deliveryTime', ''))}
                                        </Typography>
                                      </Hidden>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                      {
                                        (orderStatus && orderStatus === statusOrder.delivered)
                                        ? <Button size='small' color='inherit' style={{ backgroundColor: 'red', color: "white" }} >
                                            {I18n.t("Button.delivered")}
                                          </Button> 
                                        : ""
                                      }
                                    </Grid>
                                  </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Table>
                                    <TableHead>
                                      <TableRow>{this.renderDetailHead()}</TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {
                                        orderDetails.map((item, index) => {
                                          keyOrderHistory.filter(i => {
                                            if(i === item.name) {
                                              return item['numberDelivered'] = String(this.getData(orderHistory, i, '0'))
                                            } 
                                          })
                                          return (
                                            <TableRow key={index} className={classes.tablePadding}>
                                              {this.renderDetailFuels(item)}
                                            </TableRow>
                                          )
                                        })
                                      }
                                    </TableBody>
                                  </Table>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <br/>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })
            : ''
          }
        </PaperFade>
      )
    }
    return (
      <PaperFade showLoading={true} className={classes.paper}>
        <div className={classes.positionDate} >
          {this.renderDateField()}
        </div>
      </PaperFade>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Index));
