import BaseReducer from './BaseReducer';

class ProductReducer extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "Product.create": {
        path: "data"
      },
      "Product.edit": {
        path: "data"
      },
      "Product.delete": {
        path: "data"
      },
      "Product.destroy": {
        path: "data"
      },
      "Product.fetchAll": {
        path: "list"
      },
      "Product.productsByCategory": {
        path: "list"
      },
      "Product.fetch": {
        path: "item"
      },
      "Product.listProduct": {
        path: "listProduct"
      }
    }
  }

  get initialState() {
    return {
      ...super.initialState,
      error: {
        message: null
      }
    }
  }
}

export default ProductReducer.export()
