import BaseReducer from './BaseReducer';

class DisableLoadingReducer extends BaseReducer {
  get actionsAllow() {
    return {
      ...super.actionsAllow,
      "disableLoading": this.disableLoading
    }
  }

  get initialState() {
    return false
  }

  disableLoading = (state, action) => {
    return action.data
  }
}

/**
 * bắt buộc gọi hàm export()
 */
export default DisableLoadingReducer.export()
