import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DeliveryAction from 'src/actions/DeliveryAction';
import SortedOrderAction from '../../actions/SortedOrderAction';
import OrderAction from 'src/actions/OrderAction';
import BaseContainer, {selector} from 'src/containers/BaseContainer';
import View from 'src/views/Delivery/Index';
import {dateFormatBackend, statusOrder} from 'src/config/constant';
import moment from 'moment';
import _ from "lodash";
moment.defaultFormat = dateFormatBackend;

class Index extends BaseContainer {
  constructor(props) {
    super(props)
    this.refTable = null;
    const queryString = decodeURIComponent(props.location.search).replace('?','')
    const query = new URLSearchParams(queryString);
    let date = query.get('date');

    const defaultDate = moment(date).isValid() ? moment(date) : moment();
    this.state = {
      date: defaultDate
    }
    this.onFetchData = this.onFetchData.bind(this)
    this.onRefTable = this.onRefTable.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.onChangeStatus = this.onChangeStatus.bind(this)
  }

  onFetchData(values = null) {
    let date = _.get(values, 'date', '') || moment(this.state.date).format('YYYY-MM-DD')
    // this.props.dispatch(DeliveryAction.fetchForMe({date: date}))
    this.props.dispatch(SortedOrderAction.getSortedOrders({pageSize: -1, date: date, flag: 'driver'}));
  }

  componentDidUpdate(prevProps){
    let {sortedOrder} = this.props;
    if(prevProps.sortedOrder != sortedOrder) {
      let date = moment(this.state.date).format('YYYY-MM-DD')
      this.props.dispatch(DeliveryAction.fetchForMe({date: date}))
    }
  }

  componentDidMount() {
    this.onFetchData()
    localStorage.removeItem('datePouredAmount')
  }

  onRefTable(ref) {
    this.refTable = ref
  }

  onChangeDate(date) {
    this.setState({date: date})
    this.onFetchData({date: date.format()});
  }

  onChangeStatus(values) {
    let {_id, status} = values;
    if (status !== statusOrder.delivered) return;

    this.props.dispatch(OrderAction.patch({id: _id, status}))
      .then(data => {
        if (!data.error) {
          this.notify(data.message)
          this.onFetchData()
        } else {
          this.notify(`${data.error.message}`, 'error')
        }
      })
  }

  addNumberDelivered ({orderDetails = [], orderId, histories = []}) {
    let mapName = {dieselFreeTax: 'diesel'};
    let orderDetailsObject = orderDetails.reduce((res, i) => ({
      ...res, 
      [i.name]: Object.assign({}, i, {'numberDelivered': 0})}
    ), {});
    
    let newOrderDetail = histories.reduce((res, history) => {
      if(history.order === orderId) {     // Cung id order
        let details = _.get(history, 'details', [])
        details.forEach(d => {
          let {material, quantity = 0} = d || {};
          material = mapName[material] || material;    // name vs material = like

          if(res[material]) {
            res[material]['numberDelivered'] += parseInt(quantity);   
          }
        })
      }

      return res;
    }, orderDetailsObject)

    return Object.values(newOrderDetail)
  }

  render() {
    return (
      <View
        onFetchData={this.onFetchData}
        onRefTable={this.onRefTable}
        onChangeDate={this.onChangeDate}
        onChangeStatus={this.onChangeStatus}
        date={this.state.date}
        orders={this.props.orders}
        sortedOrder={this.props.sortedOrder}
        histories={this.props.histories}
        addNumberDelivered={this.addNumberDelivered}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    sortedOrder: selector(state, "sortedOrder.data", []),
    orders: selector(state, "delivery.list.orders", []),
    histories: selector(state, "delivery.list.histories", []),
  }
}

export default withRouter(connect(mapStateToProps)(Index))
