import BaseAction from './BaseAction'

class SettingPriceAction extends BaseAction {
  get actions() {
    return {
      edit: {
        method: 'post',
        url: '/api/setting-price',
        type: 'SettingPrice.edit'
      },
      fetch: {
        method: 'get',
        url: '/api/setting-price',
        type: 'SettingPrice.fetch'
      },
      checkSetting: {
        method: 'get',
        url: '/api/check-setting',
        type: 'SettingPrice.checkSetting'
      },
      fetchAgencies: {
        method: 'get',
        url: '/api/fetch-agencies',
        type: 'SettingPrice.fetchAgencies'
      }
    }
  }
}

export default SettingPriceAction.export()
